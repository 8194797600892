@charset "UTF-8";

:root {
	--gray-900: #26242c;
	--gray-800: #171719;
	--gray-700: #e1e1e1;
	--gray-10: #fff;
	--primary-600: #724fe5;
	--primary-500: #2c04fe;
	--success-500: #32e7b1;
	--warning-600: #eee6d0;
	--warning-500: #ffc947;
	--warning-400: #f0e9e1;
	--warning-300: #fffdf5;
	--warning-200: #f6f2e8;
	--warning-100: #fffcf2;
	--danger-500: #ff6b55;
	--fs-xl-20: 20px;
	--fs-xl-lineheight: 32px;
	--fs-lg-18: 18px;
	--fs-lg-lineheight: 28px;
	--fs-md-16: 16px;
	--fs-md-lineheight: 24px;
	--fs-xs-14: 14px;
	--fs-xs-lineheight: 22px;
	--fs-xss-12: 12px;
	--fs-xss-lineheight: 20px;
}

*,
*::before,
*::after {
	box-sizing: inherit;
	margin: 0px;
	padding: 0px;
}

html {
	box-sizing: border-box;
	height: 100%;
}

body {
	height: 100%;
	color: rgba(19, 17, 26, 0.8);
	font-family: "Inter", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: baseline;
	text-rendering: optimizeLegibility;
	font-weight: 400;
	overflow-x: hidden;
	background-color: var(--gray-800) !important;
	counter-reset: my-sec-counter;
	font-size: 18px;
	line-height: 30px;
}

body.styleguide {
	background-color: #dcdfe8;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #e5e7e8;
	padding: 0;
	margin: 0px;
}

audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

textarea {
	resize: vertical;
}

.browserupgrade {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}

.hidden {
	display: none !important;
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	white-space: nowrap;
	/* 1 */
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
	white-space: inherit;
}

.invisible {
	visibility: hidden;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	/* 1 */
	display: table;
	/* 2 */
}

.clearfix:after {
	clear: both;
}

::-moz-selection {
	background-color: #989ffd;
	color: #fff;
}

::selection {
	background-color: #989ffd;
	color: #fff;
}

button:active,
[type="button"]:active,
[type="reset"]:active,
[type="submit"]:active,
button:focus,
[type="button"]:focus,
[type="reset"]:focus,
[type="submit"]:focus,
button:hover,
[type="button"]:hover,
[type="reset"]:hover,
[type="submit"]:hover,
[type="color"]:focus,
[type="date"]:focus,
[type="datetime"]:focus,
[type="datetime-local"]:focus,
[type="email"]:focus,
[type="month"]:focus,
[type="number"]:focus,
[type="password"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="text"]:focus,
[type="time"]:focus,
[type="url"]:focus,
[type="week"]:focus,
input:not([type]):focus,
textarea:focus,
[type="color"]:active,
[type="date"]:active,
[type="datetime"]:active,
[type="datetime-local"]:active,
[type="email"]:active,
[type="month"]:active,
[type="number"]:active,
[type="password"]:active,
[type="search"]:active,
[type="tel"]:active,
[type="text"]:active,
[type="time"]:active,
[type="url"]:active,
[type="week"]:active,
input:not([type]):active,
textarea:active,
[type="color"]:hover,
[type="date"]:hover,
[type="datetime"]:hover,
[type="datetime-local"]:hover,
[type="email"]:hover,
[type="month"]:hover,
[type="number"]:hover,
[type="password"]:hover,
[type="search"]:hover,
[type="tel"]:hover,
[type="text"]:hover,
[type="time"]:hover,
[type="url"]:hover,
[type="week"]:hover,
input:not([type]):hover,
textarea:hover,
[type="color"]:invalid,
[type="date"]:invalid,
[type="datetime"]:invalid,
[type="datetime-local"]:invalid,
[type="email"]:invalid,
[type="month"]:invalid,
[type="number"]:invalid,
[type="password"]:invalid,
[type="search"]:invalid,
[type="tel"]:invalid,
[type="text"]:invalid,
[type="time"]:invalid,
[type="url"]:invalid,
[type="week"]:invalid,
input:not([type]):invalid,
textarea:invalid {
	outline: none;
	box-shadow: none;
}

button:active,
[type="button"]:active,
[type="reset"]:active,
[type="submit"]:active,
button:focus,
[type="button"]:focus,
[type="reset"]:focus,
[type="submit"]:focus,
button:hover,
[type="button"]:hover,
[type="reset"]:hover,
[type="submit"]:hover {
	cursor: pointer;
}

@media print {
	*,
	*:before,
	*:after {
		background: transparent !important;
		color: #000 !important;
		/* Black prints faster */
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " (" attr(href) ")";
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	/*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: "";
	}

	pre {
		white-space: pre-wrap !important;
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}
}
ul,
ol,
li {
	margin: 0px;
	padding: 0px;
	list-style: none;
}

button {
	padding: 0px;
	outline: none;
	border: none;
	display: inline-block;
	background-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: "Public Sans", sans-serif;
	color: var(--gray-800);
	font-weight: 700;
}

h1,
.h1 {
	font-size: 80px;
	line-height: 88px;
	margin-bottom: 20px;
	letter-spacing: -1px;
}
@media (max-width: 1400px) {
	h1,
	.h1 {
		font-size: 70px;
		line-height: 80px;
	}
}
@media (max-width: 1199px) {
	h1,
	.h1 {
		font-size: 60px;
		line-height: 75px;
	}
}
@media (max-width: 991px) {
	h1,
	.h1 {
		font-size: 48px;
		line-height: 65px;
	}
}
@media (max-width: 767px) {
	h1,
	.h1 {
		font-size: 36px;
		line-height: 48px;
	}
}

h2,
.h2 {
	font-size: 48px;
	line-height: 60px;
	margin-bottom: 15px;
}
@media (max-width: 991px) {
	h2,
	.h2 {
		font-size: 40px;
		line-height: 50px;
	}
}
@media (max-width: 767px) {
	h2,
	.h2 {
		font-size: 32px;
		line-height: 40px;
	}
}

h3,
.h3 {
	font-size: 30px;
	line-height: 42px;
	margin-bottom: 15px;
}
@media (max-width: 767px) {
	h3,
	.h3 {
		font-size: 24px;
		line-height: 34px;
	}
}

h4,
.h4 {
	font-size: 24px;
	line-height: 30px;
	margin-bottom: 15px;
	font-weight: 600;
}

h5,
.h5 {
	font-size: 20px;
	line-height: 28px;
	margin-bottom: 15px;
}

p {
	margin-bottom: 30px;
}
p:last-child {
	margin-bottom: 0;
}

.f-size-10 {
	font-size: 10px !important;
}

.f-size-11 {
	font-size: 11px !important;
}

.f-size-12 {
	font-size: 12px !important;
}

.f-size-13 {
	font-size: 13px !important;
}

.f-size-14 {
	font-size: 14px !important;
}

.f-size-15 {
	font-size: 15px !important;
}

.f-size-16 {
	font-size: 16px !important;
}

.f-size-17 {
	font-size: 17px !important;
}

.f-size-18 {
	font-size: 18px !important;
}

.f-size-19 {
	font-size: 19px !important;
}

.f-size-20 {
	font-size: 20px !important;
}

.f-size-21 {
	font-size: 21px !important;
}

.f-size-22 {
	font-size: 22px !important;
}

.f-size-23 {
	font-size: 23px !important;
}

.f-size-24 {
	font-size: 24px !important;
}

.f-size-25 {
	font-size: 25px !important;
}

.f-size-26 {
	font-size: 26px !important;
}

.f-size-27 {
	font-size: 27px !important;
}

.f-size-28 {
	font-size: 28px !important;
}

.f-size-29 {
	font-size: 29px !important;
}

.f-size-30 {
	font-size: 30px !important;
}

.f-size-31 {
	font-size: 31px !important;
}

.f-size-32 {
	font-size: 32px !important;
}

.f-size-33 {
	font-size: 33px !important;
}

.f-size-34 {
	font-size: 34px !important;
}

.f-size-35 {
	font-size: 35px !important;
}

.f-size-36 {
	font-size: 36px !important;
}

.f-size-37 {
	font-size: 37px !important;
}

.f-size-38 {
	font-size: 38px !important;
}

.f-size-39 {
	font-size: 39px !important;
}

.f-size-40 {
	font-size: 40px !important;
}

.line-height-10 {
	line-height: 10px !important;
}

.line-height-11 {
	line-height: 11px !important;
}

.line-height-12 {
	line-height: 12px !important;
}

.line-height-13 {
	line-height: 13px !important;
}

.line-height-14 {
	line-height: 14px !important;
}

.line-height-15 {
	line-height: 15px !important;
}

.line-height-16 {
	line-height: 16px !important;
}

.line-height-17 {
	line-height: 17px !important;
}

.line-height-18 {
	line-height: 18px !important;
}

.line-height-19 {
	line-height: 19px !important;
}

.line-height-20 {
	line-height: 20px !important;
}

.line-height-21 {
	line-height: 21px !important;
}

.line-height-22 {
	line-height: 22px !important;
}

.line-height-23 {
	line-height: 23px !important;
}

.line-height-24 {
	line-height: 24px !important;
}

.line-height-25 {
	line-height: 25px !important;
}

.line-height-26 {
	line-height: 26px !important;
}

.line-height-27 {
	line-height: 27px !important;
}

.line-height-28 {
	line-height: 28px !important;
}

.line-height-29 {
	line-height: 29px !important;
}

.line-height-30 {
	line-height: 30px !important;
}

.line-height-31 {
	line-height: 31px !important;
}

.line-height-32 {
	line-height: 32px !important;
}

.line-height-33 {
	line-height: 33px !important;
}

.line-height-34 {
	line-height: 34px !important;
}

.line-height-35 {
	line-height: 35px !important;
}

.line-height-36 {
	line-height: 36px !important;
}

.line-height-37 {
	line-height: 37px !important;
}

.line-height-38 {
	line-height: 38px !important;
}

.line-height-39 {
	line-height: 39px !important;
}

.line-height-40 {
	line-height: 40px !important;
}

.font-bold {
	font-weight: 700;
}

.black {
	font-weight: 900;
}

.font-medium {
	font-weight: 500;
}

.font-normal {
	font-weight: 400;
}

.font-semibold {
	font-weight: 600;
}

a {
	text-decoration: none;
	transition: all 0.24s ease-in-out;
}

img {
	max-width: 100%;
}

.rt-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.mouse-cursor,
.pointer {
	cursor: pointer;
}

blockquote {
	font-weight: 400;
	position: relative;
	font-size: 20px;
	line-height: 32px;
	border-radius: 12px;
	padding: 40px 45px;
}

.border-transparent {
	border-color: transparent !important;
}

.bg-transsparent {
	background-color: transparent !important;
}

.hover\:bg-transsparenthover {
	background-color: transparent !important;
}

.hover-shadow\:none {
	box-shadow: none !important;
}

.bgprefix-cover {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.bgprefix-contain {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

.bgprefix-full {
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center center;
}

.position-parent {
	position: relative;
	z-index: 1;
}

.position-relative {
	position: relative;
}

.body-no-scrolling {
	overflow: hidden;
}

.img-fit {
	display: block;
	object-fit: cover;
	transform: translate(-50%, -50%);
	left: 50%;
	bottom: 0;
	right: 0;
	top: 50%;
	position: absolute;
	height: 100%;
	width: 100%;
}

.rt-mb-15 {
	margin-bottom: 15px;
}

.rt-mb-8 {
	margin-bottom: 8px;
}

.rt-mb-2 {
	margin-bottom: 2px;
}

.rt-mb-4 {
	margin-bottom: 4px;
}

.rt-pt-15 {
	padding-top: 15px;
}

.rt-mb-12 {
	margin-bottom: 12px;
}

.rt-spacer-15 {
	height: 15px;
}

.rt-mb-16 {
	margin-bottom: 16px;
}

.rt-mb-25 {
	margin-bottom: 25px;
}

.rt-mb-24 {
	margin-bottom: 24px;
}

.rt-mb-28 {
	margin-bottom: 28px;
}

.rt-mb-48 {
	margin-bottom: 48px;
}

.rt-mb-32 {
	margin-bottom: 32px;
}
.rt-mb-32 {
	margin-bottom: 100px;
}

.hr-0 {
	margin: 0;
	padding: 0;
}

.text-gray {
	color: #7b878c;
}

.text-gray-900 {
	color: #26242c !important;
}

.bg-gray-900 {
	background-color: #26242c !important;
}

.border-gray-900 {
	border-color: #26242c !important;
}

.hoverbg-gray-900:hover {
	background-color: #26242c !important;
}

.hover\:bg-gray-900:hover {
	background-color: #26242c !important;
}

.hover\:text-gray-900:hover {
	color: #26242c !important;
}

.hover\:border-gray-900:hover {
	border-color: #26242c !important;
}

.text-gray-800 {
	color: #171719 !important;
}

.bg-gray-800 {
	background-color: #171719 !important;
}

.border-gray-800 {
	border-color: #171719 !important;
}

.hoverbg-gray-800:hover {
	background-color: #171719 !important;
}

.hover\:bg-gray-800:hover {
	background-color: #171719 !important;
}

.hover\:text-gray-800:hover {
	color: #171719 !important;
}

.hover\:border-gray-800:hover {
	border-color: #171719 !important;
}

.text-gray-700 {
	color: #e1e1e1 !important;
}

.bg-gray-700 {
	background-color: #e1e1e1 !important;
}

.border-gray-700 {
	border-color: #e1e1e1 !important;
}

.hoverbg-gray-700:hover {
	background-color: #e1e1e1 !important;
}

.hover\:bg-gray-700:hover {
	background-color: #e1e1e1 !important;
}

.hover\:text-gray-700:hover {
	color: #e1e1e1 !important;
}

.hover\:border-gray-700:hover {
	border-color: #e1e1e1 !important;
}

.text-gray-10 {
	color: #fff !important;
}

.bg-gray-10 {
	background-color: #fff !important;
}

.border-gray-10 {
	border-color: #fff !important;
}

.hoverbg-gray-10:hover {
	background-color: #fff !important;
}

.hover\:bg-gray-10:hover {
	background-color: #fff !important;
}

.hover\:text-gray-10:hover {
	color: #fff !important;
}

.hover\:border-gray-10:hover {
	border-color: #fff !important;
}

.text-primary-600 {
	color: #724fe5 !important;
}

.bg-primary-600 {
	background-color: #724fe5 !important;
}

.border-primary-600 {
	border-color: #724fe5 !important;
}

.hoverbg-primary-600:hover {
	background-color: #724fe5 !important;
}

.hover\:bg-primary-600:hover {
	background-color: #724fe5 !important;
}

.hover\:text-primary-600:hover {
	color: #724fe5 !important;
}

.hover\:border-primary-600:hover {
	border-color: #724fe5 !important;
}

.text-primary-500 {
	color: #2c04fe !important;
}

.bg-primary-500 {
	background-color: #2c04fe !important;
}

.border-primary-500 {
	border-color: #2c04fe !important;
}

.hoverbg-primary-500:hover {
	background-color: #2c04fe !important;
}

.hover\:bg-primary-500:hover {
	background-color: #2c04fe !important;
}

.hover\:text-primary-500:hover {
	color: #2c04fe !important;
}

.hover\:border-primary-500:hover {
	border-color: #2c04fe !important;
}

.text-success-500 {
	color: #32e7b1 !important;
}

.bg-success-500 {
	background-color: #32e7b1 !important;
}

.border-success-500 {
	border-color: #32e7b1 !important;
}

.hoverbg-success-500:hover {
	background-color: #32e7b1 !important;
}

.hover\:bg-success-500:hover {
	background-color: #32e7b1 !important;
}

.hover\:text-success-500:hover {
	color: #32e7b1 !important;
}

.hover\:border-success-500:hover {
	border-color: #32e7b1 !important;
}

.text-warning-600 {
	color: #eee6d0 !important;
}

.bg-warning-600 {
	background-color: #eee6d0 !important;
}

.border-warning-600 {
	border-color: #eee6d0 !important;
}

.hoverbg-warning-600:hover {
	background-color: #eee6d0 !important;
}

.hover\:bg-warning-600:hover {
	background-color: #eee6d0 !important;
}

.hover\:text-warning-600:hover {
	color: #eee6d0 !important;
}

.hover\:border-warning-600:hover {
	border-color: #eee6d0 !important;
}

.text-warning-500 {
	color: #ffc947 !important;
}

.bg-warning-500 {
	background-color: #ffc947 !important;
}

.border-warning-500 {
	border-color: #ffc947 !important;
}

.hoverbg-warning-500:hover {
	background-color: #ffc947 !important;
}

.hover\:bg-warning-500:hover {
	background-color: #ffc947 !important;
}

.hover\:text-warning-500:hover {
	color: #ffc947 !important;
}

.hover\:border-warning-500:hover {
	border-color: #ffc947 !important;
}

.text-warning-400 {
	color: #f0e9e1 !important;
}

.bg-warning-400 {
	background-color: #f0e9e1 !important;
}

.border-warning-400 {
	border-color: #f0e9e1 !important;
}

.hoverbg-warning-400:hover {
	background-color: #f0e9e1 !important;
}

.hover\:bg-warning-400:hover {
	background-color: #f0e9e1 !important;
}

.hover\:text-warning-400:hover {
	color: #f0e9e1 !important;
}

.hover\:border-warning-400:hover {
	border-color: #f0e9e1 !important;
}

.text-warning-300 {
	color: #fffdf5 !important;
}

.bg-warning-300 {
	background-color: #fffdf5 !important;
}

.border-warning-300 {
	border-color: #fffdf5 !important;
}

.hoverbg-warning-300:hover {
	background-color: #fffdf5 !important;
}

.hover\:bg-warning-300:hover {
	background-color: #fffdf5 !important;
}

.hover\:text-warning-300:hover {
	color: #fffdf5 !important;
}

.hover\:border-warning-300:hover {
	border-color: #fffdf5 !important;
}

.text-warning-200 {
	color: #f6f2e8 !important;
}

.bg-warning-200 {
	background-color: #f6f2e8 !important;
}

.border-warning-200 {
	border-color: #f6f2e8 !important;
}

.hoverbg-warning-200:hover {
	background-color: #f6f2e8 !important;
}

.hover\:bg-warning-200:hover {
	background-color: #f6f2e8 !important;
}

.hover\:text-warning-200:hover {
	color: #f6f2e8 !important;
}

.hover\:border-warning-200:hover {
	border-color: #f6f2e8 !important;
}

.text-warning-100 {
	color: #fffcf2 !important;
}

.bg-warning-100 {
	background-color: #fffcf2 !important;
}

.border-warning-100 {
	border-color: #fffcf2 !important;
}

.hoverbg-warning-100:hover {
	background-color: #fffcf2 !important;
}

.hover\:bg-warning-100:hover {
	background-color: #fffcf2 !important;
}

.hover\:text-warning-100:hover {
	color: #fffcf2 !important;
}

.hover\:border-warning-100:hover {
	border-color: #fffcf2 !important;
}

.text-danger-500 {
	color: #ff6b55 !important;
}

  
@-webkit-keyframes rotating /* Safari and Chrome */ {
	from {
	  -webkit-transform: rotateY(0deg);
	  -o-transform: rotateY(0deg);
	  transform: rotateY(0deg);
	}
	to {
	  -webkit-transform: rotateY(360deg);
	  -o-transform: rotateY(360deg);
	  transform: rotateY(360deg);
	}
  }
  @keyframes rotating {
	from {
	  -ms-transform: rotateY(0deg);
	  -moz-transform: rotateY(0deg);
	  -webkit-transform: rotateY(0deg);
	  -o-transform: rotateY(0deg);
	  transform: rotateY(0deg);
	}
	to {
	  -ms-transform: rotateY(360deg);
	  -moz-transform: rotateY(360deg);
	  -webkit-transform: rotateY(360deg);
	  -o-transform: rotateY(360deg);
	  transform: rotateY(360deg);
	}
  }
  .rotating {
	-webkit-animation: rotating 20s linear infinite;
	-moz-animation: rotating 20s linear infinite;
	-ms-animation: rotating 20s linear infinite;
	-o-animation: rotating 20s linear infinite;
	animation: rotating 20s linear infinite;
  }
  
  @keyframes wiggle {
	0% {
	  transform: rotate(5deg);
	}
	25% {
	  transform: rotate(-5deg);
	}
	50% {
	  transform: rotate(5deg);
	}
	75% {
	  transform: rotate(-5deg);
	}
	100% {
	  transform: rotate(5deg);
	}
  }
  
  .wiggle {
	-webkit-animation: wiggle 1s linear infinite;
	-moz-animation: wiggle 1s linear infinite;
	-ms-animation: wiggle 1s linear infinite;
	-o-animation: wiggle 1s linear infinite;
	animation: wiggle 1s linear infinite;/* Adjust animation duration and timing as needed */
  }
.bg-danger-500 {
	background-color: #ff6b55 !important;
}

.border-danger-500 {
	border-color: #ff6b55 !important;
}

.hoverbg-danger-500:hover {
	background-color: #ff6b55 !important;
}

.hover\:bg-danger-500:hover {
	background-color: #ff6b55 !important;
}

.hover\:text-danger-500:hover {
	color: #ff6b55 !important;
}

.hover\:border-danger-500:hover {
	border-color: #ff6b55 !important;
}

.hover\:border-transparent {
	border-color: transparent !important;
}

.rt-mr-0 {
	margin-right: 0px !important;
}

.rt-ml-0 {
	margin-left: 0px !important;
}

.rt-rounded-0 {
	border-radius: 0px !important;
}

.rt-mr-1 {
	margin-right: 1px !important;
}

.rt-ml-1 {
	margin-left: 1px !important;
}

.rt-rounded-1 {
	border-radius: 1px !important;
}

.rt-mr-2 {
	margin-right: 2px !important;
}

.rt-ml-2 {
	margin-left: 2px !important;
}

.rt-rounded-2 {
	border-radius: 2px !important;
}

.rt-mr-3 {
	margin-right: 3px !important;
}

.rt-ml-3 {
	margin-left: 3px !important;
}

.rt-rounded-3 {
	border-radius: 3px !important;
}

.rt-mr-4 {
	margin-right: 4px !important;
}

.rt-ml-4 {
	margin-left: 4px !important;
}

.rt-rounded-4 {
	border-radius: 4px !important;
}

.rt-mr-5 {
	margin-right: 5px !important;
}

.rt-ml-5 {
	margin-left: 5px !important;
}

.rt-rounded-5 {
	border-radius: 5px !important;
}

.rt-mr-6 {
	margin-right: 6px !important;
}

.rt-ml-6 {
	margin-left: 6px !important;
}

.rt-rounded-6 {
	border-radius: 6px !important;
}

.rt-mr-7 {
	margin-right: 7px !important;
}

.rt-ml-7 {
	margin-left: 7px !important;
}

.rt-rounded-7 {
	border-radius: 7px !important;
}

.rt-mr-8 {
	margin-right: 8px !important;
}

.rt-ml-8 {
	margin-left: 8px !important;
}

.rt-rounded-8 {
	border-radius: 8px !important;
}

.rt-mr-9 {
	margin-right: 9px !important;
}

.rt-ml-9 {
	margin-left: 9px !important;
}

.rt-rounded-9 {
	border-radius: 9px !important;
}

.rt-mr-10 {
	margin-right: 10px !important;
}

.rt-ml-10 {
	margin-left: 10px !important;
}

.rt-rounded-10 {
	border-radius: 10px !important;
}

.rt-mr-11 {
	margin-right: 11px !important;
}

.rt-ml-11 {
	margin-left: 11px !important;
}

.rt-rounded-11 {
	border-radius: 11px !important;
}

.rt-mr-12 {
	margin-right: 12px !important;
}

.rt-ml-12 {
	margin-left: 12px !important;
}

.rt-rounded-12 {
	border-radius: 12px !important;
}

.rt-mr-13 {
	margin-right: 13px !important;
}

.rt-ml-13 {
	margin-left: 13px !important;
}

.rt-rounded-13 {
	border-radius: 13px !important;
}

.rt-mr-14 {
	margin-right: 14px !important;
}

.rt-ml-14 {
	margin-left: 14px !important;
}

.rt-rounded-14 {
	border-radius: 14px !important;
}

.rt-mr-15 {
	margin-right: 15px !important;
}

.rt-ml-15 {
	margin-left: 15px !important;
}

.rt-rounded-15 {
	border-radius: 15px !important;
}

.rt-mr-16 {
	margin-right: 16px !important;
}

.rt-ml-16 {
	margin-left: 16px !important;
}

.rt-rounded-16 {
	border-radius: 16px !important;
}

.rt-mr-17 {
	margin-right: 17px !important;
}

.rt-ml-17 {
	margin-left: 17px !important;
}

.rt-rounded-17 {
	border-radius: 17px !important;
}

.rt-mr-18 {
	margin-right: 18px !important;
}

.rt-ml-18 {
	margin-left: 18px !important;
}

.rt-rounded-18 {
	border-radius: 18px !important;
}

.rt-mr-19 {
	margin-right: 19px !important;
}

.rt-ml-19 {
	margin-left: 19px !important;
}

.rt-rounded-19 {
	border-radius: 19px !important;
}

.rt-mr-20 {
	margin-right: 20px !important;
}

.rt-ml-20 {
	margin-left: 20px !important;
}

.rt-rounded-20 {
	border-radius: 20px !important;
}

.rt-mr-21 {
	margin-right: 21px !important;
}

.rt-ml-21 {
	margin-left: 21px !important;
}

.rt-rounded-21 {
	border-radius: 21px !important;
}

.rt-mr-22 {
	margin-right: 22px !important;
}

.rt-ml-22 {
	margin-left: 22px !important;
}

.rt-rounded-22 {
	border-radius: 22px !important;
}

.rt-mr-23 {
	margin-right: 23px !important;
}

.rt-ml-23 {
	margin-left: 23px !important;
}

.rt-rounded-23 {
	border-radius: 23px !important;
}

.rt-mr-24 {
	margin-right: 24px !important;
}

.rt-ml-24 {
	margin-left: 24px !important;
}

.rt-rounded-24 {
	border-radius: 24px !important;
}

.rt-mr-25 {
	margin-right: 25px !important;
}

.rt-ml-25 {
	margin-left: 25px !important;
}

.rt-rounded-25 {
	border-radius: 25px !important;
}

.rt-mr-26 {
	margin-right: 26px !important;
}

.rt-ml-26 {
	margin-left: 26px !important;
}

.rt-rounded-26 {
	border-radius: 26px !important;
}

.rt-mr-27 {
	margin-right: 27px !important;
}

.rt-ml-27 {
	margin-left: 27px !important;
}

.rt-rounded-27 {
	border-radius: 27px !important;
}

.rt-mr-28 {
	margin-right: 28px !important;
}

.rt-ml-28 {
	margin-left: 28px !important;
}

.rt-rounded-28 {
	border-radius: 28px !important;
}

.rt-mr-29 {
	margin-right: 29px !important;
}

.rt-ml-29 {
	margin-left: 29px !important;
}

.rt-rounded-29 {
	border-radius: 29px !important;
}

.rt-mr-30 {
	margin-right: 30px !important;
}

.rt-ml-30 {
	margin-left: 30px !important;
}

.rt-rounded-30 {
	border-radius: 30px !important;
}

.rt-mb-0 {
	margin-bottom: 0px;
}

.rt-pt-0 {
	padding-top: 0px;
}

.rt-spacer-0 {
	height: 0px;
}

.rt-mb-10 {
	margin-bottom: 10px;
}

.rt-pt-10 {
	padding-top: 10px;
}

.rt-spacer-10 {
	height: 10px;
}

.rt-mb-20 {
	margin-bottom: 20px;
}

.rt-pt-20 {
	padding-top: 20px;
}

.rt-spacer-20 {
	height: 20px;
}

.rt-mb-30 {
	margin-bottom: 30px;
}

.rt-pt-30 {
	padding-top: 30px;
}

.rt-spacer-30 {
	height: 30px;
}

.rt-mb-40 {
	margin-bottom: 40px;
}

.rt-pt-40 {
	padding-top: 40px;
}

.rt-spacer-40 {
	height: 40px;
}

.rt-mb-50 {
	margin-bottom: 50px;
}

.rt-pt-50 {
	padding-top: 50px;
}

.rt-spacer-50 {
	height: 50px;
}

.rt-mb-60 {
	margin-bottom: 60px;
}

.rt-pt-60 {
	padding-top: 60px;
}

.rt-spacer-60 {
	height: 60px;
}

.rt-mb-70 {
	margin-bottom: 70px;
}

.rt-pt-70 {
	padding-top: 70px;
}

.rt-spacer-70 {
	height: 70px;
}

.rt-mb-80 {
	margin-bottom: 80px;
}

.rt-pt-80 {
	padding-top: 80px;
}

.rt-spacer-80 {
	height: 80px;
}

.rt-mb-90 {
	margin-bottom: 90px;
}

.rt-pt-90 {
	padding-top: 90px;
}

.rt-spacer-90 {
	height: 90px;
}

.rt-mb-100 {
	margin-bottom: 100px;
}

.rt-pt-100 {
	padding-top: 100px;
}

.rt-spacer-100 {
	height: 100px;
}

@media (max-width: 1199.98px) {
	.rt-mb-lg-0 {
		margin-bottom: 0px;
	}

	.rt-pt-lg-0 {
		padding-top: 0px;
	}

	.rt-spacer-lg-0 {
		height: 0px;
	}

	.rt-mb-lg-10 {
		margin-bottom: 10px;
	}

	.rt-pt-lg-10 {
		padding-top: 10px;
	}

	.rt-spacer-lg-10 {
		height: 10px;
	}

	.rt-mb-lg-20 {
		margin-bottom: 20px;
	}

	.rt-pt-lg-20 {
		padding-top: 20px;
	}

	.rt-spacer-lg-20 {
		height: 20px;
	}

	.rt-mb-lg-30 {
		margin-bottom: 30px;
	}

	.rt-pt-lg-30 {
		padding-top: 30px;
	}

	.rt-spacer-lg-30 {
		height: 30px;
	}

	.rt-mb-lg-40 {
		margin-bottom: 40px;
	}

	.rt-pt-lg-40 {
		padding-top: 40px;
	}

	.rt-spacer-lg-40 {
		height: 40px;
	}

	.rt-mb-lg-50 {
		margin-bottom: 50px;
	}

	.rt-pt-lg-50 {
		padding-top: 50px;
	}

	.rt-spacer-lg-50 {
		height: 50px;
	}

	.rt-mb-lg-60 {
		margin-bottom: 60px;
	}

	.rt-pt-lg-60 {
		padding-top: 60px;
	}

	.rt-spacer-lg-60 {
		height: 60px;
	}

	.rt-mb-lg-70 {
		margin-bottom: 70px;
	}

	.rt-pt-lg-70 {
		padding-top: 70px;
	}

	.rt-spacer-lg-70 {
		height: 70px;
	}

	.rt-mb-lg-80 {
		margin-bottom: 80px;
	}

	.rt-pt-lg-80 {
		padding-top: 80px;
	}

	.rt-spacer-lg-80 {
		height: 80px;
	}

	.rt-mb-lg-90 {
		margin-bottom: 90px;
	}

	.rt-pt-lg-90 {
		padding-top: 90px;
	}

	.rt-spacer-lg-90 {
		height: 90px;
	}

	.rt-mb-lg-100 {
		margin-bottom: 100px;
	}

	.rt-pt-lg-100 {
		padding-top: 100px;
	}

	.rt-spacer-lg-100 {
		height: 100px;
	}
}
@media (max-width: 991.98px) {
	.rt-mb-md-0 {
		margin-bottom: 0px;
	}

	.rt-pt-md-0 {
		padding-top: 0px;
	}

	.rt-spacer-md-0 {
		height: 0px;
	}

	.rt-mb-md-10 {
		margin-bottom: 10px;
	}

	.rt-pt-md-10 {
		padding-top: 10px;
	}

	.rt-spacer-md-10 {
		height: 10px;
	}

	.rt-mb-md-20 {
		margin-bottom: 20px;
	}

	.rt-pt-md-20 {
		padding-top: 20px;
	}

	.rt-spacer-md-20 {
		height: 20px;
	}

	.rt-mb-md-30 {
		margin-bottom: 30px;
	}

	.rt-pt-md-30 {
		padding-top: 30px;
	}

	.rt-spacer-md-30 {
		height: 30px;
	}

	.rt-mb-md-40 {
		margin-bottom: 40px;
	}

	.rt-pt-md-40 {
		padding-top: 40px;
	}

	.rt-spacer-md-40 {
		height: 40px;
	}

	.rt-mb-md-50 {
		margin-bottom: 50px;
	}

	.rt-pt-md-50 {
		padding-top: 50px;
	}

	.rt-spacer-md-50 {
		height: 50px;
	}

	.rt-mb-md-60 {
		margin-bottom: 60px;
	}

	.rt-pt-md-60 {
		padding-top: 60px;
	}

	.rt-spacer-md-60 {
		height: 60px;
	}

	.rt-mb-md-70 {
		margin-bottom: 70px;
	}

	.rt-pt-md-70 {
		padding-top: 70px;
	}

	.rt-spacer-md-70 {
		height: 70px;
	}

	.rt-mb-md-80 {
		margin-bottom: 80px;
	}

	.rt-pt-md-80 {
		padding-top: 80px;
	}

	.rt-spacer-md-80 {
		height: 80px;
	}

	.rt-mb-md-90 {
		margin-bottom: 90px;
	}

	.rt-pt-md-90 {
		padding-top: 90px;
	}

	.rt-spacer-md-90 {
		height: 90px;
	}

	.rt-mb-md-100 {
		margin-bottom: 100px;
	}

	.rt-pt-md-100 {
		padding-top: 100px;
	}

	.rt-spacer-md-100 {
		height: 100px;
	}
}
@media (max-width: 767.98px) {
	.rt-mb-xs-0 {
		margin-bottom: 0px;
	}

	.rt-pt-xs-0 {
		padding-top: 0px;
	}

	.rt-spacer-xs-0 {
		height: 0px;
	}

	.rt-mb-xs-10 {
		margin-bottom: 10px;
	}

	.rt-pt-xs-10 {
		padding-top: 10px;
	}

	.rt-spacer-xs-10 {
		height: 10px;
	}

	.rt-mb-xs-20 {
		margin-bottom: 20px;
	}

	.rt-pt-xs-20 {
		padding-top: 20px;
	}

	.rt-spacer-xs-20 {
		height: 20px;
	}

	.rt-mb-xs-30 {
		margin-bottom: 30px;
	}

	.rt-pt-xs-30 {
		padding-top: 30px;
	}

	.rt-spacer-xs-30 {
		height: 30px;
	}

	.rt-mb-xs-40 {
		margin-bottom: 40px;
	}

	.rt-pt-xs-40 {
		padding-top: 40px;
	}

	.rt-spacer-xs-40 {
		height: 40px;
	}

	.rt-mb-xs-50 {
		margin-bottom: 50px;
	}

	.rt-pt-xs-50 {
		padding-top: 50px;
	}

	.rt-spacer-xs-50 {
		height: 50px;
	}

	.rt-mb-xs-60 {
		margin-bottom: 60px;
	}

	.rt-pt-xs-60 {
		padding-top: 60px;
	}

	.rt-spacer-xs-60 {
		height: 60px;
	}

	.rt-mb-xs-70 {
		margin-bottom: 70px;
	}

	.rt-pt-xs-70 {
		padding-top: 70px;
	}

	.rt-spacer-xs-70 {
		height: 70px;
	}

	.rt-mb-xs-80 {
		margin-bottom: 80px;
	}

	.rt-pt-xs-80 {
		padding-top: 80px;
	}

	.rt-spacer-xs-80 {
		height: 80px;
	}

	.rt-mb-xs-90 {
		margin-bottom: 90px;
	}

	.rt-pt-xs-90 {
		padding-top: 90px;
	}

	.rt-spacer-xs-90 {
		height: 90px;
	}

	.rt-mb-xs-100 {
		margin-bottom: 100px;
	}

	.rt-pt-xs-100 {
		padding-top: 100px;
	}

	.rt-spacer-xs-100 {
		height: 100px;
	}
}
.pointer {
	cursor: pointer;
}

.text-hide {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.slick-bullet .slick-dots li button:before {
	display: none !important;
}
.slick-bullet .slick-dots {
	margin: 0;
	padding: 0;
	list-style: none;
}
.slick-bullet .slick-dots li {
	position: relative;
	z-index: 1;
	width: 10px;
	display: inline-block;
	height: 10px;
}
.slick-bullet .slick-dots li button {
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.slick-bullet.deafult_style_dot .slick-dots li button {
	overflow: hidden;
	transition: background 0.3s ease;
}
.slick-bullet.deafult_style_dot .slick-dots li.slick-active {
	width: 24px;
}
.slick-bullet.deafult_style_dot .slick-dots li.slick-active button {
	border-radius: 32px;
}
.slick-bullet.dotstyle-fillup .slick-dots li button {
	overflow: hidden;
	background-color: transparent;
	box-shadow: inset 0 0 0 2px #fff;
	transition: background 0.3s ease;
}
.slick-bullet.dotstyle-fillup .slick-dots li button:after {
	content: "";
	position: absolute;
	bottom: 0;
	height: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 0 1px #fff;
	-webkit-transition: height 0.3s ease;
	transition: height 0.3s ease;
	border-radius: 50%;
}
.slick-bullet.dotstyle-fillup .slick-dots li.slick-active button::after {
	height: 100%;
}
.slick-bullet.dotstyle-scaleup .slick-dots li button {
	overflow: hidden;
	background-color: rgba(255, 255, 255, 0.3);
	transition: all 0.3s ease;
}
.slick-bullet.dotstyle-scaleup .slick-dots li.slick-active button {
	transform: scale(1.2);
	background-color: white;
}
.slick-bullet.dotstyle-stroke .slick-dots li button {
	transition: box-shadow 0.3s ease, background-color 0.3s ease;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
}
.slick-bullet.dotstyle-stroke .slick-dots li.slick-active button {
	background-color: transparent;
	box-shadow: 0 0 0 2px #fff;
}
.slick-bullet.dotstyle-fillin .slick-dots li button {
	background-color: transparent;
	box-shadow: inset 0 0 0 2px #fff;
	transition: box-shadow 0.3s ease;
}
.slick-bullet.dotstyle-fillin .slick-dots li.slick-active button {
	box-shadow: inset 0 0 0 8px #fff;
}
.slick-bullet.dotstyle-dotstroke .slick-dots li {
	box-shadow: 0px 0px 0px 2px white;
	border-radius: 50%;
	transition: all 0.3s ease;
}
.slick-bullet.dotstyle-dotstroke .slick-dots li button {
	transform: scale(0.4);
	background-color: #fff;
	transition: all 0.3s ease;
}
.slick-bullet.dotstyle-dotstroke .slick-dots li.slick-active button {
	transform: scale(1);
}
.slick-bullet.dotstyle-dotstroke2 .slick-dots li {
	box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0);
	border-radius: 50%;
	transition: all 0.3s ease;
}
.slick-bullet.dotstyle-dotstroke2 .slick-dots li button {
	background-color: #fff;
	transition: all 0.3s ease;
}
.slick-bullet.dotstyle-dotstroke2 .slick-dots li.slick-active {
	box-shadow: 0px 0px 0px 2px white;
}
.slick-bullet.dotstyle-dotstroke2 .slick-dots li.slick-active button {
	transform: scale(0.4);
}

#scrollUp {
	right: 30px;
	bottom: 30px;
	height: 45px;
	width: 45px;
	border-radius: 50%;
	color: #fff;
	align-items: center;
	justify-content: center;
	font-size: 26px;
	line-height: 55px;
	text-align: center;
}

.modal-header {
	padding: 20px 24px 20px 24px;
}
.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5,
.modal-header h6 {
	margin-bottom: 0px;
}

.modal-content {
	border-radius: 16px;
}

.modal-body {
	padding: 24px;
}

.bg-warning-400 {
	background-color: var(--warning-400);
}

.bg-warning-300 {
	background-color: var(--warning-300);
}

.bg-warning-200 {
	background-color: var(--warning-200);
}

.bg-warning-100 {
	background-color: var(--warning-100);
}

.bg-gray-800 {
	background-color: var(--gray-800);
}

.z-index {
	z-index: 3;
	position: relative;
}

.max-w-full {
	max-width: 100% !important;
}

/*1.1 layout css*/
.fugu-network-icons {
	width: 30px;
	height: 30px;
	cursor: pointer;
}
header.fugu-header-section {
	position: fixed;
	z-index: 9;
	width: 100%;
	top: 0;
	padding: 13px 0;
	transition: all 0.4s;
}

header.fugu-header-section.sticky-menu {
	padding: 10px 0;
	background-color: #fff;
	box-shadow: 0 4px 80px rgba(0, 0, 0, 0.06);
}

.site-menu-main .nav-link-item {
	color: var(--gray-800);
}

.nexto-header-two .site-menu-main .nav-link-item {
	color: var(--gray-800);
}
.nexto-header-two.site-header .mobile-menu-trigger span {
	background-color: var(--gray-800);
}
.nexto-header-two.site-header .mobile-menu-trigger span:before,
.nexto-header-two.site-header .mobile-menu-trigger span:after {
	background-color: var(--gray-800);
}

header.nexto-header-section.nexto-header-two.sticky-menu {
	background-color: var(--gray-10);
	box-shadow: 0px 8px 80px rgba(19, 17, 26, 0.06);
}

header.fugu--header-section {
	position: fixed;
	z-index: 9;
	width: 100%;
	top: 0;
	padding: 0;
	transition: all 0.4s;
}
header.fugu--header-section.fugu--header-three {
	background-color: var(--gray-800);
}
header.fugu--header-section.fugu--header-three .site-menu-main .nav-link-item {
	color: var(--gray-10) !important;
}

header.fugu--header-section.sticky-menu {
	padding: 10px 0;
	background-color: var(--gray-800);
}

.fugu--header-two .site-menu-main .nav-link-item {
	color: var(--gray-800);
}
.fugu--header-two.site-header .mobile-menu-trigger span {
	background-color: var(--gray-800);
}
.fugu--header-two.site-header .mobile-menu-trigger span:before,
.fugu--header-two.site-header .mobile-menu-trigger span:after {
	background-color: var(--gray-800);
}

header.fugu--header-section.fugu--header-two.sticky-menu {
	background-color: var(--gray-10);
	box-shadow: 0px 8px 80px rgba(19, 17, 26, 0.06);
}

.fugu--header-three .site-navbar .menu-block-wrapper {
	justify-content: center;
}

.fugu--header-two .site-navbar .menu-block-wrapper {
	justify-content: center;
}

.fugu--header-three.site-header .mobile-menu-trigger span {
	background-color: white;
}

.fugu--header-three.site-header .mobile-menu-trigger span:before,
.fugu--header-three.site-header .mobile-menu-trigger span:after {
	background-color: white;
}

footer.fugu-footer-section {
	background-color: var(--gray-800);
	padding: 100px 0 95px;
}
@media (max-width: 991px) {
	footer.fugu-footer-section {
		padding: 80px 0;
	}
}
@media (max-width: 767px) {
	footer.fugu-footer-section {
		padding: 60px 0;
	}
}

@media (max-width: 991px) {
	.fugu-textarea {
		margin-bottom: 50px;
		max-width: 500px;
	}
}
.fugu-textarea .fugu-footer-logo {
	margin-bottom: 30px;
}
.fugu-textarea p {
	color: #ffffff;
	opacity: 0.6;
}
.fugu-textarea .fugu-copywright {
	font-size: 16px;
	margin-top: 55px;
}
@media (max-width: 991px) {
	.fugu-textarea .fugu-copywright {
		margin-top: 25px;
	}
}

.fugu-footer-menu.fugu-custom-margin {
	margin-left: 60px;
}
@media (max-width: 991px) {
	.fugu-footer-menu.fugu-custom-margin {
		margin: 0 0 40px;
	}
}
@media (max-width: 575px) {
	.fugu-footer-menu {
		margin-bottom: 40px;
	}
}
.fugu-footer-menu span {
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	margin-bottom: 25px;
	display: block;
	color: var(--gray-10);
	opacity: 0.6;
}
@media (max-width: 575px) {
	.fugu-footer-menu span {
		margin-bottom: 20px;
	}
}
.fugu-footer-menu ul li {
	margin-bottom: 10px;
}
.fugu-footer-menu ul li:last-child {
	margin-bottom: 0;
}
.fugu-footer-menu ul li a {
	font-size: 18px;
	color: var(--gray-10);
	transition: all 0.4s;
}
.fugu-footer-menu ul li a:hover {
	opacity: 1;
	padding-left: 10px;
	color: var(--warning-500);
}

footer.fugu-foote2-section {
	background-color: var(--gray-800);
}
footer.fugu-foote2-section .fugu-footer-top {
	padding: 110px 0;
	border: none;
}
@media (max-width: 991px) {
	footer.fugu-foote2-section .fugu-footer-top {
		padding: 90px 0;
	}
}
@media (max-width: 767px) {
	footer.fugu-foote2-section .fugu-footer-top {
		padding: 70px 0;
	}
}
footer.fugu-foote2-section .fugu-default-content {
	max-width: 720px;
	text-align: center;
	margin: 0 auto;
	position: relative;
}
footer.fugu-foote2-section .fugu-default-content h2,
footer.fugu-foote2-section .fugu-default-content p {
	color: var(--gray-10);
}
footer.fugu-foote2-section .fugu-default-content p {
	opacity: 0.8;
	padding: 0 30px;
}
footer.fugu-foote2-section .fugu-default-content .fugu-app-btn-wrap {
	margin: 55px 0 0;
}
@media (max-width: 991px) {
	footer.fugu-foote2-section .fugu-default-content .fugu-app-btn-wrap {
		margin-top: 30px 0 0;
	}
}

.fugu-footer-middle {
	padding-bottom: 20px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 991px) {
	.fugu-footer-middle .fugu-footer-logo {
		text-align: center;
		margin-bottom: 25px;
	}
}
.fugu-footer-middle .fugu-social-icon2 {
	text-align: right;
}
@media (max-width: 991px) {
	.fugu-footer-middle .fugu-social-icon2 {
		text-align: center;
	}
}

@media (max-width: 991px) {
	.fugu-footer-menu2 {
		text-align: center;
		margin-bottom: 15px;
	}
}
.fugu-footer-menu2 ul li {
	margin: 0 15px 10px;
	display: inline-block;
}
.fugu-footer-menu2 ul li:first-child {
	margin-left: 0;
}
.fugu-footer-menu2 ul li:last-child {
	margin-right: 0;
}
.fugu-footer-menu2 ul li a {
	font-weight: 600;
	color: var(--gray-10);
	transition: all 0.4s;
}
.fugu-footer-menu2 ul li a:hover {
	color: var(--success-500);
}

.fugu-email {
	font-weight: 600;
	color: var(--gray-10);
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.fugu-inline-btn {
	text-decoration: underline;
	border: none;
	background: none;
	justify-content: flex-end;
	display: inline;
	color: var(--warning-500);
}
.fugu-email img {
	margin-right: 10px;
}
@media (max-width: 991px) {
	.fugu-email {
		justify-content: center;
	}
}

.fugu-footer-bottom {
	padding: 35px 0;
}
@media (max-width: 767px) {
	.fugu-footer-bottom {
		text-align: center;
	}
}
.fugu-footer-bottom p {
	font-size: 16px;
	color: #ffffff;
	opacity: 0.6;
	margin: 0;
	margin-right: 40px;
}
@media (max-width: 767px) {
	.fugu-footer-bottom p {
		margin: 0 0 20px;
	}
}
.fugu-footer-bottom .fugu-footer-menu {
	text-align: right;
}
@media (max-width: 767px) {
	.fugu-footer-bottom .fugu-footer-menu {
		text-align: center;
	}
}
@media (max-width: 575px) {
	.fugu-footer-bottom .fugu-footer-menu {
		margin-bottom: 0;
	}
}
.fugu-footer-bottom .fugu-footer-menu ul li {
	padding-right: 20px;
	display: inline-block;
	position: relative;
	opacity: 0.6;
	margin: 0;
}
.fugu-footer-bottom .fugu-footer-menu ul li::after {
	content: " ";
	top: 10px;
	right: 5px;
	border-radius: 50%;
	width: 5px;
	height: 5px;
	position: absolute;
	background: #ffffff;
	opacity: 0.8;
}
.fugu-footer-bottom .fugu-footer-menu ul li:last-child {
	padding-right: 0;
}
.fugu-footer-bottom .fugu-footer-menu ul li:last-child:after {
	content: none;
}
.fugu-footer-bottom .fugu-footer-menu ul li a {
	font-size: 16px;
}
.fugu-footer-bottom .fugu-footer-menu ul li a:hover {
	padding: 0;
}

footer.fugu--footer-section {
	background-color: var(--gray-800);
}

.fugu--footer-top {
	border-bottom: 1px solid var(--gray-900);
}
@media (max-width: 991px) {
	.fugu--footer-top {
		padding: 100px 0 30px;
	}
}
@media (max-width: 767px) {
	.fugu--footer-top {
		padding: 80px 0 15px;
	}
}
@media (max-width: 575px) {
	.fugu--footer-top {
		padding: 80px 0 0;
	}
}

@media (max-width: 991px) {
	.fugu--textarea {
		margin-bottom: 30px;
		max-width: 500px;
	}
}
.fugu--textarea .fugu--footer-logo {
	margin-bottom: 30px;
}
.fugu--textarea p {
	color: #ffffff;
	opacity: 0.6;
}

@media (max-width: 575px) {
	.fugu--footer-menu {
		margin-bottom: 40px;
	}
}
.fugu--footer-menu span {
	font-weight: 600;
	font-size: 20px;
	line-height: 1;
	margin-bottom: 30px;
	display: block;
	color: white;
}
@media (max-width: 575px) {
	.fugu--footer-menu span {
		margin-bottom: 20px;
	}
}
.fugu--footer-menu ul li {
	margin-bottom: 6px;
}
.fugu--footer-menu ul li:last-child {
	margin-bottom: 0;
}
.fugu--footer-menu ul li a {
	font-size: 18px;
	opacity: 0.6;
	color: var(--gray-10);
	transition: all 0.4s;
}
.fugu--footer-menu ul li a:hover {
	opacity: 1;
	padding-left: 10px;
}

.fugu--footer-bottom {
	display: flex;
	justify-content: center;
	padding: 35px 0;
}
@media (max-width: 767px) {
	.fugu--footer-bottom {
		display: block;
		text-align: center;
	}
}
@media (max-width: 575px) {
	.fugu--footer-bottom {
		display: block;
		text-align: center;
		padding: 10px 0 35px;
	}
}
.fugu--footer-bottom p {
	font-size: 16px;
	color: #ffffff;
	opacity: 0.6;
	margin: 0;
	margin-right: 40px;
}
@media (max-width: 767px) {
	.fugu--footer-bottom p {
		margin: 0 0 20px;
	}
}
@media (max-width: 575px) {
	.fugu--footer-bottom .fugu--footer-menu {
		margin-bottom: 0;
	}
}
.fugu--footer-bottom .fugu--footer-menu ul li {
	padding-right: 20px;
	display: inline-block;
	position: relative;
}

.fugu--footer-bottom .fugu--footer-menu ul li:last-child:after {
	content: none;
}
.fugu--footer-bottom .fugu--footer-menu ul li a {
	font-size: 16px;
}
.fugu--footer-bottom .fugu--footer-menu ul li a:hover {
	padding: 0;
}

footer.fugu--foote2-section {
	background-size: cover;
	background-position: center;
}
footer.fugu--foote2-section .fugu--footer-top {
	padding: 120px 0;
	border: none;
}
@media (max-width: 991px) {
	footer.fugu--foote2-section .fugu--footer-top {
		padding: 70px 0;
	}
}
@media (max-width: 767px) {
	footer.fugu--foote2-section .fugu--footer-top {
		padding: 50px 0;
	}
}
footer.fugu--foote2-section .fugu--default-content {
	max-width: 590px;
	text-align: center;
	margin: 0 auto;
	position: relative;
}

.fugu--star {
	position: absolute;
	top: -12px;
	right: -40px;
	-webkit-animation: float 3s ease-in-out infinite;
	animation: float 3s ease-in-out infinite;
}
@media (max-width: 767px) {
	.fugu--star {
		display: none;
	}
}

.fugu--footer-middle {
	padding-bottom: 35px;
	border-bottom: 1px solid var(--gray-800);
}
@media (max-width: 991px) {
	.fugu--footer-middle .fugu--footer-logo {
		text-align: center;
		margin-bottom: 25px;
	}
}
.fugu--footer-middle .fugu--social-icon2 {
	text-align: right;
}
@media (max-width: 991px) {
	.fugu--footer-middle .fugu--social-icon2 {
		text-align: center;
	}
}

.fugu--footer-menu2 {
	text-align: center;
}
.fugu--footer-menu2 ul li {
	margin: 0 15px;
	display: inline-block;
}
.fugu--footer-menu2 ul li:first-child {
	margin-left: 0;
}
.fugu--footer-menu2 ul li:last-child {
	margin-right: 0;
}
.fugu--footer-menu2 ul li a {
	font-weight: 600;
	color: var(--gray-800);
	transition: all 0.4s;
}
.fugu--footer-menu2 ul li a:hover {
	color: var(--danger-500);
}

.fugu--footer-bottom.fugu--footer-bottom2 {
	display: block;
}
@media (max-width: 991px) {
	.fugu--footer-bottom.fugu--footer-bottom2 {
		text-align: center;
	}
}
.fugu--footer-bottom.fugu--footer-bottom2 p {
	color: var(--gray-800);
	margin: 0;
}
.fugu--footer-bottom.fugu--footer-bottom2 .fugu--footer-menu {
	text-align: right;
}
@media (max-width: 991px) {
	.fugu--footer-bottom.fugu--footer-bottom2 .fugu--footer-menu {
		text-align: center;
		margin-top: 15px;
	}
}
.fugu--footer-bottom.fugu--footer-bottom2 .fugu--footer-menu ul li a {
	color: var(--gray-800);
}

.fugu--footer-bottom3 {
	display: block;
}
.fugu--footer-bottom3 p {
	margin: 0;
}
@media (max-width: 991px) {
	.fugu--footer-bottom3 p {
		text-align: center;
	}
}
.fugu--footer-bottom3 .fugu--footer-menu {
	text-align: right;
}
@media (max-width: 991px) {
	.fugu--footer-bottom3 .fugu--footer-menu {
		text-align: center;
		margin-top: 15px;
	}
}

.fugu--info ul li {
	padding-left: 32px;
	position: relative;
	margin-bottom: 18px;
}
.fugu--info ul li:last-child {
	margin-bottom: 0;
}
.fugu--info ul li a {
	font-size: 16px;
	color: rgba(255, 255, 255, 0.6);
}
.fugu--info ul li a img {
	left: 0;
	top: 6px;
	position: absolute;
}

footer.fugo--footer-section.white-version {
	background-color: #fff;
}
footer.fugo--footer-section.white-version .fugo--textarea p {
	color: #171719;
}
footer.fugo--footer-section.white-version .fugo--footer-menu span {
	color: #171719;
}
footer.fugo--footer-section.white-version .fugo--footer-menu ul li a {
	color: #171719;
}
footer.fugo--footer-section.white-version .fugo--footer-bottom p {
	color: #171719;
}

/*1.2 components css*/
.fugu-btn {
	font-size: 16px;
	line-height: 24px;
	border-radius: 3px;
	padding: 20px 55px;
	font-weight: 700;
	font-family: "Inter", sans-serif;
	width: fit-content;
	position: relative;
	z-index: 1;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: inline-block;
	text-align: center;
	-o-transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	overflow: hidden;
	color: var(--gray-800);
	background-color: var(--warning-500);
}
.fugu-btn:hover {
	color: var(--gray-10);
	background-color: var(--gray-800);
}
.fugu-btn.fugu-header-btn {
	padding: 11px 33px;
	margin-left: 40px;
	background-color: transparent;
	border: 2px solid var(--gray-800);
}
.fugu-btn.fugu-header-btn:hover {
	color: var(--gray-10);
	background-color: var(--gray-800);
}
.fugu-btn.fugu-header-btn.fugu-header-btn2 {
	border-radius: 100px;
	border: 2px solid var(--success-500);
	background-color: var(--success-500);
}
.fugu-btn.fugu-header-btn.fugu-header-btn2:hover {
	border: 2px solid var(--gray-800);
	background-color: var(--gray-800);
}
.fugu-btn.fugu-round-btn {
	width: 200px;
	padding: 18px;
	border-radius: 100px;
	background-color: transparent;
	border: 2px solid var(--gray-800);
}
.fugu-btn.fugu-round-btn:hover {
	background-color: var(--gray-800);
}
.fugu-btn.fugu-round-btn.active {
	border: 2px solid var(--success-500);
	background-color: var(--success-500);
}
.fugu-btn.fugu-round-btn.active:hover {
	color: var(--gray-10);
	background-color: var(--gray-800);
	border: 2px solid var(--gray-800);
}
.fugu-btn.small-btn {
	border-radius: 10px;
	padding: 15.5px 41px;
}
.fugu-btn.disabled,
.fugu-btn:disabled {
	opacity: 1;
}
.fugu-btn.d-block {
	width: 100%;
}
.fugu-btn.pill {
	border-radius: 999px;
}
.fugu-btn.btn-icon {
	padding: 9px 24px;
}
.fugu-btn:focus {
	box-shadow: none;
}

  .fugu--outline-btn {
	position: relative;
	padding: 2px;
	border-radius: 10px;
	display: inline-block;
	color: var(--gray-10);
	background: linear-gradient(to right, #89D3FF, #89D3FF, #0092B4);
}
.fugu--outline-btn:disabled span {
	cursor: default;
	background-color: #B9B9B9;
	color: white;
}

.fugu--outline-btn.invalid {
    background: #ff6b55 !important;
}

.fugu--outline-btn.valid {
	background: #32e7b1 !important;
}
.fugu--outline-btn span {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 12px 38px;
	border-radius: 10px;
	background-color: var(--gray-800);
	transition: all 0.4s;
  }
.fugu--outline-btn.active span {
	background-color: transparent;
}
  .fugu-button-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }
  
  .fugu-button-spinner svg {
	width: 20px;
	height: 20px;
  }
  
  .fugu--btn {
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
	font-family: "Inter", sans-serif;
	width: fit-content;
	position: relative;
	/* z-index: 1; */
	white-space: nowrap;
	text-overflow: ellipsis;
	display: inline-block;
	min-width: 180px;
	text-align: center;
	-o-transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	overflow: hidden;
	border-radius: 10px;
	padding: 15.5px 36px;
}
.fugu--claim-btn {
	height: 55px;
	min-width: 260px !important;
}
.fugu--btn.active:before {
	opacity: 1;
}
.fugu--btn.active:hover:before {
	width: 200%;
}
.fugu--btn.fugu--menu-btn1 {
	color: #fff;
	padding: 10.5px 31px;
	background-size: 200% auto !important;
	background: linear-gradient(to right, #89D3FF 0%, #89D3FF 30.35%, #0092B4 100%);
}
.fugu--btn.fugu--menu-btn1:hover {
	background-position: right center;
}
.fugu--btn.fugu--menu-btn2,
.fugu--btn.fugu--menu-btn3 {
	padding: 10.5px 30px;
	border-radius: 3px;
	font-weight: 700;
	z-index: 0;
	color: var(--gray-800);
	background-color: var(--danger-500);
}
.fugu--btn.fugu--menu-btn2:before,
.fugu--btn.fugu--menu-btn3:before {
	content: none;
}
.fugu--btn.fugu--menu-btn2:hover,
.fugu--btn.fugu--menu-btn3:hover {
	color: var(--gray-10);
	box-shadow: 5px 5px 0px 0px var(--gray-800);
}
.fugu--btn.fugu--menu-btn3 {
	border-radius: 0;
	color: var(--gray-10);
	background-color: var(--primary-500);
}
.fugu--btn.fugu--menu-btn3:hover {
	color: var(--gray-10);
	box-shadow: 5px 5px 0px 0px var(--gray-10);
}
.fugu--btn.bg-gray {
	color: var(--gray-10);
	background-color: var(--gray-900);
}
.fugu--btn.bg-white {
	color: var(--gray-800);
	background-color: var(--gray-10);
}
.fugu--btn.bg-orange {
	border-radius: 3px;
	color: var(--gray-800);
	background-color: var(--danger-500);
}
.fugu--btn.bg-orange:hover {
	color: var(--gray-10);
	box-shadow: 5px 5px 0px 0px var(--gray-800);
}
.fugu--btn.bg-orange:before {
	content: none;
}
.fugu--btn.bg-blue {
	color: var(--gray-10);
	border-radius: 3px;
	background-color: var(--primary-500);
}
.fugu--btn.bg-blue:before {
	content: none;
}
.fugu--btn.bg-blue:hover {
	box-shadow: 5px 5px 0px 0px var(--gray-10);
}
.fugu--btn.bg-gradient {
	color: var(--gray-10);
}
.fugu--btn.bg-gradient:before {
	opacity: 1;
}
.fugu--btn.bg-gradient:hover:before {
	width: 200%;
}
.fugu--btn.disabled,
.fugu--btn:disabled {
	opacity: 1;
}
.fugu--btn.btn-sm {
	font-size: 14px;
	padding: 8px 23px;
}
.fugu--btn.d-block {
	width: 100%;
}
.fugu--btn.pill {
	border-radius: 999px;
}
.fugu--btn.btn-icon {
	padding: 9px 24px;
}
.fugu--btn:focus {
	box-shadow: none;
}
.fugu--btn.no-padding {
	padding: 0 5px;
}
.fugu--btn .button-content-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 100%;
}
.fugu--btn .button-content-wrapper .button-icon {
	order: 5;
	flex-grow: 0;
	font-size: 20px;
	margin-right: 8px;
}
.fugu--btn .button-content-wrapper .button-icon.align-icon-right {
	order: 15;
	margin-left: 8px;
	margin-right: 0px;
}
.fugu--btn .button-content-wrapper .button-text {
	order: 10;
}
.fugu--btn.d-block .button-content-wrapper .button-icon,
.fugu--btn.d-block .button-content-wrapper .button-text {
	flex-grow: 0 !important;
}
.fugu--btn:before {
	content: " ";
	left: 0;
	top: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	position: absolute;
	transition: all 0.4s;
	opacity: 0;
	border-radius: 10px;
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%);
}
.fugu--btn:hover:before {
	opacity: 1;
}
/* .fugu--btn:hover {
	color: #fff;
} */

.dropdown-toggle::after {
	border: none;
	content: "";
	font-family: "flaticon";
	font-size: 14px;
	vertical-align: middle;
	margin-left: 15px;
}

.fromGroup .btn-link {
	font-size: 16px;
	font-weight: 400;
	color: var(--gray-500) !important;
}

.dropdown-menu {
	padding: 7px 0px;
	color: var(--gray-700);
	border: none;
	border: 1px solid var(--gray-100);
	box-sizing: border-box;
	box-shadow: 0px 4px 24px rgba(25, 27, 28, 0.16);
	border-radius: 4px;
	min-width: 100px;
}

.dropdown-item {
	color: var(--gray-600);
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	padding: 5px 15px !important;
}
.dropdown-item:hover {
	background-color: transparent;
	color: var(--danger-500);
}
.dropdown-item svg {
	margin-right: 4px;
}
.dropdown-item path {
	transition: all 0.4s;
	stroke: var(--gray-600);
}
.dropdown-item:hover path {
	stroke: var(--danger-500);
}
/* start custom react dropdown style */
.fugu-dropdown .Dropdown-control {
	display: flex;
  	align-items: center;
  	padding: 5px 10px ;
	background-color: var(--gray-900);
	border-radius: 12px;
	color: var(--gray-700);
	border: none;
	
  }
  
  .dropdown-arrow {
	margin-left: 0.5rem;
  }
  
  .dropdown-placeholder {
	color: #777;
  }

  
  .dropdown-arrow {
	margin-left: 0.5rem;
  }
  
  .dropdown-placeholder {
	color: #777;
  }
/* end custom react dropdown */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="time"],
input[type="date"],
input[type="search"],
input,
textarea {
	margin: 0;
	width: 100%;
	height: 60px;
	border-radius: 10px;
	background-color: #1e2930;
	padding: 15px 30px !important;
	border: none !important;
	transition: all 0.4s;
	border: 1px solid var(--gray-700) !important;
}
input.fugu-date-picker,
input[type="date"].fugu-date-picker,
input[type="time"].fugu-date-picker,
/* ... Add other input types as needed */
textarea.fugu-date-picker {
    border: initial !important;
    background-color: transparent !important;
}
input.fugu-date-picker:focus,
input[type="date"].fugu-date-picker:focus,
input[type="time"].fugu-date-picker:focus,
/* ... Add other input types as needed */
textarea.fugu-date-picker:focus {
    border: none !important; /* Remove the border on focus */
    outline: none; /* Remove the default focus outline */
}
@media screen and (max-width: 768px) {
	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="url"],
	input[type="tel"],
	input[type="time"],
	input[type="date"],
	input[type="search"],
	input,
	textarea {
	  margin: 0;
	  width: 100%;
	  height: 70px;
	  border-radius: 10px;
	  background-color: #1e2930;
	  padding: 15px 30px !important;
	  border: none !important;
	  transition: all 0.4s;
	  /* border: 1px solid var(--gray-700) !important; */
	}
	input[type="text"]::placeholder,
	input[type="email"]::placeholder,
	input[type="password"]::placeholder,
	input[type="url"]::placeholder,
	input[type="tel"]::placeholder,
	input[type="time"]::placeholder,
	input[type="date"]::placeholder,
	input[type="search"]::placeholder,
	input::placeholder,
	textarea::placeholder {
	  font-size: 22px; 
	  border: none;
	  color: lightgray;
	}
  }
input:focus,
textarea:focus {
	border: 1px solid var(--warning-500) !important;
}

textarea {
	height: 150px;
	resize: none;
}
@media (max-width: 767px) {
	textarea {
		height: 100px;
	}
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: var(--gray-800);
	opacity: 0.6;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: var(--gray-800);
	opacity: 0.6;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: var(--gray-800);
	opacity: 0.6;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: var(--gray-800);
	opacity: 0.6;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
	/* Microsoft Edge */
	color: var(--gray-800);
	opacity: 0.6;
}

input::placeholder,
textarea::placeholder {
	/* Most modern browsers support this now. */
	color: var(--gray-700);
	opacity: 0.6;
}

.fugu-newsletter {
	max-width: 555px;
	position: relative;
	margin: 0 auto;
}
.fugu-newsletter input {
	padding: 15px 185px 15px 30px !important;
}
@media (max-width: 575px) {
	.fugu-newsletter input {
		padding: 15px 30px !important;
	}
}
.fugu-newsletter p {
	font-size: 14px;
	margin-top: 20px;
	color: rgba(19, 17, 26, 0.5);
}

#fugu-submit-btn {
	font-weight: 700;
	width: 175px;
	height: 50px;
	right: 5px;
	top: 5px;
	font-size: 16px;
	transition: all 0.4s;
	border-radius: 10px;
	position: absolute;
	color: var(--gray-800);
	background-color: var(--warning-500);
}
#fugu-submit-btn:hover {
	color: var(--gray-10);
	background-color: var(--gray-800);
}
@media (max-width: 575px) {
	#fugu-submit-btn {
		position: inherit;
		width: 100% !important;
		margin-top: 10px;
		right: 0;
		top: 0;
	}
}

.fugu--newsletter {
	max-width: 430px;
	position: relative;
	margin: 0 auto;
}
.fugu--newsletter input {
	color: #171719;
	height: 55px;
	padding: 10px 150px 10px 25px !important;
	background-color: var(--gray-10);
}
.fugu--newsletter.fugu--search input {
	height: 55px;
	padding: 10px 150px 10px 50px !important;
}

button#fugu--search-btn {
	position: absolute;
	top: 11px;
	z-index: 9;
	left: 20px;
	opacity: 0.5;
}

#fugu--submit-btn {
	font-weight: 700;
	width: 100%;
	height: 45px;
	color: white;
	right: 5px;
	top: 5px;
	transition: all 0.4s;
	border-radius: 10px;
	position: absolute;
	background-size: 200% auto !important;
	background: linear-gradient(to right, #89D3FF 0%, #89D3FF 30.35%, #0092B4 100%);
}
#fugu--submit-btn:hover {
	background-position: right center;
}
#fugu--submit-btn:disabled {
    background: var(--gray-900);
}
@media (max-width: 768px) {
	#fugu--submit-btn {
		position: inherit;
		width: 100% ;
		margin-top: 10px;
		right: 0;
		top: 0;
		height: 60px;
	}
}

.fugu--newsletter.fugu--newsletter2 {
	max-width: 466px;
	margin: 45px 0 0;
}
.fugu--newsletter.fugu--newsletter2 input {
	padding: 10px 160px 10px 25px !important;
}
@media (max-width: 991px) {
	.fugu--newsletter.fugu--newsletter2 {
		max-width: 100%;
	}
}
.fugu--newsletter.fugu--newsletter2 input {
	border-radius: 3px;
}
.fugu--newsletter.fugu--newsletter2 #fugu--submit-btn {
	border-radius: 3px;
	right: 0;
	top: 0;
	height: 55px;
	width: 150px;
	border-radius: 0px 3px 3px 0px;
	background: var(--gray-800);
}

.fugu--newsletter input::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #171719;
	opacity: 0.5;
}

.fugu--newsletter input:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #171719;
	opacity: 0.5;
}

.fugu--newsletter input::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #171719;
	opacity: 0.5;
}

.fugu--newsletter input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #171719;
	opacity: 0.5;
}
.fugu-limit-line-hight {
	max-height: 30px;
	overflow: hidden;
}
.fugu--newsletter input::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #171719;
	opacity: 0.5;
}

.fugu--newsletter input::placeholder {
	/* Most modern browsers support this now. */
	color: #171719;
	opacity: 0.5;
}

#fugu--form-submit-btn {
	width: 180px;
	height: 55px;
	font-weight: 700;
	font-size: 16px;
	color: #fff;
	transition: all 0.4s;
	background: linear-gradient(225deg, #0092B4 0, #89D3FF 46.35%, #89D3FF 100%);
	border-radius: 10px;
}

.fugu-breadcrumb-section {
	padding: 188px 0 120px;
	position: relative;
	background-color: var(--warning-400);
}
@media (max-width: 991px) {
	.fugu-breadcrumb-section {
		padding: 150px 0 80px;
	}
}
@media (max-width: 767px) {
	.fugu-breadcrumb-section {
		padding: 130px 0 60px;
	}
	.fugu--btn:hover:before {
		opacity: initial;
	}
	.fugu--btn:hover {
		color: initial;
	}
}
@media (max-width: 479px) {
	.fugu-breadcrumb-section {
		padding: 100px 0 60px;
	}
}

.breadcrumbs {
	text-align: center;
}
@media (max-width: 575px) {
	.breadcrumbs h1 {
		margin-bottom: 10px;
		line-height: 20px;
	}
}

.breadcrumb {
	margin: 45px 0 0px;
	justify-content: center;
}
@media (max-width: 991px) {
	.breadcrumb {
		margin: 25px 0 0px;
	}
}
.breadcrumb .breadcrumb-item {
	color: var(--gray-800);
}
.breadcrumb .breadcrumb-item a {
	color: var(--gray-800);
}
.breadcrumb .breadcrumb-item.active {
	font-weight: 600;
}

.fugu-shape8 {
	position: absolute;
	top: 32%;
	-webkit-animation: float 3s ease-in-out infinite;
	animation: float 3s ease-in-out infinite;
}
@media (max-width: 991px) {
	.fugu-shape8 {
		display: none;
	}
}

.fugu-shape9 {
	position: absolute;
	right: 4%;
	bottom: 14%;
	-webkit-animation: float 3s ease-in-out infinite;
	animation: float 3s ease-in-out infinite;
}
@media (max-width: 991px) {
	.fugu-shape9 {
		display: none;
	}
}

.fugu--breadcrumb-thumb {
	overflow: hidden;
	border-radius: 100%;
	animation: zoom-in-zoom-out2 3s ease-out infinite;
}
.fugu--breadcrumb-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
}
.fugu--breadcrumb-thumb-bottom,
.fugu--breadcrumb-thumb-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@media (max-width: 767px) {
	.fugu--breadcrumb-thumb-bottom,
	.fugu--breadcrumb-thumb-top {
		display: none;
	}
}
.fugu--breadcrumb-thumb-top {
	max-width: 873px;
	margin: 0 auto 80px;
}
.fugu--breadcrumb-thumb.thumb1 {
	width: 80px;
	height: 80px;
}
.fugu--breadcrumb-thumb.thumb2 {
	width: 48px;
	height: 45px;
}
.fugu--breadcrumb-thumb.thumb3 {
	width: 60px;
	height: 60px;
}
.fugu--breadcrumb-thumb.thumb4 {
	width: 86px;
	height: 80px;
}

@keyframes zoom-in-zoom-out2 {
	0% {
		transform: scale(1, 1);
	}
	50% {
		transform: scale(1.2, 1.2);
	}
	100% {
		transform: scale(1, 1);
	}
}
.fugu--breadcrumbs-section {
	padding: 20px;
	position: relative;
	z-index: 0;
}
@media (max-width: 991px) {
	.fugu--breadcrumbs-section {
		padding: 60px 0 50px;
	}
}
@media (max-width: 479px) {
	.fugu--breadcrumbs-section {
		padding: 110px 0 50px;
	}
}

.fugu--breadcrumbs-data {
	max-width: 760px;
}
.fugu--breadcrumbs-data h1 {
	letter-spacing: -1px;
	font-family: "Space Grotesk", sans-serif;
}
.fugu--breadcrumbs-data p {
	font-size: 20px;
	line-height: 32px;
	letter-spacing: -0.5px;
}
.fugu--breadcrumbs-data p:last-child {
	margin-bottom: 0;
}
@media (max-width: 767px) {
	.fugu--breadcrumbs-data p {
		font-size: 18px;
	}
}
.fugu--breadcrumbs-data.dark-content h1,
.fugu--breadcrumbs-data.dark-content p {
	color: var(--gray-800);
}
.fugu--breadcrumbs-data.center-content {
	margin: 0 auto;
	text-align: center;
}
.fugu--breadcrumbs-data.center-content .fugu--newsletter {
	margin-top: 40px;
}

.dark-version .fugu--breadcrumbs-data h1,
.dark-version .fugu--breadcrumbs-data p {
	color: var(--gray-10);
}

.fugu--blog-shape1 {
	top: 0;
	z-index: -1;
	position: absolute;
}

.pagination {
	justify-content: center;
}

.page-item .page-link {
	height: 50px;
	width: 50px;
	background-color: transparent;
	color: #fff;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px;
	border-radius: 0;
	font-size: 20px;
	font-weight: 700;
	color: rgba(19, 17, 26, 0.4);
	text-align: center;
	border: 1px solid var(--gray-700);
}
.page-item .page-link:focus {
	box-shadow: none;
	outline: none;
}
.page-item .page-link:hover {
	color: var(--gray-800);
	background-color: var(--warning-500);
}
.page-item .page-link.active {
	color: var(--gray-800);
	background-color: var(--warning-500);
}
.page-item:first-child .page-link {
	border-radius: 5px 0px 0px 5px;
}
.page-item:last-child .page-link {
	border-radius: 0px 5px 5px 0px;
}
.page-item:first-child .page-link:hover,
.page-item:last-child .page-link:hover {
	color: #0092B4;
}
.page-item:first-child .page-link svg,
.page-item:last-child .page-link svg {
	position: absolute;
	right: -22px;
}
.page-item:first-child .page-link svg path,
.page-item:last-child .page-link svg path {
	stroke: var(--gray-10);
	transition: all 0.4s;
}
.page-item:first-child .page-link:hover path,
.page-item:last-child .page-link:hover path {
	stroke: #0092B4;
}
.page-item:first-child .page-link:hover,
.page-item:last-child .page-link:hover {
	background-color: transparent !important;
}
.page-item.active .page-link {
	color: white;
	background-color: var(--gray-900);
}

.fugu-pagination {
	text-align: center;
	margin-top: 56px;
}
@media (max-width: 767px) {
	.fugu-pagination {
		margin-top: 35px;
	}
}

.fugo--pagination .page-item {
	margin-right: 13px;
}
.fugo--pagination .page-item:last-child {
	margin-right: 0px;
}
.fugo--pagination .page-item .page-link {
	height: 55px;
	width: 60px;
	background-color: transparent;
	color: #fff;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px;
	border-radius: 10px;
	font-size: 20px;
	font-weight: 700;
	color: var(--gray-10);
	text-align: center;
}
@media (max-width: 479px) {
	.fugo--pagination .page-item .page-link {
		width: 50px;
		height: 50px;
	}
}
.fugo--pagination .page-item .page-link:focus {
	box-shadow: none;
	outline: none;
}
.fugo--pagination .page-item .page-link:hover {
	background-color: var(--gray-900);
}
.fugo--pagination .page-item:first-child .page-link {
	border-radius: 10px;
}
.fugo--pagination .page-item:first-child .page-link svg {
	left: -22px;
	transform: rotate(180deg);
}
.fugo--pagination .page-item:first-child .page-link:hover,
.fugo--pagination .page-item:last-child .page-link:hover {
	color: #0092B4;
}
.fugo--pagination .page-item:first-child .page-link svg,
.fugo--pagination .page-item:last-child .page-link svg {
	position: absolute;
	right: -22px;
}
.fugo--pagination .page-item:first-child .page-link svg path,
.fugo--pagination .page-item:last-child .page-link svg path {
	stroke: var(--gray-10);
	transition: all 0.4s;
}
.fugo--pagination .page-item:first-child .page-link:hover path,
.fugo--pagination .page-item:last-child .page-link:hover path {
	stroke: #0092B4;
}
.fugo--pagination .page-item:first-child .page-link:hover,
.fugo--pagination .page-item:last-child .page-link:hover {
	background-color: transparent !important;
}
.fugo--pagination .page-item.active .page-link {
	color: white;
	background-color: var(--gray-900);
}

.fugo--pagination {
	text-align: center;
	margin-top: 56px;
}
@media (max-width: 767px) {
	.fugo--pagination {
		margin-top: 35px;
	}
}

.fugo--pagination.fugo--pagination2 .page-item .page-link {
	color: var(--gray-800);
}
.fugo--pagination.fugo--pagination2 .page-item .page-link svg {
	position: absolute;
	right: -22px;
}
.fugo--pagination.fugo--pagination2 .page-item .page-link svg path {
	stroke: var(--gray-800);
	transition: all 0.4s;
}
.fugo--pagination.fugo--pagination2 .page-item .page-link:hover {
	color: var(--gray-800);
}
.fugo--pagination.fugo--pagination2 .page-item .page-link:hover path {
	stroke: var(--danger-500);
}
.fugo--pagination.fugo--pagination2 .page-item:hover .page-link,
.fugo--pagination.fugo--pagination2 .page-item.active .page-link {
	background-color: var(--danger-500);
}
.fugo--pagination.fugo--pagination2 .page-item:first-child .page-link:hover,
.fugo--pagination.fugo--pagination2 .page-item:last-child .page-link:hover {
	color: var(--danger-500);
}
.fugo--pagination.fugo--pagination2 .page-item:first-child .page-link:hover path,
.fugo--pagination.fugo--pagination2 .page-item:last-child .page-link:hover path {
	stroke: var(--danger-500);
}
.fugo--pagination.fugo--pagination2 .page-item:first-child .page-link:hover,
.fugo--pagination.fugo--pagination2 .page-item:last-child .page-link:hover {
	background-color: transparent !important;
}

/* card */
.fugu--card-wrap {
	border-radius: 10px;
	padding: 20px;
	transition: all 0.4s;
	position: relative;
	background: #1c1e20;
	box-shadow: 0 0 0 1px white;
	min-height: 500px; 
}

.fugu--card-wrap-qrc-nft {
	border-radius: 10px;
	padding: 20px;
	transition: all 0.4s;
	position: relative;
	background: #1c1e20;
	box-shadow: 0 0 0 1px white;
	min-height: 350px; 
}
.fugu--card-wrap:hover {
	box-shadow: 0 0 0 1px rgba(255, 255, 255, 0);
}
.fugu--card-wrap:hover:before {
	opacity: 1;
}
.fugu--card-wrap::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	margin: -1px;
	opacity: 0;
	transition: all 0.4s;
	border-radius: inherit;
	background: linear-gradient(to right, #89D3FF, #89D3FF, #0092B4);
}
.fugu--card-wrap:hover .fugu--btn:before {
	color: var(--gray-10);
	opacity: 1;
}
.fugu--card-wrap:hover .fugu--btn {
	/* color: var(--gray-10); */
}
.fugu--card-wrap:hover .fugu--card-thumb img {
	transform: scale(1.1) rotate(3deg);
}
.fugu--card-thumb {
	overflow: hidden;
	border-radius: 10px;
}
.fugu--card-thumb img {
	width: 230px;
	height: 250px;
	object-fit: contain;
	object-position: center top;
	transition: all 0.4s;
}
.fugu--card-data {
	margin-top: 20px;
}
.fugu--card-data h3 {
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	color: var(--gray-10);
	margin: 0 0 10px;
	width: 250px;
	min-height: 65px;
	font-family: "Inter", sans-serif;
	overflow: hidden;
}
.fugu--card-data p {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: var(--gray-10);
	opacity: 0.4;
	margin: 0;
}
.fugu--card-footer {
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.fugu--card-footer-data span {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: var(--gray-10);
}
.fugu--card-footer-data h4 {
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	color: var(--gray-10);
	margin: 0;
	font-family: "Inter", sans-serif;
}
.fugu--card-footer .fugu--btn {
	min-width: inherit;
}

.fugu--card-wrap.fugu--card2 {
	background: var(--gray-10);
	border: 3px solid var(--gray-800);
	border-radius: 3px;
	padding: 18px;
}
.fugu--card-wrap.fugu--card2:before {
	content: none;
}
.fugu--card-wrap.fugu--card2 .fugu--card-thumb img {
	border-radius: 3px;
}
.fugu--card-wrap.fugu--card2 .fugu--card-data h3 {
	color: var(--gray-800);
}
.fugu--card-wrap.fugu--card2 .fugu--card-data p {
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 20px;
	opacity: 1;
	color: var(--gray-800);
}
.fugu--card-wrap.fugu--card2 .fugu--card-data p img {
	margin-right: 8px;
}
.fugu--card-wrap.fugu--card2 .fugu--card-footer-data h4 {
	font-weight: 400;
	font-size: 18px;
	color: var(--gray-800);
	opacity: 0.8;
}
.fugu--card-wrap.fugu--card2 .fugu--card-footer .fugu--btn {
	border-radius: 5px;
	display: flex;
	align-items: center;
	font-size: 16px;
	color: rgba(19, 17, 26, 0.4) !important;
	background-color: rgba(19, 17, 26, 0.1);
}
.fugu--card-wrap.fugu--card2 .fugu--card-footer .fugu--btn img {
	opacity: 0.3;
	margin-right: 7px;
}
.fugu--card-wrap.fugu--card2 .fugu--card-footer .fugu--btn:before {
	content: none;
}
.fugu--card-wrap.fugu--card2 .fugu--card-footer .fugu--btn .red-heart {
	display: none;
}
.fugu--card-wrap.fugu--card2 .fugu--card-footer .fugu--btn.dark-btn {
	color: var(--gray-10) !important;
	background-color: var(--gray-800);
}
.fugu--card-wrap.fugu--card2 .fugu--card-footer .fugu--btn.dark-btn img {
	opacity: 1;
}
.fugu--card-wrap.fugu--card2 .fugu--card-footer .fugu--btn.dark-btn .red-heart {
	display: block;
}
.fugu--card-wrap.fugu--card2 .fugu--card-footer .fugu--btn.dark-btn .black-heart {
	display: none;
}

.fugu--slider-two .slick-slide {
	margin: 0 12px;
}

.fugu--blog-wrap {
	overflow: hidden;
	border-radius: 10px;
	backdrop-filter: blur(400px);
	background: rgba(90, 75, 124, 0.2);
}
.fugu--blog-wrap:hover .fugu--blog-thumb img {
	transform: scale(1.07) rotate(2deg);
}
.fugu--blog-thumb {
	height: 400px;
	overflow: hidden;
	position: relative;
}
@media (max-width: 991px) {
	.fugu--blog-thumb {
		height: auto;
	}
}
.fugu--blog-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
	transition: all 0.5s;
}
.fugu--blog-thumb .fugu--blog-badge {
	background: #ffffff;
	border-radius: 10px;
	position: absolute;
	top: 40px;
	right: 40px;
	font-weight: 700;
	font-size: 16px;
	color: var(--gray-800);
	padding: 10.5px 19.7px;
}
.fugu--blog-content {
	padding: 40px;
}
@media (max-width: 767px) {
	.fugu--blog-content {
		padding: 30px;
	}
}
.fugu--blog-content p {
	font-size: 18px;
	line-height: 30px;
	color: #ffffff;
	opacity: 0.8;
}
.fugu--blog-date {
	margin-bottom: 30px;
}
.fugu-date-box {
	min-height: 20px;
}
.fugu-edit-scheduler-btn {
	color: #32e7b1;
	font-weight: bold;
	font-size: 20px;
	cursor: pointer;
}
.fugu-schedule-amount-input{
	width: 300px
}
.fugu-schedule-currency-symbol {
	font-weight: bold;
	font-size: 20px;
	margin-right: 20px;
}
.fugu--blog-date ul {
	display: flex;
	align-items: center;
}
.fugu--blog-date ul li {
	font-weight: 600;
	font-size: 18px;
	line-height: 1;
	display: flex;
	align-items: center;
	margin-right: 22px;
}
@media (max-width: 400px) {
	.fugu--blog-date ul li {
		margin-right: 15px;
	}
}
.fugu--blog-date ul li a {
	color: #ffffff;
	opacity: 0.4;
}
.fugu--blog-date ul li:last-child {
	margin-right: 0;
}
.fugu--blog-date ul li img {
	margin-right: 13px;
}
.fugu--blog-title h3 {
	font-size: 32px;
	line-height: 38px;
	transition: all 0.4s;
	margin-bottom: 18px;
}
@media (max-width: 767px) {
	.fugu--blog-title h3 {
		font-size: 26px;
		line-height: 34px;
	}
}
.fugu--blog-user {
	display: flex;
	align-items: center;
	margin-top: 30px;
}
.fugu--blog-user-thumb {
	width: 68px;
	height: 65px;
	border-radius: 100%;
	flex-shrink: 0;
	margin-right: 15px;
	overflow: hidden;
}
.fugu--blog-user-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
}
.fugu--blog-user-data span {
	display: block;
	font-weight: 600;
	font-size: 20px;
	color: #ffffff;
}
.fugu--blog-user-data p {
	font-size: 18px;
	color: #ffffff;
	opacity: 0.4;
	margin: 0;
}

.fugu--blog-wrap2 {
	backdrop-filter: inherit;
	background: transparent;
}
.fugu--blog-wrap2 .fugu--blog-thumb {
	height: 280px;
	border-radius: 10px;
}
@media (max-width: 767px) {
	.fugu--blog-wrap2 .fugu--blog-thumb {
		height: auto;
	}
}
.fugu--blog-wrap2 .fugu--blog-content {
	padding: 30px 0;
}
.fugu--blog-wrap2 .fugu--blog-date {
	margin-bottom: 15px;
}
.fugu--blog-wrap2 .fugu--blog-date ul li {
	font-size: 16px;
	position: relative;
	margin-right: 25px;
}
.fugu--blog-wrap2 .fugu--blog-date ul li:last-child {
	margin-right: 0;
}
.fugu--blog-wrap2 .fugu--blog-date ul li:before {
	content: "";
	right: -16px;
	top: 7px;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	position: absolute;
	opacity: 0.4;
	background-color: var(--gray-10);
}
.fugu--blog-wrap2 .fugu--blog-date ul li:last-child:before {
	content: none;
}
.fugu--blog-wrap2 .fugu--blog-title h3 {
	font-size: 28px;
	line-height: 38px;
	margin-bottom: 30px;
}
@media (max-width: 767px) {
	.fugu--blog-wrap2 .fugu--blog-title h3 {
		font-size: 24px;
		line-height: 34px;
	}
}

a.fugu--readmore-btn {
	font-weight: 700;
	font-size: 16px;
	color: white;
}
a.fugu--readmore-btn span {
	display: inline-block;
	width: 30px;
	height: 30px;
	z-index: 0;
	margin-left: 10px;
	border-radius: 100%;
	line-height: 28px;
	text-align: center;
	position: relative;
	background-color: var(--gray-900);
}
a.fugu--readmore-btn span:before {
	content: " ";
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: 0;
	border-radius: 50%;
	transition: all 0.4s;
	position: absolute;
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%);
}

.fugu--blog-wrap2:hover a.fugu--readmore-btn span::before {
	opacity: 1;
}

.fugu-hero-section {
	z-index: 0;
	overflow: hidden;
	position: relative;
	padding: 210px 0 130px;
	background-color: var(--warning-400);
}
@media (max-width: 991px) {
	.fugu-hero-section {
		padding: 170px 0 0;
	}
}
@media (max-width: 767px) {
	.fugu-hero-section {
		padding: 120px 0 0;
	}
}

.fugu-hero-content {
	margin-right: 50px;
}
@media (max-width: 991px) {
	.fugu-hero-content {
		margin: 0 50px 50px;
		text-align: center;
	}
}
@media (max-width: 479px) {
	.fugu-hero-content {
		margin: 0 0 50px;
	}
}
@media (max-width: 991px) {
	.fugu-hero-content h1 {
		padding: 0 20px;
	}
}
@media (max-width: 575px) {
	.fugu-hero-content h1 {
		padding: 0;
	}
}
.fugu-hero-content p {
	font-size: 20px;
	line-height: 32px;
	letter-spacing: -0.5px;
	color: var(--gray-800);
}

.fugu-app-btn-wrap {
	margin: 55px 0 95px;
}
@media (max-width: 991px) {
	.fugu-app-btn-wrap {
		margin: 40px 0 60px;
	}
}
@media (max-width: 575px) {
	.fugu-app-btn-wrap {
		margin: 30px 0 40px;
	}
}

a.fugu-app-btn {
	display: inline-block;
	margin-right: 20px;
}
@media (max-width: 767px) {
	a.fugu-app-btn {
		width: 160px;
	}
}
@media (max-width: 575px) {
	a.fugu-app-btn {
		margin: 10px;
	}
}
a.fugu-app-btn:last-child {
	margin: 0;
}

.fugu-hero-client-area {
	display: flex;
	align-items: center;
}
@media (max-width: 991px) {
	.fugu-hero-client-area {
		justify-content: center;
	}
}
@media (max-width: 575px) {
	.fugu-hero-client-area {
		display: block;
		margin: 0;
	}
}
.fugu-hero-client-wrap {
	display: flex;
	align-items: center;
}
@media (max-width: 575px) {
	.fugu-hero-client-wrap {
		display: block;
		margin-bottom: 15px;
	}
}
.fugu-hero-client-thumb {
	flex-shrink: 0;
	margin-right: 20px;
}
@media (max-width: 575px) {
	.fugu-hero-client-thumb {
		margin: 0 0 15px;
	}
}
.fugu-hero-client-data h3 {
	line-height: 1;
	margin-bottom: 2px;
}
.fugu-hero-client-data p {
	font-size: 16px;
	opacity: 0.6;
}
.fugu-hero-client-rating {
	padding-left: 24px;
	margin-left: 24px;
	position: relative;
}
@media (max-width: 575px) {
	.fugu-hero-client-rating {
		margin: 0;
		padding: 0;
	}
}
.fugu-hero-client-rating:before {
	content: "";
	left: 0;
	top: 0;
	height: 39px;
	width: 2px;
	position: absolute;
	background-color: rgba(19, 17, 26, 0.1);
}
@media (max-width: 575px) {
	.fugu-hero-client-rating:before {
		content: none;
	}
}
.fugu-hero-client-rating h3 {
	line-height: 1;
	margin-bottom: 2px;
}
.fugu-hero-client-rating ul li {
	display: inline-block;
	font-size: 16px;
	opacity: 0.6;
	color: var(--gray-800);
}
.fugu-hero-client-rating ul li:last-child {
	margin-left: 7px;
}

.fugu-hero-thumb {
	position: absolute;
	bottom: 0;
	right: 0;
}
@media (max-width: 1399px) {
	.fugu-hero-thumb {
		right: -10%;
	}
}
@media (max-width: 1199px) {
	.fugu-hero-thumb {
		right: -24%;
	}
}
@media (max-width: 1100px) {
	.fugu-hero-thumb {
		right: -27%;
	}
}
@media (max-width: 991px) {
	.fugu-hero-thumb {
		position: relative;
		right: 0 !important;
	}
}

.fugu-shape1 {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: -1;
}

.fugu-shape2 {
	position: absolute;
	top: -20%;
	left: -25%;
	z-index: -1;
}

.fugu-hero-star {
	position: absolute;
	top: 0;
	right: 38%;
	-webkit-animation: rotate-animation 10s infinite linear;
	-moz-animation: rotate-animation 10s infinite linear;
	-o-animation: rotate-animation 10s infinite linear;
	animation: rotate-animation 10s infinite linear;
}

@keyframes rotate-animation {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.fugu-hero-section2 {
	z-index: 0;
	overflow: hidden;
	padding: 188px 0 430px;
	position: relative;
	background-color: var(--warning-200);
}
@media (max-width: 991px) {
	.fugu-hero-section2 {
		padding: 160px 0 405px;
	}
}
@media (max-width: 767px) {
	.fugu-hero-section2 {
		padding: 150px 0 190px;
	}
}
@media (max-width: 479px) {
	.fugu-hero-section2 {
		padding: 120px 0 190px;
	}
}
@media (max-width: 767px) {
	.fugu-hero-section2:after {
		content: "";
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		opacity: 0.15;
		position: absolute;
		background-color: #000;
	}
}
.fugu-hero-section2:before {
	content: "";
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	position: absolute;
	background: linear-gradient(180deg, #724fe5 6.25%, #32e7b1 31.77%, rgba(255, 255, 255, 0) 55.21%);
	transform: matrix(1, 0, 0, -1, 0, 0);
	border-radius: 50% 50% 0 0;
	width: 150vw;
	left: -25vw;
}
@media (max-width: 767px) {
	.fugu-hero-section2:before {
		border-radius: 0;
	}
}

.fugu-hero-content2 {
	max-width: 920px;
	margin: 0 auto;
	text-align: center;
}
.fugu-hero-content2 p {
	padding: 0 70px;
}
@media (max-width: 550px) {
	.fugu-hero-content2 p {
		padding: 0;
	}
}

.fugu-hero-btn-wrap {
	margin-top: 60px;
}
@media (max-width: 991px) {
	.fugu-hero-btn-wrap {
		margin-top: 40px;
	}
}
.fugu-hero-btn-wrap .fugu-btn {
	margin: 7px;
}

.fugu-single-thumb {
	text-align: center;
	margin-top: -320px;
	z-index: 1;
	position: relative;
}
@media (max-width: 767px) {
	.fugu-single-thumb {
		margin-top: -120px;
	}
}

.fugu-single-thumb-section {
	background-color: var(--warning-200);
}

.fugu-shape4 {
	position: absolute;
	top: 15%;
	left: 3%;
	-webkit-animation: float 4s ease-in-out infinite;
	animation: float 3s ease-in-out infinite;
}
@media (max-width: 767px) {
	.fugu-shape4 {
		display: none;
	}
}
/* Dashboard section style */
@media (max-width: 922px) {
	.fugu--dashboard-section  {
	padding: 200px 5px 5px !important;
	margin-top: 50px!important;
	}
}
.fugu--dashboard-section {
	z-index: 0;
	padding: 200px 40px 120px;
	background-size: cover;
	background-position: top center;
	position: relative;
	background-position: center bottom;
	background-color: var(--gray-800);
	margin-top: 100px;
	min-height: 100vh;
	background-image: url(./../../../assets/images/all-img/v3/dashboardBg.png);
}
.opacity-bg {
	background-color: rgba(0, 0, 0, 0.5); 
	color: #FFF;
	padding-top: 20px;
}
.fugu--dashboard-controller-holder {
	border-radius: 10px;
	text-align: center;
	position: relative;
	background: rgba(28, 30, 32, 0.5);
	padding: 25px 10px 25px 10px;
}
.fugu--dashboard-controller-holder::before  {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	margin: -2px;
	transition: all 0.4s;
	border-radius: inherit;
	/* background: linear-gradient(to right, #89D3FF, #89D3FF, #0092B4); */
}
.fugu-select-pay-method-holder {
	align-items: center;
	height: 100%;
	display: contents;
}
.fugu-send-payment-btn {
    display: flex;
    align-items: center;
	justify-content: center;
	width: 100%;

}
.fugu-send-payment {
	position: relative;
}
.react-international-phone-input-container .react-international-phone-input {
	color:#fff !important;
	background-color: #1e2930 !important;
	height: 60px !important;
	border-radius: 10px !important;
}
.react-international-phone-country-selector-button{
	height: 60px !important;
}
.fugu-user-balance div span {
	display: flex;
	align-items: center;
	height: 55px;
	padding: 5px;	
	width: 100%;
	justify-content: center;
	background-color: var(--gray-800);
}
 
.fugu-token-symbol div{
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 16px;
	position: relative;
	border-radius: 100%;
	padding: 1px;
	margin: 10px;
	color: white;
	cursor: pointer;
	transition: all 0.4s;
	width:30px;
	background: linear-gradient(to right, #89D3FF, #89D3FF, #0092B4);
}
.fugu-token-symbol div span {
	display: flex;
	align-items: center;
	height: 55px;
	padding: 5px;
	border-radius: 100%;
	width: 100%;
	justify-content: center;
	background-color: var(--gray-800);
}
  @media screen and (max-width: 768px) {
	.fugu-token-symbol div {
	  font-size: 14px;
	  padding: 4px 8px;
	  border-radius: 40px;
	}
  }
  
  @media screen and (max-width: 480px) {
	.fugu-token-symbol div {
	  font-size: 12px;
	  padding: 3px 6px;
	  border-radius: 30px;
	}
  }  
  .fugu-select-pay-method {
	color: var(--gray-10);
	font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
	justify-content: center;
  }
.fugu-to-receiver {
width: 100%;
}
.fugu-recievers-inputs {
	width: 85%;
}
.fugu-to-receiver span input {
	border: none !important;
	color: #fff;
	text-align: center;
}
.fugu-to-receiver span form {
	border: none !important;
	color: transparent;
	text-align: center;
	background-color: transparent;
}
.fugu-input-validation-issue {
	color: var(--danger-500);
}
#to::placeholder {
    color: #fff;
    opacity: 1;
}

.fugu-user-balance button{
	position: relative !important;
	top: 0px !important;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.fugu-max-btn {
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 16px;
	position: relative;
	border-radius: 20px;
	width: 40px;
	height: 40px;
	color: white;
	cursor: pointer;
	transition: all 0.4s;
	background: linear-gradient(to right, #89D3FF, #89D3FF, #0092B4);
	border: none;
    justify-content: center;
  }
  
  .fugu-max-btn.active {
	transition: all 0.4s;
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%);
  }
  
  .fugu-max-btn.active span {
	transition: all 0.4s;
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%);
  }
  
  .fugu-max-btn span{
	display: flex;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	justify-content: center;
	background-color: var(--gray-800);
	margin: 2px;
  }

/* end dashboard page style */
/* start claim fund page */
.claim-nft-item {
	margin: 10px;
}
.fugu-fund-table-wrapper {
	border-radius: 16px;
	background-color: rgba(0, 0, 0, 0.5); 
}
/* end claim fund page */
/* start schedule transaction */
.fugu-schedule-Table { 
	border-collapse: collapse;
	color: #fff; 
	width: 100%; 
	text-align: center;
 }
 .fugu-schedule-table-wrapper {
    border-radius: 16px;
    overflow: hidden;
}
 @media (max-width: 1500px) {
	.fugu-schedule-Table th, td {
		display: block;
		float: left;
		width: 100%;
	}

	.fugu-schedule-Table th {
		background-color: #000;
		color: white;
		padding-top: 10px;
	}

	.fugu-schedule-Table thead th {
		display: none;
	}

	.fugu-schedule-Table td::before {
		display: block;
		font-weight: bold;
		margin-top: .33em;
		margin-bottom: .33em;
	}
	.fugu-schedule-Table td:nth-child(1):before {
		content: "TO";
	}
	.fugu-schedule-Table td:nth-child(2):before {
		content: "Value";
	}

	.fugu-schedule-Table td:nth-child(3):before {
		content: "Number Of Execution";
	}

	.fugu-schedule-Table td:nth-child(4):before {
		content: "Current Number Of Execution";
	}

	td:nth-child(5):before {
		content: "Time Gap between Transactions";
	}

	.fugu-schedule-Table td:nth-child(6):before {
		content: "Date Of Execution";
	}

	.fugu-schedule-Table td:nth-child(7):before {
		content: "Status";
	}

	.fugu-schedule-Table td:nth-child(8):before {
		content: "Action";
	}
	.fugu-schedule-Table tbody tr td:last-child {
		margin-bottom: 20px;
	}
	.fugu-schedule-Table {
		border: none;
	}
	.fugu-schedule-Table tbody tr td:first-child li{
		list-style: none;
		justify-content: center;
		text-align: center;
		display: flex;
	}
}

@media (min-width: 700px) and (max-width: 1500px) {
	.fugu-schedule-Table td {
		width: 25% !important;
        min-height: 100px;
        box-sizing: border-box;
	}
	.fugu-schedule-Table tbody tr td:first-child ul{
		margin-left: 0;
	}
	.fugu-schedule-Table tbody tr td:first-child li{
		font-size: 75%;
		list-style: none;
		justify-content: center;
		text-align: center;
		display: flex;
	}
}

.fugu-schedule-Table td:nth-child(2){
    min-width: 150px;
	word-wrap: break-word;
}

.fugu-schedule-Table td:nth-child(1) ul {
	margin: 0;
	text-align: left;
}
.fugu-schedule-Table td:nth-child(1) ul li{
	list-style-type: disc;
	text-align: left;
	padding-inline: 10px;
}
.fugu-table-border {
	/* border: 0.5px solid #fff; */
	background-color: rgba(0, 0, 0, 0.5); 
}
/* end schedule transaction */
/* start tokens table */
.fugu-tokens-table {
	cursor: pointer;
	/* justify-content: center;*/
	align-items: center; 
	/* border: 1px solid white; */
	padding: 10px;
	
}
.fugu-tokens-table-holder {
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 18px;
}

.fugu-tokens-table h4 {
	/* color: var(--warning-500); */
	color: #fff;
	max-height: 30px;
	overflow: hidden;
}
.fugu-tokens-table p {
	color: #fff;
	align-self: center;
	margin-left: 10px;
}
.fugu-tokens-table h4.active {
	color: var(--warning-500);
	text-decoration: underline ;
}
/* end token table */
/* start password Modal */
#password-modal .modal-content {
	margin: 2px ;
	color: white ;
	cursor: pointer ;
	transition: all 0.4s ;
	background: rgba(23, 23, 25, 0.9) ;
  }
  .bordered-modal #password-modal .modal-content {
    border: 2px solid #0092B4;
}
  #password-modal .modal-title {
	color: white ;
	font-family: "Space Grotesk", sans-serif ;
	align-self: center;
  }
  #password-modal .modal-header {
	border-bottom: none ;
	justify-content: center ;
  }
  #password-modal .modal-footer {
	border-top: none ;
  }
  #password-modal .form-control:focus{
	box-shadow: none;
	background-color: transparent !important;
	color: var(--gray-10) ;
  }
  #password-modal .form-control {
	background-color: transparent !important;
  }
  #password-modal .form-control:not(active){
	color:white ;
 }
 #password-modal .form-control::placeholder {
    color: #cccccc ;
}
  
/* end password modal */
/* start privacy Policy */
.responsive-table {
	width: 100%;
	font-size:14px;
	background:#FFF;
	border-collapse: collapse;
	border-spacing: 0;
	border:2px solid grey;
  }
  
  .responsive-table th,
  .responsive-table td {
	text-align: left;
	padding: 8px;
	border-bottom: 1px solid #ddd;
  }
  
  .responsive-table th {
	background-color: #f2f2f2;
  }
   .responsive-table  span {
	  display: block;
	  padding:10px 0;
	  height:10vh;
  }
  
  @media screen and (max-width:600px){
	.responsive-table {
	overflow-x:auto;
	}
  }

  .privacy-container {
	padding: 200px 4rem 100px;
    background-color: #18191f;
	color: #f5f6f7;
  }
  .privacy-container h1,h4{
	color: #f5f6f7;
  }
  .privacy-container table {
	color: black
  }
  .privacy-container li {
	list-style: disc;
  }
/* end privacy policy */
/* override to all inputs type number to hide wheel */
input[type="number"] {
    -moz-appearance: textfield;
}
.fugu-shape5 {
	position: absolute;
	right: 5%;
	bottom: 25%;
	-webkit-animation: float 3s ease-in-out infinite;
	animation: float 3s ease-in-out infinite;
}
@media (max-width: 767px) {
	.fugu-shape5 {
		display: none;
	}
}

@-webkit-keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}
@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}
.fugu--hero-section {
	z-index: 0;
	padding: 250px 0 120px;
	background-size: cover;
	position: relative;
	background-position: center bottom;
	background-color: var(--gray-800);
}
@media (max-width: 991px) {
	.fugu--hero-section {
		padding: 175px 0 100px;
	}
	.fugu--header-connect-option-holder::before{
		background: transparent !important;
	}
}
@media (max-width: 767px) {
	.fugu--hero-section {
		padding: 150px 0 80px;
	}
	.fugu--header-connect-option-holder::before{
		background: var(--gray-800) !important;
	}
	.fugu--header-connect-option-holder {
		background: transparent !important;
	}
}
@media (max-width: 575px) {
	.fugu--hero-section {
		padding: 190px 0 80px;
	}
	.fugu--header-connect-option-holder::before{
		background: var(--gray-800) !important;
	}
}

.fugu--hero-content {
	max-width: 636px;
}
.fugu--hero-content h1 {
	color: white;
	font-family: "Space Grotesk", sans-serif;
}
@media (max-width: 991px) {
	.fugu--hero-content {
		text-align: center;
		margin: 0 auto;
	}
}
@media (max-width: 767px) {
	.fugu--hero-content {
		max-width: 500px;
	}
}
.fugu--hero-content p {
	font-size: 20px;
	line-height: 32px;
	letter-spacing: -0.5px;
	margin-right: 80px;
	color: white;
}
@media (max-width: 991px) {
	.fugu--hero-content p {
		margin-right: 0;
	}
}

.fugu--hero-shape1 {
	position: absolute;
	bottom: -116px;
	right: 14%;
	z-index: -1;
}
@media (max-width: 1600px) {
	.fugu--hero-shape1 {
		right: 3%;
	}
}
@media (max-width: 1400px) {
	.fugu--hero-shape1 {
		right: 0;
	}
}
@media (max-width: 1199px) {
	.fugu--hero-shape1 {
		bottom: 0;
		width: 70%;
	}
}
@media (max-width: 991px) {
	.fugu--hero-shape1 {
		right: 110px;
		width: 100%;
	}
}

.fugu--hero-shape2 {
	width: 18%;
	height: 37%;
	position: absolute;
	background: #2c04fe;
	opacity: 1;
	filter: blur(136.18px);
	border-radius: 100%;
	top: 15%;
	right: 10%;
	z-index: -1;
}
@media (max-width: 1600px) {
	.fugu--hero-shape2 {
		right: 0;
	}
}

.fugu--btn-wrap.fugu--hero-btn {
	margin: 55px 0 0;
}
@media (max-width: 991px) {
	.fugu--btn-wrap.fugu--hero-btn {
		margin: 35px 0 0;
	}
}
.fugu--btn-wrap.fugu--hero-btn .fugu--btn {
	margin-right: 20px;
}
.fugu--btn-wrap.fugu--hero-btn .fugu--btn:last-child {
	margin-right: 0;
}
@media (max-width: 410px) {
	.fugu--btn-wrap.fugu--hero-btn .fugu--btn {
		margin: 0 10px 15px;
	}
}

.fugu--btn-wrap {
	margin: 45px 0 0;
}

.fugu--hero-right {
	width: 490px;
	float: right;
	z-index: 0;
	transform: rotate(3deg);
	position: relative;
}
@media (max-width: 1400px) {
	.fugu--hero-right {
		width: 100%;
	}
}
@media (max-width: 991px) {
	.fugu--hero-right {
		float: none;
		transform: rotate(0deg) !important;
		margin: 0 auto;
		margin-top: 50px;
		width: 480px;
	}
}
@media (max-width: 767px) {
	.fugu--hero-right {
		width: 100%;
	}
}
.fugu--four-column {
	margin: 0 auto;
}
.fugu--hero-right .fugu--card-wrap {
	background: rgba(90, 75, 124, 0.5);
	backdrop-filter: blur(400px);
}
.fugu--hero-right .fugu--card-wrap:before {
	content: none;
}
.fugu--hero-right .fugu--card-wrap:hover {
	box-shadow: 0 0 0 1px #fff;
}
.fugu--hero-right .fugu--card-data {
	margin: 0;
	padding: 40px 10px 20px;
}
@media (max-width: 575px) {
	.fugu--hero-right .fugu--card-data {
		padding: 20px 5px 5px;
	}
}
.fugu--hero-right .fugu--card-data h3 {
	font-size: 30px;
}
@media (max-width: 575px) {
	.fugu--hero-right .fugu--card-data h3 {
		font-size: 24px;
	}
}
.fugu--hero-right .fugu--card-data .fugu--card-footer-data span {
	display: block;
	margin-bottom: 10px;
}
.fugu--hero-right .fugu--card-data .fugu--card-footer-data h4 {
	font-size: 30px;
}
@media (max-width: 575px) {
	.fugu--hero-right .fugu--card-data .fugu--card-footer-data h4 {
		font-size: 24px;
	}
}

.fugu--hero-section2 {
	z-index: 0;
	padding: 230px 0 235px;
	position: relative;
	background-color: var(--warning-600);
}
@media (max-width: 1199px) {
	.fugu--hero-section2 {
		padding: 150px 0 120px;
	}
}
@media (max-width: 767px) {
	.fugu--hero-section2 {
		padding: 150px 0 80px;
	}
}
@media (max-width: 479px) {
	.fugu--hero-section2 {
		padding: 120px 0 80px;
	}
}

.fugu--hero-content2 {
	max-width: 760px;
	text-align: center;
	margin: 0 auto;
	position: relative;
}
@media (max-width: 991px) {
	.fugu--hero-content2 {
		max-width: 565px;
	}
}
@media (max-width: 767px) {
	.fugu--hero-content2 {
		max-width: 445px;
	}
}
.fugu--hero-content2 h1 {
	color: var(--gray-800);
	font-family: "Public Sans", sans-serif;
}
.fugu--hero-content2 p {
	margin-right: 0;
	color: var(--gray-800);
	margin: 0 20px;
}
.fugu--hero-content2 .fugu--content-star {
	position: absolute;
	top: 115px;
	right: -15px;
	-webkit-animation: float 3s ease-in-out infinite;
	animation: float 3s ease-in-out infinite;
}

.fugu--hero-mocup {
	position: absolute;
	animation: zoom-in-zoom-out 5s ease-out infinite;
}
@media (max-width: 767px) {
	.fugu--hero-mocup {
		display: none;
	}
}
.fugu--hero-mocup.mocup1 {
	top: 15%;
	width: 22.5%;
	left: -35%;
}
@media (max-width: 1400px) {
	.fugu--hero-mocup.mocup1 {
		top: 15%;
		width: 17.5%;
		left: -20%;
	}
}
@media (max-width: 1199px) {
	.fugu--hero-mocup.mocup1 {
		top: 15%;
		width: 15%;
		left: -13%;
	}
}
.fugu--hero-mocup.mocup2 {
	bottom: -30%;
	width: 26.4%;
	left: -12%;
	filter: blur(2px);
	transition: all 0.4s;
}
.fugu--hero-mocup.mocup2:hover {
	filter: blur(0px);
}
@media (max-width: 1400px) {
	.fugu--hero-mocup.mocup2 {
		bottom: -15%;
		width: 20%;
		left: -10%;
	}
}
@media (max-width: 1199px) {
	.fugu--hero-mocup.mocup2 {
		width: 15%;
	}
}
.fugu--hero-mocup.mocup3 {
	top: -3%;
	width: 23.7%;
	right: -37%;
	filter: blur(2px);
	transition: all 0.4s;
}
.fugu--hero-mocup.mocup3:hover {
	filter: blur(0px);
}
@media (max-width: 1400px) {
	.fugu--hero-mocup.mocup3 {
		top: 0%;
		width: 17.7%;
		right: -24%;
	}
}
@media (max-width: 1199px) {
	.fugu--hero-mocup.mocup3 {
		width: 15%;
		right: -13%;
	}
}
.fugu--hero-mocup.mocup4 {
	bottom: -30%;
	width: 26.4%;
	right: -10%;
}
@media (max-width: 1400px) {
	.fugu--hero-mocup.mocup4 {
		bottom: -15%;
		width: 20%;
		right: -10%;
	}
}
@media (max-width: 1199px) {
	.fugu--hero-mocup.mocup4 {
		width: 15%;
		right: -5%;
	}
}

@keyframes zoom-in-zoom-out {
	0% {
		transform: scale(1, 1);
	}
	50% {
		transform: scale(1.15, 1.15);
	}
	100% {
		transform: scale(1, 1);
	}
}
.fugu--hero-section3 {
	z-index: 0;
	padding: 280px 0 120px;
	position: relative;
	background-size: cover;
	background-position: center;
	background-color: var(--gray-800);
}
@media (max-width: 991px) {
	.fugu--hero-section3 {
		padding: 175px 0 100px;
	}
}
@media (max-width: 479px) {
	.fugu--hero-section3 {
		padding: 130px 0 80px;
	}
}

.fugu--hero-content3 p {
	margin-right: 0;
}
.fugu--hero-content3 .fugu--btn.bg-blue {
	background-color: var(--gray-900);
}
.fugu--hero-content3 .fugu--btn.bg-blue:hover {
	background-color: var(--primary-500);
}
.fugu--hero-content3 .fugu--btn.bg-blue.active {
	background-color: var(--primary-500);
	box-shadow: 5px 5px 0px 0px var(--gray-10);
}

.fugu--thumb-three {
	text-align: right;
}
@media (max-width: 991px) {
	.fugu--thumb-three {
		text-align: center;
		margin-top: 150px;
	}
}

.fugu--hero-thumb3 {
	transform: rotate(0deg);
	display: inline-block;
	position: relative;
}
.fugu--hero-thumb3 .fugu--bitcoin {
	position: absolute;
	transform: translatey(0px);
	-webkit-animation: float 3s ease-in-out infinite;
	animation: float 3s ease-in-out infinite;
}
.fugu--hero-thumb3 .fugu--bitcoin.bitcoin-one {
	top: -95px;
	right: 130px;
}
.fugu--hero-thumb3 .fugu--bitcoin.bitcoin-two {
	top: 20px;
	left: 25px;
	width: 29%;
}
.fugu--hero-thumb3 .fugu--bitcoin.bitcoin-three {
	top: 5px;
	right: 20px;
}

@-webkit-keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}
@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}
.fugu-counter-section {
	padding: 80px 0;
}
@media (max-width: 1199px) {
	.fugu-counter-section .fugu-default-content {
		text-align: center;
		max-width: 600px;
		margin: 0 auto;
	}
}
.fugu-counter-wrap {
	display: flex;
	text-align: center;
}
@media (max-width: 1199px) {
	.fugu-counter-wrap {
		margin-bottom: 30px;
		justify-content: center;
		flex-wrap: wrap;
	}
}
.fugu-counter-data {
	width: 250px;
	height: 250px;
	border-radius: 100%;
	border: 1px solid #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 40px;
	position: relative;
}
@media (max-width: 1399px) {
	.fugu-counter-data {
		padding: 25px;
		width: 200px;
		height: 200px;
	}
}
@media (max-width: 1199px) {
	.fugu-counter-data {
		padding: 30px;
		width: 220px;
		height: 220px;
	}
}
.fugu-counter-data:nth-child(2) {
	margin-left: -30px;
}
@media (max-width: 479px) {
	.fugu-counter-data:nth-child(2) {
		margin-left: 0;
		margin-top: -30px;
	}
}
@media (max-width: 479px) {
	.fugu-counter-data:nth-child(2) {
		margin-top: -30px;
	}
}
.fugu-counter-data:nth-child(3) {
	margin-left: -30px;
}
@media (max-width: 767px) {
	.fugu-counter-data:nth-child(3) {
		margin-left: 0;
		margin-top: -58px;
	}
}
@media (max-width: 479px) {
	.fugu-counter-data:nth-child(3) {
		margin-top: -30px;
	}
}
.fugu-counter-data h2 {
	color: #fff;
	margin-bottom: 10px;
}
.fugu-counter-data p {
	font-size: 16px;
	line-height: 24px;
	color: #fff;
}

.fugu--counter-wrap {
	display: flex;
	max-width: 385px;
	justify-content: space-between;
	margin-top: 55px;
}
@media (max-width: 991px) {
	.fugu--counter-wrap {
		margin: 0 auto;
		margin-top: 40px;
	}
}
@media (max-width: 410px) {
	.fugu--counter-wrap {
		margin-top: 25px;
	}
}

.fugu--counter-data h2 {
	font-size: 48px;
	line-height: 1;
	color: white;
}
.fugu--counter-data h2 strong {
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}
.fugu--counter-data p {
	font-size: 18px;
	line-height: 30px;
	margin: 0;
}

@media (max-width: 991px) {
	.fugu--counter-wrap2 {
		margin: 30px 0 0;
	}
}
.fugu--counter-wrap2 .fugu--counter-data h2 {
	margin: 0 0 10px;
}
.fugu--counter-wrap2 .fugu--counter-data h2 strong {
	background: none;
	color: var(--gray-800);
	-webkit-text-fill-color: var(--gray-800);
}
.fugu--counter-wrap2 .fugu--counter-data p {
	font-weight: 600;
	opacity: 1 !important;
}

.fugu--counter-wrap3 {
	max-width: 100%;
	margin: 0;
	flex-wrap: wrap;
}
@media (max-width: 767px) {
	.fugu--counter-wrap3 .fugu--counter-data {
		flex: 0 0 50%;
		text-align: center;
		margin-bottom: 40px;
	}
	.fugu--counter-wrap3 .fugu--counter-data:nth-child(3),
	.fugu--counter-wrap3 .fugu--counter-data:nth-child(4) {
		margin-bottom: 0;
	}
}
.fugu--counter-wrap3 .fugu--counter-data h2 {
	z-index: 0;
	margin: 0 0 20px;
	font-size: 60px;
	color: var(--gray-800);
	font-family: "Space Grotesk", sans-serif;
	position: relative;
}
@media (max-width: 1199px) {
	.fugu--counter-wrap3 .fugu--counter-data h2 {
		font-size: 48px;
	}
}
@media (max-width: 767px) {
	.fugu--counter-wrap3 .fugu--counter-data h2 {
		font-size: 36px;
	}
}
.fugu--counter-wrap3 .fugu--counter-data h2:before {
	content: " ";
	left: 0;
	top: -15px;
	z-index: -1;
	width: 90px;
	height: 90px;
	border-radius: 100%;
	position: absolute;
	background-color: rgba(255, 107, 85, 0.2);
}
@media (max-width: 1199px) {
	.fugu--counter-wrap3 .fugu--counter-data h2:before {
		width: 78px;
		height: 78px;
	}
}
@media (max-width: 767px) {
	.fugu--counter-wrap3 .fugu--counter-data h2:before {
		width: 60px;
		height: 60px;
		left: 45%;
		transform: translateX(-50%);
	}
}
.fugu--counter-wrap3 .fugu--counter-data h2 strong {
	background: none;
	color: var(--gray-800);
	-webkit-text-fill-color: var(--gray-800);
}
.fugu--counter-wrap3 .fugu--counter-data p {
	opacity: 1 !important;
	font-size: 30px;
	letter-spacing: -1px;
	color: #171719;
}
@media (max-width: 1199px) {
	.fugu--counter-wrap3 .fugu--counter-data p {
		font-size: 24px;
	}
}
@media (max-width: 991px) {
	.fugu--counter-wrap3 .fugu--counter-data p {
		font-size: 20px;
	}
}
@media (max-width: 767px) {
	.fugu--counter-wrap3 .fugu--counter-data p {
		font-size: 18px;
	}
}

.fugu-team-page {
	padding-top: 110px;
}
@media (max-width: 991px) {
	.fugu-team-page {
		padding-top: 100px;
	}
}
@media (max-width: 767px) {
	.fugu-team-page {
		padding-top: 80px;
	}
}

.fugu-team-wrap {
	margin-bottom: 24px;
}
.fugu-team-wrap.wrap2 {
	background: #ffffff;
	box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
	border-radius: 10px;
	height: calc(100% - 24px);
	overflow: hidden;
	padding: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
}
.fugu-team-wrap.wrap2 h4 {
	margin-bottom: 40px;
}
@media (max-width: 767px) {
	.fugu-team-wrap.wrap2 {
		height: 100%;
	}
}
.fugu-team-thumb {
	position: relative;
	border-radius: 10px;
	overflow: hidden;
}
.fugu-team-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all 0.4s;
}
.fugu-team-thumb:hover img {
	transform: scale(1.07) rotate(2deg);
}
.fugu-team-data {
	bottom: 20px;
	padding: 20px;
	margin-left: 20px;
	width: calc(100% - 40px);
	border-radius: 10px;
	position: absolute;
	background-color: #fff;
}
.fugu-team-data h5 {
	font-weight: 700;
	display: block;
	margin-bottom: 5px;
	line-height: 1;
}
.fugu-team-data h5 a {
	color: var(--gray-800);
	transition: all 0.4s;
}
.fugu-team-data h5 a:hover {
	color: var(--warning-500);
}
.fugu-team-data p {
	font-size: 14px;
	margin: 0;
}
.fugu-team-data-wrap {
	display: flex;
	justify-content: space-between;
}

.fugu-single-team-page {
	padding: 120px 0 100px;
}
@media (max-width: 991px) {
	.fugu-single-team-page {
		padding: 100px 0;
	}
}
@media (max-width: 767px) {
	.fugu-single-team-page {
		padding: 80px 0;
	}
}

.fugu-team-single-wrap {
	padding: 60px;
	border-radius: 10px;
	background: #ffffff;
	box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
}
@media (max-width: 1199px) {
	.fugu-team-single-wrap {
		padding: 40px;
	}
}
@media (max-width: 767px) {
	.fugu-team-single-wrap {
		padding: 30px;
	}
}
.fugu-team-single-thumb {
	overflow: hidden;
	border-radius: 10px;
}
@media (max-width: 991px) {
	.fugu-team-single-thumb {
		margin-bottom: 30px;
	}
}
.fugu-team-single-thumb img {
	width: 100%;
	height: 100%;
}
@media (max-width: 1199px) {
	.fugu-team-single-data {
		margin-left: 40px;
	}
}
@media (max-width: 991px) {
	.fugu-team-single-data {
		margin: 0;
	}
}
.fugu-team-single-data h2 {
	margin-bottom: 10px;
}
.fugu-team-single-data span {
	display: block;
	font-weight: 600;
	margin-bottom: 15px;
	color: var(--gray-800);
}

.fugu-team-single-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 35px;
}
@media (max-width: 479px) {
	.fugu-team-single-footer {
		display: block;
	}
}
.image-overlay {
  position: relative;
}

.image-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-overlay.disabled::before {
  background-color: rgba(28, 30, 32, 0.7);
}

.fugu-team-single-footer .fugu-social-icon {
	margin: 0;
}
@media (max-width: 479px) {
	.fugu-team-single-footer .fugu-social-icon {
		margin: 0 0 20px;
	}
}
.fugu-team-single-footer .fugu-social-icon ul li a {
	background-color: rgba(19, 17, 26, 0.05);
}
.fugu-team-single-footer .fugu-social-icon ul li a:hover {
	background-color: var(--gray-800);
}
.fugu-team-single-footer .fugu-social-icon ul li a path {
	fill: var(--gray-800);
}
.fugu-team-single-footer .fugu-social-icon ul li a:hover path {
	fill: var(--warning-500);
}

.fugu-team-information {
	margin-bottom: 24px;
}
.fugu-team-information-wrap {
	padding-bottom: 70px;
}
.fugu-team-information ul li {
	margin-bottom: 10px;
	padding-left: 20px;
	position: relative;
}
.fugu-team-information ul li:before {
	content: "";
	left: 0;
	top: 10px;
	width: 8px;
	height: 8px;
	border-radius: 100px;
	position: absolute;
	background-color: var(--gray-800);
}
.fugu-team-information ul li:last-child {
	margin-bottom: 0;
}
.fugu-team-information ul li a {
	transition: all 0.4s;
	color: rgba(19, 17, 26, 0.8);
}
.fugu-team-information ul li a:hover {
	color: var(--warning-500);
}

.fugu--team-section {
	position: relative;
	z-index: 0;
	background-color: var(--gray-800);
	border-bottom: 1px solid #26242c;
}

.fugu--team-wrap {
	display: flex;
	align-items: center;
	border-radius: 10px;
	padding: 20px;
	margin-bottom: 24px;
	justify-content: space-between;
	background-color: #1c1e20;
	border: 1px solid white;
}
.fugu--team-left {
	display: flex;
	align-items: center;
}
.fugu--team-thumb {
	width: 80px;
	height: 80px;
	border-radius: 10px;
	overflow: hidden;
	margin-right: 20px;
}
.fugu--team-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
}
.fugu--team-data h4 {
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	margin-bottom: 10px;
	color: var(--gray-10);
	font-family: "Inter", sans-serif;
}
.fugu--team-data p {
	opacity: 0.8;
	margin: 0;
	color: var(--gray-10);
}

.fugu--team-two,
.fugu--team-three {
	border: none;
	background: var(--gray-10);
}
.fugu--team-two .fugu--team-wrap,
.fugu--team-three .fugu--team-wrap {
	display: block;
	text-align: center;
	border: none;
	padding: 0;
	background-color: transparent;
}
.fugu--team-two .fugu--team-thumb,
.fugu--team-three .fugu--team-thumb {
	width: 100%;
	height: auto;
	border-radius: 0;
	margin-bottom: 20px;
}
.fugu--team-two .fugu--team-data h4,
.fugu--team-three .fugu--team-data h4 {
	line-height: 1;
	margin-bottom: 7px;
	color: var(--gray-800);
}
.fugu--team-two .fugu--team-data p,
.fugu--team-three .fugu--team-data p {
	font-size: 18px;
	opacity: 0.8;
	color: var(--gray-800);
	margin: 0 0 20px;
}

.fugu--team-two {
	padding: 0 0 120px;
}
@media (max-width: 991px) {
	.fugu--team-two {
		padding: 0 0 100px;
	}
}
@media (max-width: 767px) {
	.fugu--team-two {
		padding: 0 0 80px;
	}
}
.fugu--team-two .fugu--team-wrap {
	margin-bottom: 40px;
}

.version-04.fugu--team-two {
	padding: 110px 0 80px;
}
@media (max-width: 991px) {
	.version-04.fugu--team-two {
		padding: 100px 0 60px;
	}
}
@media (max-width: 767px) {
	.version-04.fugu--team-two {
		padding: 80px 0 40px;
	}
}

.fugu--team-section.fugu--team-three {
	background-color: var(--gray-10);
	border: none;
}

.fugu--team-three .fugu--team-wrap {
	padding: 40px;
	border-radius: 3px;
	border: 1px solid var(--gray-800);
}
.fugu--team-three .fugu--team-thumb {
	width: 216px;
	height: 216px;
	border-radius: 100%;
	margin: 0 auto 20px;
}
@media (min-width: 1200px) and (max-width: 1400px) {
	.fugu--team-three .fugu--team-thumb {
		width: 175px;
		height: 175px;
	}
}

.fugu--inner-section.bg-white {
	background: var(--gray-10);
}

.fugu--team-button {
	text-align: center;
	margin-top: 40px;
}

.team-page .fugu--breadcrumbs-data {
	max-width: 880px;
}

.dark-version .fugu--team-data p,
.dark-version .fugu--team-data h4 {
	color: #fff;
}

.fugu-iconbox-wrap {
	border-radius: 3px;
	padding: 39px;
	display: flex;
	transition: all 0.4s;
	margin-bottom: 24px;
}
@media (max-width: 991px) {
	.fugu-iconbox-wrap {
		padding: 30px;
		background-color: var(--warning-300);
	}
}
@media (max-width: 479px) {
	.fugu-iconbox-wrap {
		display: block;
	}
}
.fugu-iconbox-wrap:hover {
	background-color: var(--warning-300);
}
.fugu-iconbox-wrap.fugu-iconbox-wrap5 {
	border: 1px solid rgba(19, 17, 26, 0.1);
}
.fugu-iconbox-wrap.fugu-iconbox-wrap5:hover {
	background: #ffffff;
	border: 1px solid transparent;
	box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
}
.fugu-iconbox-icon {
	flex-shrink: 0;
	margin-right: 40px;
}
@media (max-width: 991px) {
	.fugu-iconbox-icon {
		margin-right: 30px;
	}
}
@media (max-width: 479px) {
	.fugu-iconbox-icon {
		margin: 0 0 25px;
	}
}

.fugu-icon-btn {
	font-size: 16px;
	font-weight: 700;
	color: var(--gray-800);
}
.fugu-icon-btn:hover {
	color: var(--gray-800);
}
.fugu-icon-btn img {
	transition: all 0.4s;
	margin-left: 7px;
}

.fugu-iconbox-wrap2 {
	padding: 30px;
	border-radius: 10px;
	margin-bottom: 24px;
	background-color: var(--warning-100);
}
.fugu-iconbox-icon2 {
	margin-bottom: 25px;
}

.fugu-iconbox-wrap3 {
	padding: 40px;
	text-align: center;
	transition: all 0.4s;
	border-radius: 3px;
	margin-bottom: 24px;
}
.fugu-iconbox-wrap3:hover {
	background-color: #0d0d0e;
}
@media (max-width: 991px) {
	.fugu-iconbox-wrap3 {
		padding: 30px;
		background-color: #0d0d0e;
	}
}
.fugu-iconbox-icon3 {
	margin-bottom: 30px;
}
.fugu-iconbox-data3 h4,
.fugu-iconbox-data3 p {
	color: var(--gray-10);
}
.fugu-iconbox-data3 p {
	opacity: 0.8;
}

.fugu-iconbox-wrap4 {
	padding: 29px;
	text-align: center;
	transition: all 0.4s;
	border-radius: 10px;
	margin-bottom: 24px;
	border: 1px solid rgba(19, 17, 26, 0.1);
}
.fugu-iconbox-icon4 {
	margin-bottom: 30px;
}

.fugu-iconbox-wrap6 {
	text-align: center;
	padding: 0 22px;
	margin-bottom: 24px;
}
@media (max-width: 1199px) {
	.fugu-iconbox-wrap6 {
		padding: 0 5px;
	}
}
@media (max-width: 767px) {
	.fugu-iconbox-wrap6 {
		padding: 0 20px;
	}
}
.fugu-iconbox-icon6 {
	margin-bottom: 30px;
}

.fugu-iconbox-border {
	position: relative;
}
.fugu-iconbox-border:before {
	content: "";
	top: 30px;
	left: 50%;
	width: calc(100% - 30%);
	height: 1px;
	position: absolute;
	transform: translateX(-50%);
	border: 1px dashed rgba(19, 17, 26, 0.1);
}
@media (max-width: 991px) {
	.fugu-iconbox-border:before {
		width: calc(100% - 50%);
	}
}
@media (max-width: 767px) {
	.fugu-iconbox-border:before {
		content: none;
	}
}

.fugu--iconbox-wrap {
	z-index: 0;
	padding: 0 15px;
	text-align: center;
	position: relative;
	margin-bottom: 24px;
}
@media (max-width: 1400px) {
	.fugu--iconbox-wrap {
		padding: 0;
	}
}
.fugu--iconbox-wrap:before {
	content: " ";
	right: -68px;
	top: 106px;
	width: 108px;
	height: 2px;
	z-index: -1;
	position: absolute;
	background-image: url("../../images/all-img/v3/line.png");
}
.fugu--iconbox-thumb {
	margin-bottom: 30px;
}
.fugu--iconbox-data h4 {
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 15px;
	font-family: "Inter", sans-serif;
	display: inline-block;
	padding: 0 10px;
	color: white;
	background-color: var(--gray-800);
}
.fugu--iconbox-data p {
	color: #ffffff;
	opacity: 0.8;
}
.fugu--iconbox-data p:last-child {
	margin: 0;
}

.fugu--roadmap-section .col-xl-3.col-md-6:nth-child(4) .fugu--iconbox-wrap:before {
	content: none;
}
@media (max-width: 1199px) {
	.fugu--roadmap-section .col-xl-3.col-md-6:nth-child(2) .fugu--iconbox-wrap:before {
		content: none;
	}
}
@media (max-width: 767px) {
	.fugu--roadmap-section .fugu--iconbox-wrap:before {
		content: none;
	}
}

.fugu--roadmap-v2 .fugu--iconbox-wrap {
	text-align: left;
	padding: 0;
	padding-right: 30px;
}
.fugu--roadmap-v2 .fugu--iconbox-wrap:before {
	content: none;
}
.fugu--roadmap-v2 .fugu--iconbox-data h4 {
	padding: 0;
	margin-bottom: 20px;
	color: var(--gray-800);
	background-color: transparent;
}
.fugu--roadmap-v2 .fugu--iconbox-data span {
	font-weight: 600;
	font-size: 18px;
	display: block;
	margin-bottom: 10px;
	color: var(--gray-800);
}
.fugu--roadmap-v2 .fugu--iconbox-data p {
	font-size: 16px;
	line-height: 24px;
	color: var(--gray-800);
	opacity: 0.8;
}

.fugu--iconbox-wrap3 {
	padding: 40px;
	border-radius: 3px;
	background: linear-gradient(118.28deg, #17181a -8.97%, #636675 141.84%);
}
@media (max-width: 991px) {
	.fugu--iconbox-wrap3 {
		padding: 30px;
	}
}
.fugu--iconbox-wrap3:before {
	content: none;
}
.fugu--iconbox-wrap3:hover .fugu--iconbox-thumb {
	background-color: var(--gray-10);
}
.fugu--iconbox-wrap3:hover .fugu--iconbox-thumb path {
	fill: var(--primary-500);
}
.fugu--iconbox-wrap3 .fugu--iconbox-thumb {
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	border-radius: 100%;
	margin: 0 auto 30px;
	justify-content: center;
	transition: all 0.4s;
	background-color: var(--primary-500);
}
.fugu--iconbox-wrap3 .fugu--iconbox-thumb path {
	transition: all 0.4s;
}
.fugu--iconbox-wrap3 .fugu--iconbox-data h4 {
	padding: 0;
	background-color: transparent;
}

.fugu--iconbox-wrap4 {
	border-radius: 10px;
	/* border: 1px solid var(--gray-10); */
	background: rgba(0, 0, 0, 0.3);
}
.collection-creation-icon img{
    width: 120px;
}
.collection-creation .fugu--iconbox-data h4{
	font-size: 18px;
	font-weight: 600;
}
.fugu--iconbox-wrap4 .fugu--iconbox-thumb {
	background: linear-gradient(0deg, #addcff 0%, #eaf6ff 50.28%, #eaf6ff 100%);
}

.fugu--iconbox-wrap5 {
	background: #211d2e;
	border-radius: 10px;
}
.fugu--iconbox-wrap5 .fugu--iconbox-thumb {
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%);
}
.fugu--iconbox-wrap5 .fugu--iconbox-data a {
	font-weight: 700;
	font-size: 16px;
	color: #ffffff;
}
.fugu--iconbox-wrap5 .fugu--iconbox-data a img {
	margin-left: 10px;
}

.fugu-accordion-item {
	margin-bottom: 40px;
	padding-bottom: 40px;
	border-bottom: 1px solid var(--gray-800);
}
.fugu-accordion-item h4 {
	font-weight: bold;
}
.fugu-accordion-item:last-child {
	margin: 0;
	padding: 0;
	border: none;
}
.fugu-accordion-wrap2 {
	margin-top: 75px;
}
@media (max-width: 991px) {
	.fugu-accordion-wrap2 {
		margin-top: 40px;
	}
}
.fugu-accordion-wrap2 .fugu-accordion-item {
	display: flex;
	margin-bottom: 24px;
	padding-bottom: 24px;
	align-items: center;
	justify-content: space-between;
}
.fugu-accordion-wrap2 .fugu-accordion-item:last-child {
	padding: 0;
	margin: 0;
}
.fugu-accordion-wrap2 .fugu-accordion-item h4 {
	margin: 0;
	font-weight: 700;
	font-family: "Inter", sans-serif;
}
@media (max-width: 1199px) {
	.fugu-accordion-wrap2 .fugu-accordion-item h4 {
		font-size: 20px;
	}
}

a.fugu-faq-btn {
	font-size: 16px;
	position: absolute;
	bottom: 0;
	font-weight: 700;
	color: var(--gray-800);
	transition: all 0.4s;
	text-decoration: underline;
}
a.fugu-faq-btn:hover {
	color: var(--warning-500);
}
@media (max-width: 991px) {
	a.fugu-faq-btn {
		position: inherit;
	}
}

@media (max-width: 991px) {
	.nexto-accordion-one.two {
		margin-top: 30px;
	}
}
.nexto-accordion-one .accordion-item {
	border: none;
	background-color: transparent;
	border: 1px solid rgba(19, 17, 26, 0.5);
	border-bottom: none;
}
.nexto-accordion-one .accordion-item:first-child {
	border-radius: 10px 10px 0px 0px;
}
.nexto-accordion-one .accordion-item:last-child {
	border-radius: 0px 0px 10px 10px;
	border-bottom: 1px solid rgba(19, 17, 26, 0.5);
}
.nexto-accordion-one .accordion-button {
	padding: 30px;
	font-weight: 600;
	font-size: 20px;
	line-height: 1;
	font-family: "Inter", sans-serif;
	color: var(--gray-800);
	background-color: transparent;
}
@media (max-width: 767px) {
	.nexto-accordion-one .accordion-button {
		line-height: 28px;
		font-size: 18px;
		padding: 20px;
	}
}
.nexto-accordion-one .accordion-button:not(.collapsed) {
	box-shadow: none;
}
.nexto-accordion-one .accordion-button:after {
	background-repeat: no-repeat;
	background-size: 16px;
	background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillrule='evenodd' clipRule='evenodd' d='M9.0001 0.600098C9.66284 0.600098 10.2001 1.13736 10.2001 1.8001V7.8001H16.2001C16.8628 7.8001 17.4001 8.33736 17.4001 9.0001C17.4001 9.66284 16.8628 10.2001 16.2001 10.2001H10.2001V16.2001C10.2001 16.8628 9.66284 17.4001 9.0001 17.4001C8.33736 17.4001 7.8001 16.8628 7.8001 16.2001V10.2001H1.8001C1.13736 10.2001 0.600098 9.66284 0.600098 9.0001C0.600098 8.33735 1.13736 7.8001 1.8001 7.8001L7.8001 7.8001V1.8001C7.8001 1.13736 8.33736 0.600098 9.0001 0.600098Z' fill='%23111827'/%3E%3C/svg%3E%0A");
}
.nexto-accordion-one .accordion-button:not(.collapsed)::after {
	transform: rotate(-45deg);
}
.nexto-accordion-one .accordion-body {
	padding: 0 50px 25px 30px;
}
@media (max-width: 767px) {
	.nexto-accordion-one .accordion-body {
		padding: 0 20px 20px 20px;
	}
}

@media (max-width: 991px) {
	.fugu-tab-menu {
		margin-bottom: 40px;
	}
}
@media (max-width: 767px) {
	.fugu-tab-menu {
		margin-bottom: 20px;
	}
}
.fugu-tab-menu.nav {
	justify-content: center;
	max-width: 750px;
	margin: 0 auto;
	margin-bottom: 80px;
}
@media (max-width: 767px) {
	.fugu-tab-menu.nav {
		justify-content: space-between;
	}
}
.fugu-tab-menu.nav-tabs {
	border-bottom: 2px solid rgba(19, 17, 26, 0.1);
}
@media (max-width: 767px) {
	.fugu-tab-menu.nav-tabs {
		border: none;
	}
}
.fugu-tab-menu.nav-tabs .nav-item {
	margin-right: 45px;
}

/* Custom style for the dropdown */
/* Increase specificity to override default styles */
.fugu-tab-menu.nav-tabs .nav-item .nav-link {
	border: none;
	font-size: 16px;
	font-weight: 700;
	padding: 0;
	padding-bottom: 10px;

	color: rgba(19, 17, 26, 0.8);
	position: relative;
}
@media (max-width: 767px) {
	.fugu-tab-menu.nav-tabs .nav-link {
		margin: 10px;
	}
}
.fugu-tab-menu.nav-tabs .nav-link:before {
	content: "";
	left: 0;
	opacity: 0;
	bottom: -1px;
	width: 0;
	height: 2px;
	position: absolute;
	background-color: var(--warning-500);
}
.fugu-tab-menu.nav-tabs .nav-link:last-child {
	margin-right: 0;
}
.fugu-tab-menu.nav-tabs .nav-link.active {
	background-color: transparent;
	color: var(--warning-500);
}
.fugu-tab-menu.nav-tabs .nav-link.active:before {
	width: 100%;
	opacity: 1;
}

.fugu--faq-section {
	background-color: var(--warning-600);
}
.fugu--faq-section .fugu--default-content {
	margin-right: 20px;
}
@media (max-width: 991px) {
	.fugu--faq-section .fugu--default-content {
		max-width: 500px;
	}
}

.fugu--faq-section2 {
	z-index: 0;
	position: relative;
	background-color: var(--gray-800);
}

.fugu--accordion-one .accordion-item {
	border: none;
	background-color: transparent;
	border-bottom: 2px solid var(--gray-800);
}
.fugu--accordion-one .accordion-button {
	padding: 30px 0;
	font-weight: 600;
	font-size: 24px;
	line-height: 1;
	font-family: "Inter", sans-serif;
	color: var(--gray-800);
	background-color: transparent;
}
@media (max-width: 767px) {
	.fugu--accordion-one .accordion-button {
		font-size: 20px;
		line-height: 30px;
	}
}
.fugu--accordion-one .accordion-button:not(.collapsed) {
	box-shadow: none;
}
.fugu--accordion-one .accordion-button:after {
	background-repeat: no-repeat;
	background-size: 16px;
	background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillrule='evenodd' clipRule='evenodd' d='M9.0001 0.600098C9.66284 0.600098 10.2001 1.13736 10.2001 1.8001V7.8001H16.2001C16.8628 7.8001 17.4001 8.33736 17.4001 9.0001C17.4001 9.66284 16.8628 10.2001 16.2001 10.2001H10.2001V16.2001C10.2001 16.8628 9.66284 17.4001 9.0001 17.4001C8.33736 17.4001 7.8001 16.8628 7.8001 16.2001V10.2001H1.8001C1.13736 10.2001 0.600098 9.66284 0.600098 9.0001C0.600098 8.33735 1.13736 7.8001 1.8001 7.8001L7.8001 7.8001V1.8001C7.8001 1.13736 8.33736 0.600098 9.0001 0.600098Z' fill='%23111827'/%3E%3C/svg%3E%0A");
}
.fugu--accordion-one .accordion-button:not(.collapsed)::after {
	transform: rotate(-45deg);
}
.fugu--accordion-one .accordion-body {
	font-size: 18px;
	line-height: 30px;
	color: rgba(19, 17, 26, 0.8);
	padding: 0 220px 25px 0;
}
@media (max-width: 1199px) {
	.fugu--accordion-one .accordion-body {
		padding: 0 0 25px;
	}
}
.fugu--accordion-one.accordion-two .accordion-item {
	background-color: transparent;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.fugu--accordion-one.accordion-two .accordion-button {
	color: white;
	font-size: 20px;
	padding: 20px 50px;
}
.fugu--accordion-one.accordion-two .accordion-button:after {
	left: 0;
	top: 27px;
	background-size: 20px;
	position: absolute;
	background-image: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2L10 10L18 2' stroke='white' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.fugu--accordion-one.accordion-two .accordion-button:not(.collapsed)::after {
	top: 17px;
	transform: rotate(-180deg);
}
.fugu--accordion-one.accordion-two .accordion-body {
	padding: 0 130px 22px 52px;
	color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 767px) {
	.fugu--accordion-one.accordion-two .accordion-body {
		padding: 0 20px 20px 52px;
	}
}
.fugu--accordion-one.accordion-three .accordion-item {
	border: 0.5px solid #ffffff;
	border-bottom: none;
}
.fugu--accordion-one.accordion-three .accordion-item:last-child {
	border-bottom: 0.5px solid #ffffff;
}
.fugu--accordion-one.accordion-three .accordion-button {
	font-size: 20px;
	line-height: 28px;
	padding: 30px;
	color: var(--gray-10);
}
.fugu--accordion-one.accordion-three .accordion-button:after {
	background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.0625 6.5625H8.4375V0.9375C8.4375 0.68886 8.33873 0.450403 8.16291 0.274588C7.9871 0.0987722 7.74864 0 7.5 0C7.25136 0 7.0129 0.0987722 6.83709 0.274588C6.66127 0.450403 6.5625 0.68886 6.5625 0.9375V6.5625H0.9375C0.68886 6.5625 0.450403 6.66127 0.274588 6.83709C0.0987722 7.0129 0 7.25136 0 7.5C0 7.74864 0.0987722 7.9871 0.274588 8.16291C0.450403 8.33873 0.68886 8.4375 0.9375 8.4375H6.5625V14.0625C6.5625 14.3111 6.66127 14.5496 6.83709 14.7254C7.0129 14.9012 7.25136 15 7.5 15C7.74864 15 7.9871 14.9012 8.16291 14.7254C8.33873 14.5496 8.4375 14.3111 8.4375 14.0625V8.4375H14.0625C14.3111 8.4375 14.5496 8.33873 14.7254 8.16291C14.9012 7.9871 15 7.74864 15 7.5C15 7.25136 14.9012 7.0129 14.7254 6.83709C14.5496 6.66127 14.3111 6.5625 14.0625 6.5625Z' fill='white'/%3E%3C/svg%3E%0A");
}
.fugu--accordion-one.accordion-three .accordion-body {
	color: var(--gray-10);
	opacity: 0.8;
	padding: 30px;
	padding-top: 0;
}
.fugu--accordion-one.accordion-four .accordion-item {
	border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}
.fugu--accordion-one.accordion-four .accordion-button {
	color: var(--gray-10);
}
.fugu--accordion-one.accordion-four .accordion-button:after {
	background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.0625 6.5625H8.4375V0.9375C8.4375 0.68886 8.33873 0.450403 8.16291 0.274588C7.9871 0.0987722 7.74864 0 7.5 0C7.25136 0 7.0129 0.0987722 6.83709 0.274588C6.66127 0.450403 6.5625 0.68886 6.5625 0.9375V6.5625H0.9375C0.68886 6.5625 0.450403 6.66127 0.274588 6.83709C0.0987722 7.0129 0 7.25136 0 7.5C0 7.74864 0.0987722 7.9871 0.274588 8.16291C0.450403 8.33873 0.68886 8.4375 0.9375 8.4375H6.5625V14.0625C6.5625 14.3111 6.66127 14.5496 6.83709 14.7254C7.0129 14.9012 7.25136 15 7.5 15C7.74864 15 7.9871 14.9012 8.16291 14.7254C8.33873 14.5496 8.4375 14.3111 8.4375 14.0625V8.4375H14.0625C14.3111 8.4375 14.5496 8.33873 14.7254 8.16291C14.9012 7.9871 15 7.74864 15 7.5C15 7.25136 14.9012 7.0129 14.7254 6.83709C14.5496 6.66127 14.3111 6.5625 14.0625 6.5625Z' fill='white'/%3E%3C/svg%3E%0A");
}
.fugu--accordion-one.accordion-four .accordion-body {
	color: var(--gray-10);
	opacity: 0.8;
	padding: 0 90px 30px 0;
}
@media (max-width: 991px) {
	.fugu--accordion-one.accordion-four .accordion-body {
		padding: 0 0px 30px 0;
	}
}

.fugu-video-thumb {
	position: relative;
}
.fugu-video-thumb img {
	width: 100%;
	height: 100%;
}
@media (max-width: 991px) {
	.fugu-video-thumb {
		margin-bottom: 30px;
	}
}

.fugu-popup,
.fugu-play-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.fugu-popup img {
	-webkit-animation: rotate-animation 10s infinite linear;
	-moz-animation: rotate-animation 10s infinite linear;
	-o-animation: rotate-animation 10s infinite linear;
	animation: rotate-animation 10s infinite linear;
}
.fugu-popup img:hover {
	-webkit-animation-play-state: paused;
	-moz-animation-play-state: paused;
	-o-animation-play-state: paused;
	animation-play-state: paused;
}
@media (max-width: 991px) {
	.fugu-popup img {
		width: 90px;
	}
}
@media (max-width: 767px) {
	.fugu-popup img {
		width: 75px;
	}
}

.fugu-play-btn img {
	animation: none !important;
	width: auto !important;
}

@keyframes rotate-animation {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.nexto-video-wrap .nexto-popup img {
	-webkit-animation: rotate-animation 10s infinite linear;
	-moz-animation: rotate-animation 10s infinite linear;
	-o-animation: rotate-animation 10s infinite linear;
	animation: rotate-animation 10s infinite linear;
}

.fugu-video-thumb2 {
	border-radius: 10px;
	overflow: hidden;
	margin: 0;
}
.fugu-video-thumb2:before {
	content: "";
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	background: linear-gradient(180deg, #724fe5 0%, #32e7b1 34.38%, #ffffff 100%);
	mix-blend-mode: multiply;
	border-radius: 10px;
	transform: matrix(1, 0, 0, -1, 0, 0);
}

.fugu-video-thumb3 {
	margin: 0;
}

.fugu--video-section {
	background-position: center center;
	background-repeat: no-repeat;
	background-color: var(--gray-800);
}
.fugu--video-section .fugu--btn-wrap {
	margin: 45px 0 0;
}
.fugu--video-section .fugu--default-content {
	padding-left: 30px;
	position: relative;
	z-index: 1;
}
@media (max-width: 1400px) {
	.fugu--video-section .fugu--default-content {
		padding: 0;
	}
}

.fugu--video-thumb {
	z-index: 0;
	position: relative;
	margin-right: 50px;
	border-radius: 3px;
}
@media (max-width: 1199px) {
	.fugu--video-thumb {
		margin: 0 0 30px;
	}
}
.fugu--video-thumb img {
	border-radius: 3px;
	width: 100%;
}
.fugu--video-thumb .fugu--popup {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
}
@media (max-width: 767px) {
	.fugu--video-thumb .fugu--popup {
		width: 60px;
		height: 60px;
	}
}

.waves {
	position: absolute;
	width: 150px;
	height: 150px;
	background: rgba(255, 255, 255, 0.7);
	opacity: 0;
	border-radius: 100%;
	left: -35px;
	top: -35px;
	z-index: 0;
	-webkit-animation: waves 3s ease-in-out infinite;
	animation: waves 3s ease-in-out infinite;
	z-index: -1;
}
@media (max-width: 767px) {
	.waves {
		width: 130px;
		height: 130px;
	}
}
.waves.wave-1 {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
.waves.wave-2 {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}
.waves.wave-3 {
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

@keyframes waves {
	0% {
		-webkit-transform: scale(0.2, 0.2);
		transform: scale(0.2, 0.2);
		opacity: 0;
	}
	50% {
		opacity: 0.9;
	}
	100% {
		-webkit-transform: scale(0.9, 0.9);
		transform: scale(0.9, 0.9);
		opacity: 0;
	}
}
.fugu--video-wrap {
	position: relative;
}
.fugu--video-wrap .fugu--popup {
	position: absolute;
	top: 48%;
	left: 58%;
	transform: translate(-50%, -50%);
}
.fugu--video-wrap .fugu--popup img {
	-webkit-animation: rotate-animation 10s infinite linear;
	-moz-animation: rotate-animation 10s infinite linear;
	-o-animation: rotate-animation 10s infinite linear;
	animation: rotate-animation 10s infinite linear;
}
.fugu--video-wrap .fugu--popup img:hover {
	-webkit-animation-play-state: paused;
	-moz-animation-play-state: paused;
	-o-animation-play-state: paused;
	animation-play-state: paused;
}
@media (max-width: 1199px) {
	.fugu--video-wrap .fugu--popup {
		width: 120px;
		height: 120px;
	}
}
@media (max-width: 991px) {
	.fugu--video-wrap .fugu--popup {
		width: 100px;
		height: 100px;
	}
}
@media (max-width: 767px) {
	.fugu--video-wrap .fugu--popup {
		width: 75px;
		height: 75px;
	}
}
@media (max-width: 479px) {
	.fugu--video-wrap .fugu--popup {
		width: 55px;
		height: 55px;
	}
}
.fugu--video-column {
	display: flex;
}
.fugu--video-column .fugu--video-thumb {
	margin-right: 30px;
	position: relative;
}
@media (max-width: 1199px) {
	.fugu--video-column .fugu--video-thumb {
		margin: 0;
		margin-right: 20px;
	}
}
.fugu--video-column .fugu--video-thumb:last-child {
	margin-right: 0;
	padding-top: 115px;
}
@media (max-width: 991px) {
	.fugu--video-column .fugu--video-thumb:last-child {
		padding-top: 80px;
	}
}
@media (max-width: 767px) {
	.fugu--video-column .fugu--video-thumb:last-child {
		padding-top: 0;
	}
}

.fugu--play-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.fugu--play-btn img {
	animation: none !important;
}
@media (max-width: 767px) {
	.fugu--play-btn img {
		width: 15px;
	}
}

@keyframes rotate-animation {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.fugu-testimonial-section {
	background-color: var(--warning-400);
}
.fugu-testimonial-section .fugu-section-title {
	max-width: 720px;
}

.fugu-testimonial-card {
	padding: 30px;
	border-radius: 3px;
	background-color: var(--warning-300);
}
.fugu-testimonial-card h4 {
	font-size: 20px;
	line-height: 28px;
	font-weight: 700;
	margin-bottom: 15px;
	font-family: "Inter", sans-serif;
}
.fugu-testimonial-card p {
	font-weight: 500;
	color: var(--gray-800);
}
.fugu-testimonial-author-wrap {
	display: flex;
	align-items: center;
	margin-bottom: 35px;
}
.fugu-testimonial-author-thumb {
	width: 70px;
	height: 70px;
	flex-shrink: 0;
	overflow: hidden;
	margin-right: 15px;
	border-radius: 100%;
}
.fugu-testimonial-author-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
}
.fugu-testimonial-author-data h5 {
	font-size: 18px;
	margin-bottom: 0;
	line-height: 1;
	font-family: "Inter", sans-serif;
}
.fugu-testimonial-author-data span {
	font-size: 14px;
	opacity: 0.7;
	color: var(--gray-800);
}

.fugu-testimonial-slider .fugu-testimonial-card {
	margin: 0 12px;
	padding: 29px;
}
@media (max-width: 767px) {
	.fugu-testimonial-slider .fugu-testimonial-card {
		margin: 0;
	}
}

.fugu-testimonial-card2 {
	padding: 40px;
	border-radius: 10px;
	text-align: center;
	background-color: var(--warning-100);
}
.fugu-testimonial-card2 ul {
	margin-bottom: 20px;
}
.fugu-testimonial-card2 ul li {
	display: inline-block;
}
.fugu-testimonial-card2 p {
	font-weight: 600;
	color: var(--gray-800);
}

.fugu-testimonial-slider2 {
	max-width: 1150px;
	margin: 0 auto;
}
.fugu-testimonial-slider2 .fugu-testimonial-card2 {
	/* margin: 0 32px; */
}
@media (max-width: 1399px) {
	.fugu-testimonial-slider2 .fugu-testimonial-card2 {
		margin: 0 20px;
	}
}
@media (max-width: 991px) {
	.fugu-testimonial-slider2 .fugu-testimonial-card2 {
		margin: 0;
	}
}

.fugu-testimonial-btn {
	margin-top: 80px;
	text-align: center;
}
@media (max-width: 1199px) {
	.fugu-testimonial-btn {
		margin-top: 50px;
	}
}
.fugu-testimonial-btn .fugu-btn.fugu-header-btn {
	margin-left: 0;
}

.fugu-testimonial-card3 {
	padding: 40px;
	margin-bottom: 24px;
	border-radius: 10px;
	border: 1px solid rgba(2, 2, 30, 0.1);
}
@media (max-width: 1399px) {
	.fugu-testimonial-card3 {
		padding: 29px;
	}
}
.fugu-testimonial-card3 ul {
	margin-bottom: 30px;
}
.fugu-testimonial-card3 ul li {
	display: inline-block;
}
.fugu-testimonial-card3 p {
	color: var(--gray-800);
}
.fugu-testimonial-card3 .fugu-testimonial-author-wrap {
	margin: 0;
}
.fugu-testimonial-author-data3 h5 {
	line-height: 1;
	margin: 0 0 7px;
}

.fugu--testimonial-wrap {
	padding: 40px;
	border-radius: 3px;
	margin-bottom: 24px;
	background-color: var(--gray-900);
}
@media (max-width: 991px) {
	.fugu--testimonial-wrap {
		padding: 30px;
	}
}
.fugu--testimonial-rating {
	margin-bottom: 27px;
}
.fugu--testimonial-rating ul li {
	display: inline-block;
}
.fugu--testimonial-author {
	margin-top: 25px;
	display: flex;
	align-items: center;
}
.fugu--testimonial-author-thumb {
	width: 70px;
	height: 70px;
	border-radius: 100%;
	overflow: hidden;
	flex-shrink: 0;
	margin-right: 20px;
}
.fugu--testimonial-author-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
}
.fugu--testimonial-author-data span {
	display: inline-block;
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	margin-bottom: 5px;
	color: white;
}
.fugu--testimonial-author-data p {
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	opacity: 0.7;
	margin: 0;
}
.fugu--testimonial-data p {
	color: #ffffff;
}

.fugu--testimonial-button {
	margin-top: 56px;
	text-align: center;
}
@media (max-width: 991px) {
	.fugu--testimonial-button {
		margin-top: 36px;
	}
}

.fugu-cta-section {
	padding: 95px 0;
	background-color: var(--warning-400);
}
@media (max-width: 767px) {
	.fugu-cta-section {
		padding: 80px 0;
	}
}
@media (max-width: 991px) {
	.fugu-cta-section .fugu-default-content.large-content {
		text-align: center;
		max-width: 600px;
		margin: 0 auto 30px;
	}
}

.fugu-cta-app-btn-wrap {
	width: 100%;
	text-align: right;
}
@media (max-width: 991px) {
	.fugu-cta-app-btn-wrap {
		text-align: center;
	}
}

.fugu--cta-section {
	z-index: 0;
	background-size: cover;
	position: relative;
	background-color: var(--gray-800);
}

.fugu--cta-wrap {
	border-radius: 10px;
	padding: 80px 110px;
	background-color: var(--primary-500);
}
@media (max-width: 1400px) {
	.fugu--cta-wrap {
		padding: 80px;
	}
}
@media (max-width: 991px) {
	.fugu--cta-wrap {
		text-align: center;
	}
}
@media (max-width: 767px) {
	.fugu--cta-wrap {
		padding: 50px;
	}
}
@media (max-width: 575px) {
	.fugu--cta-wrap {
		padding: 35px;
	}
}

.fugu-social-icon {
	margin-top: 30px;
}
.fugu-social-icon ul {
	display: flex;
}
.fugu-social-icon ul li {
	margin: 0 7.5px;
	display: inline-block;
}
.fugu-social-icon ul li:first-child {
	margin-left: 0;
}
.fugu-social-icon ul li:last-child {
	margin-right: 0;
}
.fugu-social-icon ul li a {
	width: 30px;
	height: 30px;
	display: flex;
	z-index: 0;
	color: white;
	font-size: 12px;
	border-radius: 100%;
	align-items: center;
	position: relative;
	overflow: hidden;
	transition: all 0.4s;
	justify-content: center;
	background-color: var(--gray-900);
}
.fugu-social-icon ul li a:hover {
	color: var(--gray-800);
	background-color: var(--warning-500);
}
.fugu-social-icon ul li a path {
	fill: var(--gray-10);
	transition: all 0.4s;
}
.fugu-social-icon ul li a:hover path {
	fill: var(--gray-800);
}

.fugu-social-icon2 ul li {
	margin: 0 2px;
	display: inline-block;
}
.fugu-social-icon2 ul li:first-child {
	margin-left: 0;
}
.fugu-social-icon2 ul li:last-child {
	margin-right: 0;
}
.fugu-social-icon2 ul li a path {
	fill: var(--gray-800);
	transition: all 0.4s;
}
.fugu-social-icon2 ul li a:hover path {
	fill: var(--warning-500);
}

.fugu-social-icon3 {
	position: absolute;
	right: 30px;
	top: 30px;
}
@media (max-width: 767px) {
	.fugu-social-icon3 {
		top: 50px;
	}
}
.fugu-social-icon3 ul li {
	display: inline-block;
	margin-right: 10px;
}
.fugu-social-icon3 ul li:last-child {
	margin-right: 0;
}
.fugu-social-icon3 ul li a path {
	fill: var(--gray-800);
}

.fugu--social-icon {
	margin-top: 30px;
}
.fugu--social-icon ul li {
	margin: 0 7px;
	display: inline-block;
}
.fugu--social-icon ul li:first-child {
	margin-left: 0;
}
.fugu--social-icon ul li:last-child {
	margin-right: 0;
}
.fugu--social-icon ul li a {
	width: 30px;
	height: 30px;
	display: flex;
	z-index: 0;
	border-radius: 100%;
	align-items: center;
	position: relative;
	overflow: hidden;
	justify-content: center;
	background-color: var(--gray-900);
}
.fugu--social-icon ul li a:before {
	content: " ";
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: 0;
	transition: all 0.4s;
	position: absolute;
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%) !important;
}
.fugu--social-icon ul li a:hover::before {
	opacity: 1;
}

.fugu--social-icon2 {
	margin: 0;
}
@media (max-width: 991px) {
	.fugu--social-icon2 {
		text-align: center;
		margin-top: 15px;
	}
}
.fugu--social-icon2 ul li a:hover {
	background: var(--danger-500);
}
.fugu--social-icon2 ul li a:hover:before {
	content: none;
}

.fugu--social-icon3 ul li a:hover {
	background: var(--primary-500);
}
.fugu--social-icon3 ul li a:hover:before {
	content: none;
}

.fugu--social-icon4 ul {
	display: flex;
	justify-content: center;
}
.fugu--social-icon4 ul li a {
	width: 49px;
	height: 49px;
	background-color: transparent;
}
.fugu--social-icon4 ul li a:hover {
	background: var(--danger-500);
}
.fugu--social-icon4 ul li a svg {
	width: 22px;
	height: 22px;
}
.fugu--social-icon4 ul li a svg path {
	transition: all 0.4s;
	fill: var(--gray-800);
}
.fugu--social-icon4 ul li a:hover:before {
	content: none;
}

.fugu--social-icon5 ul li a {
	background-color: #ececed;
}
.fugu--social-icon5 ul li a path {
	fill: #171719;
	transition: all 0.4s;
}
.fugu--social-icon5 ul li a:hover path {
	fill: white;
}

.fugu-client-section {
	padding: 75px 0 80px;
}
@media (max-width: 767px) {
	.fugu-client-section {
		padding: 60px 0;
	}
}

.fugu-client-title {
	margin-bottom: 50px;
	text-align: center;
}
.fugu-client-title.text-white h5 {
	color: var(--gray-10);
	
}
.fugu-client-title h5 {
	font-size: 20px;
	line-height: 32px;
	margin: 0;
	font-weight: 600;
	letter-spacing: -0.5px;
	font-family: "Inter", sans-serif;
}

.fugu-brand-logo {
	margin: 10px 0;
}
.fugu-brand-logo img {
	opacity: 0.7;
}

.fugu-icon-list {
	margin-top: 55px;
}
@media (max-width: 991px) {
	.fugu-icon-list {
		margin-top: 30px;
	}
}
.fugu-icon-list ul li {
	position: relative;
	font-weight: 600;
	font-size: 18px;
	padding-left: 30px;
	margin-bottom: 18px;
	color: var(--gray-800);
}
.fugu-icon-list ul li:last-child {
	margin-bottom: 0;
}
.fugu-icon-list ul li img {
	position: absolute;
	left: 0;
	top: 3px;
}

.fugu-icon-list2 {
	margin-top: 55px;
}
@media (max-width: 991px) {
	.fugu-icon-list2 {
		margin-top: 30px;
	}
}
.fugu-icon-list2 ul li {
	padding-left: 70px;
	position: relative;
	margin-bottom: 25px;
}
.fugu-icon-list2 ul li:last-child {
	margin: 0;
}
.fugu-icon-list2 ul li .fulo-list-icon {
	width: 50px;
	height: 50px;
	background: red;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	position: absolute;
	left: 0;
	background-color: var(--gray-800);
}

.fugu-icon-list3 {
	margin-top: 45px;
}
@media (max-width: 991px) {
	.fugu-icon-list3 {
		margin-top: 30px;
	}
}
.fugu-icon-list3 ul li {
	padding-left: 40px;
}
.fugu-icon-list3 ul li img {
	top: -2px;
}

/*---------------------------------------------------
PRICING SECTION
----------------------------------------------------*/
.pricing-btn {
	margin-bottom: 60px;
}
@media (max-width: 991px) {
	.pricing-btn {
		margin-bottom: 30px;
	}
}

[data-pricing-dynamic][data-value-active="yearly"] .dynamic-value:before {
	display: inline-block;
	content: attr(data-yearly);
}

[data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] {
	background: #ff5722;
}

[data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] span {
	left: calc(100% - 33px);
}

[data-pricing-dynamic][data-value-active="monthly"] .dynamic-value:before {
	display: inline-block;
	content: attr(data-monthly);
}

.dynamic-value:before {
	display: inline-block;
	content: attr(data-active);
}

.static-value:before {
	display: inline-block;
	content: attr(data-active);
}

.pricing-btn .btn-toggle {
	position: relative;
	width: 65px !important;
	height: 30px;
	border-radius: 15px;
	border: none;
	cursor: pointer;
	background-color: var(--gray-800) !important;
	background-image: url("../../images/all-img/service/circle-bg.png");
}

.pricing-btn .btn-toggle:focus {
	border: none !important;
	outline: none;
	box-shadow: none;
	background-color: var(--gray-800) !important;
	background-image: url("../../images/all-img/service/circle-bg.png");
}

.pricing-btn .btn-toggle:checked {
	border: none !important;
	background-color: var(--gray-800) !important;
	background-image: url("../../images/all-img/service/circle-bg.png");
}

.pricing-btn label {
	font-size: 16px;
	font-weight: 700;
	letter-spacing: normal;
	line-height: 24px;
	margin: 0 20px;
	color: var(--gray-800);
}
@media (max-width: 767px) {
	.pricing-btn label {
		margin: 0 15px;
	}
}

.fugu-pricing-wrap {
	padding: 40px;
	position: relative;
	overflow: hidden;
	border-radius: 10px;
}
@media (max-width: 991px) {
	.fugu-pricing-wrap {
		padding: 30px;
	}
}
.fugu-pricing-wrap.active {
	background-color: var(--gray-800);
}
.fugu-pricing-wrap.active .fugu-pricing-header h5,
.fugu-pricing-wrap.active .fugu-pricing-header p {
	color: #fff;
}
.fugu-pricing-wrap.active .fugu-pricing-header p {
	opacity: 0.8;
}
.fugu-pricing-wrap.active .fugu-price,
.fugu-pricing-wrap.active p.bottom_text,
.fugu-pricing-wrap.active .fugu-pricing-currency {
	color: #fff;
}
.fugu-pricing-wrap.active .fugu-pricing-body ul li {
	color: #fff;
	opacity: 0.8;
}
.fugu-pricing-wrap.fugu-pricing-wrap {
	margin-bottom: 24px;
}
.fugu-pricing-wrap.fugu-pricing-wrap3 {
	padding: 30px;
	background: #ffffff;
	border: 1px solid rgba(19, 17, 26, 0.1);
	border-radius: 10px;
	transition: all 0.4s;
}
.fugu-pricing-wrap.fugu-pricing-wrap3:hover {
	border-radius: 10px;
	background: #ffffff;
	border: 1px solid #fff;
	box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
}
.fugu-pricing-wrap.fugu-pricing-wrap3:hover a.fugu-pricing-btn {
	border: 2px solid var(--warning-500);
	background-color: var(--warning-500);
}
.fugu-pricing-wrap.fugu-pricing-wrap3 a.fugu-pricing-btn {
	margin: 0;
	padding: 13.5px;
}
.fugu-pricing-wrap.fugu-pricing-wrap3 .fugu-pricing-price {
	margin-bottom: 15px;
}
.fugu-pricing-wrap.fugu-pricing-wrap3 p {
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 25px;
}
.fugu-pricing-wrap.fugu-pricing-wrap3 p.bottom_text {
	margin: 0;
}
.fugu-pricing-wrap.fugu-pricing-wrap3 .fugu-pricing-body {
	border-top: 1px solid rgba(19, 17, 26, 0.1);
	padding: 25px 0 35px;
}
.fugu-pricing-wrap.fugu-pricing-wrap3 .fugu-pricing-body span {
	font-size: 16px;
	display: block;
	margin-bottom: 18px;
	font-weight: 600;
}
.fugu-pricing-wrap.fugu-pricing-wrap3 .fugu-pricing-body ul li {
	font-weight: 400;
	color: var(--gray-800);
}
.fugu-pricing-wrap.fugu-pricing-wrap3 .fugu-pricing-body ul .disable {
	opacity: 0.4;
}
.fugu-pricing-header {
	margin-bottom: 15px;
}
.fugu-pricing-header p {
	font-size: 16px;
	line-height: 24px;
	margin-right: 90px;
}
.fugu-pricing-price {
	display: flex;
	align-items: flex-end;
	margin-bottom: 35px;
}
.fugu-pricing-currency {
	line-height: 1;
	font-size: 20px;
	font-weight: 700;
	position: relative;
	bottom: 5px;
}
.fugu-pricing-body ul li {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 11px;
	position: relative;
	padding-left: 30px;
}
.fugu-pricing-body ul li img {
	left: 0;
	top: 7px;
	position: absolute;
}
.fugu-pricing-body ul li:last-child {
	margin-bottom: 0;
}

.fugu-price {
	line-height: 1;
	margin: 0;
	font-size: 60px;
	font-weight: 700;
	font-family: "Inter", sans-serif;
	color: var(--gray-800);
}

p.bottom_text {
	line-height: 1;
	font-size: 20px;
	font-weight: 700;
	position: relative;
	bottom: 5px;
	color: var(--gray-800);
}

a.fugu-pricing-btn {
	display: block;
	width: 100%;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	text-align: center;
	border-radius: 10px;
	padding: 15px;
	color: var(--gray-800);
	border: 2px solid var(--gray-800);
	transition: all 0.4s;
	margin-bottom: 40px;
}
a.fugu-pricing-btn:hover {
	border: 2px solid var(--warning-500);
	background-color: var(--warning-500);
}
a.fugu-pricing-btn.active {
	border: 2px solid var(--warning-500);
	background-color: var(--warning-500);
}

.fugu-pricing-label {
	width: 178px;
	top: 27px;
	right: -42px;
	font-weight: 600;
	text-align: center;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: rotate(44.51deg);
	position: absolute;
	background-color: var(--warning-500);
}

.fugu--circle-shape {
	z-index: 0;
	z-index: -1;
	position: absolute;
}
.fugu--circle-shape .waves {
	background: rgba(44, 4, 254, 0.15);
}
.fugu--circle-shape .waves.wave-1 {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
.fugu--circle-shape.circle-one {
	top: 15%;
	left: 50%;
	transform: translateX(-50%);
}
.fugu--circle-shape.circle-two {
	left: 45px;
	bottom: 30%;
}
.fugu--circle-shape.circle-three {
	top: -23%;
	right: 0;
}
.fugu--circle-shape.circle-four {
	bottom: 25%;
	left: 70px;
}
.fugu--circle-shape.circle-five {
	left: 10%;
	top: 34%;
}
.fugu--circle-shape.circle-six {
	left: 35%;
	top: -70px;
}
.fugu--circle-shape.circle-seven {
	right: 20%;
	bottom: 10%;
}
.fugu--circle-shape.circle-eight {
	top: 20%;
	left: 5%;
}
.fugu--circle-shape.circle-nine {
	bottom: 18%;
	right: 3%;
}

.fugu--shape1 {
	position: absolute;
	top: 60px;
	right: 0;
	z-index: -1;
}

.fugu--shape2 {
	position: absolute;
	top: 50px;
	z-index: -1;
}

.fugu--shape3 {
	top: 0;
	right: 0;
	z-index: -1;
	position: absolute;
}

.fugu--shape4 {
	position: absolute;
	z-index: -1;
	top: 50%;
	transform: translateY(-50%);
}

.fugu--hero-shpae1 {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: -1;
	width: 30%;
}
.fugu--hero-shpae1 img {
	width: 100%;
}

.fugu--hero-shpae2 {
	top: 14%;
	z-index: -1;
	left: 7%;
	width: 28%;
	position: absolute;
}
.fugu--hero-shpae2 img {
	width: 100%;
}

.fugu--shape-art {
	position: absolute;
	top: -44%;
	z-index: -1;
	right: -42%;
	width: 100%;
}
.fugu--shape-art img {
	width: 100%;
}
@media (max-width: 1199px) {
	.fugu--shape-art {
		top: -35%;
		right: -35%;
	}
}
.fugu--shape-art.fugu--shape-art2 {
	top: -35%;
	right: -5%;
}

.fugu--video-shape1,
.fugu--video-shape2 {
	width: 71.4%;
	height: 71.4%;
	z-index: -1;
	top: -95px;
	right: -80px;
	position: absolute;
}
@media (max-width: 1199px) {
	.fugu--video-shape1,
	.fugu--video-shape2 {
		right: 0;
	}
}
@media (max-width: 700px) {
	.fugu--video-shape1,
	.fugu--video-shape2 {
		display: none;
	}
}

.fugu--video-shape2 {
	width: 86.4%;
	height: 100%;
	top: auto;
	right: auto;
	bottom: -46px;
	left: -26%;
}

.fugu--content-shape {
	z-index: -1;
	position: absolute;
	bottom: -60%;
	width: 161%;
	height: 161%;
	left: -35%;
}
@media (max-width: 1199px) {
	.fugu--content-shape {
		bottom: auto;
		height: 100%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
@media (max-width: 700px) {
	.fugu--content-shape {
		display: none;
	}
}
.fugu--content-shape img {
	width: 100%;
}

.fugu--circle-shape {
	z-index: 0;
	z-index: -1;
	position: absolute;
}
.fugu--circle-shape .waves {
	background: rgba(44, 4, 254, 0.15);
}
.fugu--circle-shape .waves.wave-1 {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
.fugu--circle-shape.circle-one {
	top: 15%;
	left: 50%;
	transform: translateX(-50%);
}
.fugu--circle-shape.circle-two {
	left: 45px;
	bottom: 30%;
}
.fugu--circle-shape.circle-three {
	top: -23%;
	right: 0;
}
.fugu--circle-shape.circle-four {
	bottom: 25%;
	left: 70px;
}
.fugu--circle-shape.circle-five {
	left: 10%;
	top: 34%;
}
.fugu--circle-shape.circle-seven {
	right: 20%;
	bottom: 10%;
}
.fugu--circle-shape.circle-eight {
	top: 20%;
	left: 5%;
}
.fugu--circle-shape.circle-nine {
	bottom: 18%;
	right: 3%;
}

.fugu--shape1 {
	position: absolute;
	top: 60px;
	right: 0;
	z-index: -1;
}

.fugu--shape2 {
	position: absolute;
	top: 50px;
	z-index: -1;
}

.fugu--shape3 {
	top: 0;
	right: 0;
	z-index: -1;
	position: absolute;
}

.fugu--shape4 {
	position: absolute;
	z-index: -1;
	top: 50%;
	transform: translateY(-50%);
}

.fugu--hero-shpae1 {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: -1;
	width: 30%;
}
.fugu--hero-shpae1 img {
	width: 100%;
}

.fugu--hero-shpae2 {
	top: 14%;
	z-index: -1;
	left: 7%;
	width: 28%;
	position: absolute;
}
.fugu--hero-shpae2 img {
	width: 100%;
}

.fugu--shape-art {
	position: absolute;
	top: -44%;
	z-index: -1;
	right: -42%;
	width: 100%;
}
.fugu--shape-art img {
	width: 100%;
}
@media (max-width: 1199px) {
	.fugu--shape-art {
		top: -35%;
		right: -35%;
	}
}
.fugu--shape-art.fugu--shape-art2 {
	top: -35%;
	right: -5%;
}

.fugu--video-shape1,
.fugu--video-shape2 {
	width: 71.4%;
	height: 71.4%;
	z-index: -1;
	top: -95px;
	right: -80px;
	position: absolute;
}
@media (max-width: 1199px) {
	.fugu--video-shape1,
	.fugu--video-shape2 {
		right: 0;
	}
}
@media (max-width: 700px) {
	.fugu--video-shape1,
	.fugu--video-shape2 {
		display: none;
	}
}

.fugu--video-shape2 {
	width: 86.4%;
	height: 100%;
	top: auto;
	right: auto;
	bottom: -46px;
	left: -26%;
}

.fugu--content-shape {
	z-index: -1;
	position: absolute;
	bottom: -60%;
	width: 161%;
	height: 161%;
	left: -35%;
}
@media (max-width: 1199px) {
	.fugu--content-shape {
		bottom: auto;
		height: 100%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
@media (max-width: 700px) {
	.fugu--content-shape {
		display: none;
	}
}
.fugu--content-shape img {
	width: 100%;
}

.fugu-section-padding {
	padding: 120px 0;
}
@media (max-width: 991px) {
	.fugu-section-padding {
		padding: 100px 0;
	}
}
@media (max-width: 767px) {
	.fugu-section-padding {
		padding: 80px 0;
	}
}

.fugu-section-padding2 {
	padding: 110px 0 120px;
}
@media (max-width: 991px) {
	.fugu-section-padding2 {
		padding: 90px 0 100px;
	}
}
@media (max-width: 767px) {
	.fugu-section-padding2 {
		padding: 70px 0 80px;
	}
}

.fugu-section-padding3 {
	padding: 110px 0 96px;
}
@media (max-width: 991px) {
	.fugu-section-padding3 {
		padding: 90px 0 76px;
	}
}
@media (max-width: 767px) {
	.fugu-section-padding3 {
		padding: 70px 0 56px;
	}
}

.fugu-section-title {
	text-align: center;
	max-width: 655px;
	margin: 0 auto 75px;
}
@media (max-width: 991px) {
	.fugu-section-title {
		margin: 0 auto 45px;
		max-width: 590px;
	}
}
.fugu-section-title h2 {
	margin: 0;
}
.fugu-section-title.title-large h2 {
	font-size: 68px;
	line-height: 75px;
}
@media (max-width: 991px) {
	.fugu-section-title.title-large h2 {
		font-size: 48px;
		line-height: 60px;
	}
}
@media (max-width: 767px) {
	.fugu-section-title.title-large h2 {
		font-size: 40px;
		line-height: 50px;
	}
}
@media (max-width: 479px) {
	.fugu-section-title.title-large h2 {
		font-size: 36px;
		line-height: 46px;
	}
}

.fugu-section-title2 {
	margin: 0 auto 75px;
}
@media (max-width: 991px) {
	.fugu-section-title2 {
		margin: 0 auto 45px;
		max-width: 590px;
		text-align: center;
	}
}
.fugu-section-title2 h2 {
	margin: 0;
	margin-right: 31px;
}
@media (max-width: 991px) {
	.fugu-section-title2 h2 {
		margin: 0 auto 15px;
	}
}

.fugu-section-title.title-white h2 {
	color: #fff;
}

.fugu-default-content.large-content h2 {
	font-size: 68px;
	line-height: 75px;
}
@media (max-width: 1399px) {
	.fugu-default-content.large-content h2 {
		font-size: 62px;
		line-height: 70px;
	}
}
@media (max-width: 1199px) {
	.fugu-default-content.large-content h2 {
		font-size: 48px;
		line-height: 60px;
	}
}
@media (max-width: 767px) {
	.fugu-default-content.large-content h2 {
		font-size: 40px;
		line-height: 50px;
	}
}
@media (max-width: 479px) {
	.fugu-default-content.large-content h2 {
		font-size: 36px;
		line-height: 46px;
	}
}
.fugu-default-content.content-white h2 {
	color: #fff;
}

.fugu--section-padding {
	padding: 180px 0 140px;
}
@media (max-width: 991px) {
	.fugu--section-padding {
		padding: 170px 0 120px;
	}
}
@media (max-width: 767px) {
	.fugu--section-padding {
		padding: 200px 0 100px;
	}
}

.fugu--section-padding2 {
	padding: 120px 0 105px;
}
@media (max-width: 991px) {
	.fugu--section-padding2 {
		padding: 105px 0 95px;
	}
}
@media (max-width: 767px) {
	.fugu--section-padding2 {
		padding: 70px 0 55px;
	}
}

.fugu--section-padding-bottom {
	padding: 0 0 120px;
}
@media (max-width: 991px) {
	.fugu--section-padding-bottom {
		padding: 0 0 100px;
	}
}
@media (max-width: 767px) {
	.fugu--section-padding-bottom {
		padding: 0 0 80px;
	}
}

.fugu--section-title {
	max-width: 708px;
	text-align: center;
	margin: 0 auto 75px;
}
@media (max-width: 991px) {
	.fugu--section-title {
		margin: 0 auto 50px;
	}
}
.fugu--section-title h2 {
	font-family: "Space Grotesk", sans-serif;
}
.fugu--section-title .fugu--default-content p {
	padding: 0 20px;
}
@media (max-width: 767px) {
	.fugu--section-title .fugu--default-content p {
		padding: 0;
	}
}

.fugu--section-title-wrap {
	display: flex;
	margin-bottom: 80px;
	align-items: flex-end;
	justify-content: space-between;
}
@media (max-width: 991px) {
	.fugu--section-title-wrap {
		margin-bottom: 60px;
		display: block;
		margin: 0 auto 60px;
	}
}
.fugu--section-title-wrap .fugu--section-title {
	text-align: left;
	margin: 0;
	max-width: 510px;
}
@media (max-width: 991px) {
	.fugu--section-title-wrap .fugu--section-title {
		margin: 0 auto;
		text-align: center;
	}
}
.fugu--section-title-wrap .fugu--section-title p {
	margin: 0;
	padding: 0;
}

.fugu--default-content h2 {
	font-family: "Space Grotesk", sans-serif;
	color: white;
}
.fugu--default-content.content-sm h2 {
	font-size: 42px;
	line-height: 48px;
	color: white;
	font-family: "Space Grotesk", sans-serif;
}
@media (max-width: 991px) {
	.fugu--default-content.content-sm h2 {
		font-size: 40px;
	}
}
@media (max-width: 767px) {
	.fugu--default-content.content-sm h2 {
		font-size: 32px;
		line-height: 40px;
	}
}
.fugu--default-content p {
	color: #fff;
}
@media (max-width: 767px) {
	.fugu--default-content p {
		font-size: 16px;
		line-height: 26px;
	}
}
.fugu--default-content.content-black h2 {
	color: var(--gray-800);
	font-family: "Public Sans", sans-serif;
}
.fugu--default-content.content-black p {
	opacity: 0.8;
	color: var(--gray-800);
}

.fugu--section-title2 {
	margin-bottom: 65px;
}
@media (max-width: 991px) {
	.fugu--section-title2 {
		margin-bottom: 45px;
		text-align: center;
	}
}

/*1.3 pages css*/
.fugu-apps-thumb {
	position: relative;
	transform: rotate(-5deg);
	margin-left: 60px;
}
@media (max-width: 1200px) {
	.fugu-apps-thumb {
		margin: 0;
	}
}
@media (max-width: 991px) {
	.fugu-apps-thumb {
		margin: 0 0 50px;
		text-align: center;
		transform: none !important;
	}
}

.fugu-apps-thumb.fugu-apps-thumb2 {
	transform: rotate(-5deg);
}

.fugu-shape3 {
	position: absolute;
	top: 0;
	z-index: -1;
	left: 6%;
}
@media (max-width: 1650px) {
	.fugu-shape3 {
		left: -5%;
	}
}
@media (max-width: 1199px) {
	.fugu-shape3 {
		left: -12%;
	}
}
@media (max-width: 991px) {
	.fugu-shape3 {
		left: 5%;
	}
}
@media (max-width: 767px) {
	.fugu-shape3 {
		display: none;
	}
}

.fugu-error-content {
	padding: 190px 0 110px;
	text-align: center;
	max-width: 640px;
	margin: 0 auto;
}
@media (max-width: 991px) {
	.fugu-error-content {
		padding: 150px 0 90px;
	}
}
@media (max-width: 767px) {
	.fugu-error-content {
		padding: 130px 0 70px;
	}
}
.fugu-error-content h1 {
	font-size: 150px;
	line-height: 176px;
}
@media (max-width: 991px) {
	.fugu-error-content h1 {
		font-size: 120px;
		line-height: 140px;
	}
}
@media (max-width: 767px) {
	.fugu-error-content h1 {
		font-size: 100px;
		line-height: 120px;
	}
}
.fugu-error-content p {
	margin-bottom: 55px;
	padding: 0 30px;
}
@media (max-width: 991px) {
	.fugu-error-content p {
		margin-bottom: 35px;
	}
}
@media (max-width: 767px) {
	.fugu-error-content p {
		padding: 0;
	}
}

.coming-soon-section {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.coming-soon-column {
	text-align: center;
	margin: 0 auto;
}
.coming-soon-logo {
	margin-bottom: 90px;
}
@media (max-width: 991px) {
	.coming-soon-logo {
		margin-bottom: 50px;
	}
}
.coming-soon-content {
	max-width: 505px;
	margin: 0 auto 80px;
}
@media (max-width: 991px) {
	.coming-soon-content {
		margin: 0 auto 40px;
	}
}

.fugu-countdown-wrap {
	display: flex;
	justify-content: center;
	margin-bottom: 80px;
}
@media (max-width: 991px) {
	.fugu-countdown-wrap {
		margin-bottom: 40px;
	}
}
@media (max-width: 575px) {
	.fugu-countdown-wrap {
		flex-wrap: wrap;
	}
}
.fugu-countdown-item {
	width: 200px;
	height: 200px;
	border-radius: 100%;
	display: flex;
	padding-top: 20px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--gray-800);
}
@media (max-width: 767px) {
	.fugu-countdown-item {
		width: 150px;
		height: 150px;
	}
}
.fugu-countdown-item:nth-child(2),
.fugu-countdown-item:nth-child(3),
.fugu-countdown-item:nth-child(4) {
	margin-left: -30px;
}
@media (max-width: 767px) {
	.fugu-countdown-item:nth-child(2),
	.fugu-countdown-item:nth-child(3),
	.fugu-countdown-item:nth-child(4) {
		margin-left: -15px;
	}
}
.fugu-countdown-item .number {
	margin-bottom: 20px;
	font-weight: 700;
	font-size: 48px;
	color: var(--gray-800);
}
@media (max-width: 991px) {
	.fugu-countdown-item .number {
		font-size: 40px;
	}
}
@media (max-width: 767px) {
	.fugu-countdown-item .number {
		font-size: 36px;
	}
}
.fugu-countdown-item p {
	font-size: 16px;
}

.fugu-go-top {
	position: fixed;
	bottom: 10%;
	right: 3%;
	z-index: 99;
	cursor: pointer;
	-webkit-font-smoothing: antialiased;
}
.fugu-go-top:before {
	content: "";
	left: 50%;
	top: 50%;
	z-index: -1;
	width: 40px;
	height: 40px;
	border-radius: 3px;
	position: absolute;
	background-color: var(--warning-500);
	transform: translate(-50%, -50%);
}
.fugu-go-top img {
	transform: rotate(-90deg);
}

.fugu-btn-wrap {
	margin-top: 55px;
}
@media (max-width: 991px) {
	.fugu-btn-wrap {
		margin-top: 40px;
	}
}

.fugu--slider-section {
	background-color: var(--gray-800);
	position: relative;
	z-index: 0;
}

.fugu--slider-one .slick-slide {
	padding: 2px 12px;
	/* margin: 2px 12px; */
}

.fugu--slider-one .prev-arrow,
.fugu--slider-one .fugu--arrow {
	left: -30px;
	top: 50%;
	z-index: 9;
	position: absolute;
	transform: translateY(-50%);
	width: 60px;
	height: 60px;
	color: #1c1e20;
	font-size: 20px;
	cursor: pointer;
	border-radius: 100%;
	background-size: 10px;
	background-position: center;
	background-repeat: no-repeat;
	background-color: var(--gray-10);
	border: 5px solid #171719;
	transition: all 0.4s;
	background-image: url("../../images/svg2/arrow-left.svg");
}

.fugu--slider-one .fugu--arrow {
	left: auto;
	right: -30px;
	background-image: url("../../images/svg2/arrow-right.svg");
}

.slick-prev:before,
.slick-next:before {
	display: none;
}

.fugu--slider-one .slick-slide.slick-current.slick-active .fugu--btn:before {
	opacity: 1;
}
.fugu--slider-one .slick-slide.slick-current.slick-active .fugu--btn {
	color: var(--gray-10);
}
.fugu--slider-one .slick-slide.slick-current.slick-active .fugu--card-wrap {
	box-shadow: 0 0 0 1px rgba(255, 255, 255, 0);
}
.fugu--slider-one .slick-slide.slick-current.slick-active .fugu--card-wrap:before {
	opacity: 1;
}

.fugu--portfolio-section {
	position: relative;
	z-index: 0;
	margin-top: 60px;
}
.fugu--portfolio-section .fugu--section-title {
	margin: 0 auto 55px;
}

.fugu--portfolio-menu {
	margin-bottom: 25px;
	margin-top: 40px;
}
@media (max-width: 991px) {
	.fugu--portfolio-menu {
		margin-bottom: 35px;
	}
}
.fugu--portfolio-menu ul {
	display: flex;
	justify-content: start;
	flex-wrap: wrap;
}
@media (max-width: 1199px) {
	.fugu--portfolio-menu ul {
		justify-content: space-between;
	}
}
.fugu--portfolio-menu ul li {
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 16px;
	position: relative;
	border-radius: 10px;
	padding: 2px;
	margin: 0 10px;
	color: white;
	cursor: pointer;
	transition: all 0.4s;
	background: linear-gradient(to right, #89D3FF, #89D3FF, #0092B4);
}
.fugu--portfolio-menu ul li span:hover {
	transition: all 0.4s;
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%);
}
.fugu--portfolio-menu ul li.active {
	transition: all 0.4s;
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%);
}
.fugu--portfolio-menu ul li.active span {
	transition: all 0.4s;
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%);
}
@media (max-width: 1199px) {
	.fugu--portfolio-menu ul li {
		margin: 0 0 15px;
		flex: 0 0 32.2%;
	}
}
@media (max-width: 767px) {
	.fugu--portfolio-menu ul li {
		flex: 0 0 48.5%;
	}
}
@media (max-width: 420px) {
	.fugu--portfolio-menu ul li {
		flex: 0 0 100%;
	}
}
.fugu--portfolio-menu ul li img {
	margin-right: 10px;
}
.fugu--portfolio-menu ul li span {
	display: flex;
	align-items: center;
	height: 55px;
	padding: 10px 20.5px;
	border-radius: 5px;
	width: 100%;
	justify-content: center;
	background-color: var(--gray-800);
}

.fugu--portfolio-section {
	background-color: var(--gray-800);
}
.fugu--portfolio-section .fugu--card-wrap {
	margin-bottom: 24px;
	margin-left: 24px;
}

.fugu--portfolio-wrap {
	margin-left: -24px;
}

.fugu--portfolio-btn {
	text-align: center;
	margin-top: 56px;
}
@media (max-width: 991px) {
	.fugu--portfolio-btn {
		margin-top: 35px;
	}
}

@media (max-width: 991px) {
	.fugu--section-button {
		margin-top: 35px;
		text-align: center;
	}
}

.fugu--text-slider-section {
	padding: 22px 0;
	overflow: hidden;
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%);
}

.fugu--text-slider-data {
	display: flex !important;
	align-items: center;
	justify-content: center;
	margin: 0 10px;
	flex-direction: row-reverse;
}
@media (max-width: 600px) {
	.fugu--text-slider-data {
		text-align: center;
		display: block !important;
	}
}
.fugu--text-slider-data h3 {
	font-weight: 700;
	font-size: 28px;
	letter-spacing: 2px;
	display: inline-block;
	margin: 0 15px;
	color: #fff;
	line-height: 36px;
}
@media (max-width: 991px) {
	.fugu--text-slider-data h3 {
		font-size: 24px;
	}
}
.fugu--text-slider-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.4s;
}
@media (max-width: 600px) {
	.fugu--text-slider-icon {
		margin-bottom: 10px;
	}
}

.fugu--roadmap-section {
	padding: 115px 0 80px;
	background-color: var(--gray-800);
}
@media (max-width: 991px) {
	.fugu--roadmap-section {
		padding: 100px 0 60px;
	}
}
@media (max-width: 767px) {
	.fugu--roadmap-section {
		padding: 80px 0 40px;
	}
}
.fugu--roadmap-section .fugu--iconbox-wrap {
	margin-bottom: 40px;
}

.fugu--newslatter-section {
	z-index: 0;
	position: relative;
	background-color: var(--gray-800);
}
.fugu--header-connect-option-holder {
	border-radius: 10px;
	padding: 15px;
	text-align: center;
	position: relative;
	background: #1c1e20;
	margin-left: 8px;
}
.fugu--header-connect-option-holder::before  {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	margin: -2px;
	transition: all 0.4s;
	border-radius: inherit;
	/* background: linear-gradient(to right, #89D3FF, #89D3FF, #0092B4); */
}
.fugu-wallet-font h3{
	font-weight: 600 ;
	font-size: 18px;
	color: var(--gray-10);
	font-family: "Inter", sans-serif;
}
.fugu-add-icon {
	color: #ffffff;
	font-size: 22px;
	align-items: center;
	display: flex;
	cursor: pointer;
}
.fugu-schedule-icon {
	font-size: 22px;
	align-items: center;
	display: flex;
	cursor: pointer;
}
.fugu-regular-light-font {
	color: #fff;
	font-weight: 500;
}
.fugu-remove-icon {
	padding-left: 10px;
	cursor: pointer;
}
.fugu-address{
	display: "flex" ;
	align-items: "center" ;
	justify-content: "center";
}
.fugu--social-login-icon {
	margin-right: 10px;
}	
.fugu--social-login-icon ul li {
	margin: 0 15px;
	display: inline-block;
}
.fugu--social-login-icon ul li:first-child {
	margin-left: 0;
}
.fugu--social-login-icon ul li:last-child {
	margin-right: 0;
}
.fugu--social-login-icon ul li a {
	width: 60px;
	height: 40px;
	display: flex;
	z-index: 0;
	align-items: center;
	position: relative;
	overflow: hidden;
	justify-content: center;
	background-color: var(--gray-900);
	border-radius: 12px;
	margin-top: 5px;
}
.fugu--social-login-icon ul li a:before {
	content: " ";
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;	
	transition: all 0.4s;
	position: absolute;
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%);
}
.fugu--social-login-icon ul li a:hover::before {
	opacity: 1;
}
.fugu--newslatter-wrap {
	border-radius: 10px;
	padding: 100px;
	text-align: center;
	position: relative;
	background: #1c1e20;
}
@media (max-width: 991px) {
	.fugu--newslatter-wrap {
		padding: 70px;
	}
}
@media (max-width: 767px) {
	.fugu--newslatter-wrap {
		padding: 50px;
	}
}
@media (max-width: 400px) {
	.fugu--newslatter-wrap {
		padding: 30px;
	}
}
.fugu--newslatter-wrap .fugu--section-title {
	max-width: 585px;
	margin: 0 auto 40px;
}
.fugu--newslatter-wrap::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	margin: -2px;
	transition: all 0.4s;
	border-radius: inherit;
	background: linear-gradient(to right, #89D3FF, #89D3FF, #0092B4);
}

.fugu-text-slider-section {
	padding: 40px 0;
	background-color: var(--warning-200);
}
@media (max-width: 1280px) {
	.fugu-text-slider-section {
		padding: 0;
	}
}

.fugu-text-slider-area1 {
	padding: 21px 0;
	background-color: var(--primary-600);
	transform: rotate(-2.5deg);
}
@media (max-width: 1669px) {
	.fugu-text-slider-area1 {
		padding: 13px 0;
	}
}
@media (max-width: 1280px) {
	.fugu-text-slider-area1 {
		margin: 0;
		transform: rotate(0deg);
	}
}

.fugu-text-slider-area2 {
	padding: 21px 0;
	background-color: var(--success-500);
	transform: rotate(3deg);
	margin-top: -84px;
}
@media (max-width: 1669px) {
	.fugu-text-slider-area2 {
		padding: 13px 0;
		margin-top: -65px;
	}
}
@media (max-width: 1280px) {
	.fugu-text-slider-area2 {
		margin: 0;
		transform: rotate(0deg);
	}
}

.fugu-text-slider-data {
	display: flex !important;
	align-items: center;
	justify-content: center;
	margin: 0 10px;
	flex-direction: row-reverse;
}
@media (max-width: 767px) {
	.fugu-text-slider-data {
		text-align: center;
		display: block !important;
	}
}
.fugu-text-slider-data h3 {
	font-weight: 700;
	font-size: 28px;
	display: inline-block;
	margin: 0 15px;
	line-height: 36px;
	letter-spacing: 1px;
	color: var(--gray-800);
}
@media (max-width: 1669px) {
	.fugu-text-slider-data h3 {
		font-size: 22px;
	}
}
@media (max-width: 1500px) {
	.fugu-text-slider-data h3 {
		font-size: 18px;
	}
}
.fugu-text-slider-icon {
	flex-shrink: 0;
}
.fugu-text-slider-icon img {
	margin: 0 auto;
}
@media (max-width: 600px) {
	.fugu-text-slider-icon {
		margin-bottom: 10px;
	}
}

.fugu-trading-card-thumb {
	position: relative;
	padding-left: 140px;
	z-index: 2;
}
@media (max-width: 766px) {
	.fugu-trading-card-thumb {
		padding-left: 70px;
	}
}
@media (max-width: 991px) {
	.fugu-trading-card-thumb img {
		width: 100%;
	}
}
@media (max-width: 991px) {
	.fugu-trading-card-thumb {
		margin-bottom: 40px;
	}
}
.fugu-trading-card-thumb2 {
	top: 50%;
	left: 0;
	z-index: 1;
	width: 33.5%;
	position: absolute;
	transform: translateY(-50%);
}
.fugu-trading-card-thumb2 img {
	width: 100%;
}
.fugu-trading-card2 {
	text-align: right;
}
.fugu-trading-card2 .fugu-trading-card-thumb {
	padding-left: 0;
}
@media (max-width: 991px) {
	.fugu-trading-card2 .fugu-trading-card-thumb {
		padding-left: 160px;
		margin-bottom: 80px;
	}
}
@media (max-width: 767px) {
	.fugu-trading-card2 .fugu-trading-card-thumb {
		padding-left: 80px;
	}
}
@media (max-width: 575px) {
	.fugu-trading-card2 .fugu-trading-card-thumb {
		margin-bottom: 50px;
	}
}
.fugu-trading-card2 .fugu-trading-card-thumb2 {
	top: auto;
	left: 16%;
	transform: none;
	bottom: -8%;
	width: 39%;
}
@media (max-width: 1399px) {
	.fugu-trading-card2 .fugu-trading-card-thumb2 {
		left: 5%;
	}
}
@media (max-width: 991px) {
	.fugu-trading-card2 .fugu-trading-card-thumb2 {
		left: 0;
	}
}
.fugu-trading-card2 .fugu-shape7 {
	top: -29%;
	z-index: -1;
	right: -27%;
}
.fugu-trading-card2 .fugu-shape6 {
	top: 22%;
	left: -3%;
}

.fugu-shape6 {
	position: absolute;
	top: -10%;
	left: -23%;
	z-index: 0;
}
@media (max-width: 766px) {
	.fugu-shape6 {
		left: -40%;
	}
}
@media (max-width: 575px) {
	.fugu-shape6 {
		display: none;
	}
}

.fugu-shape7 {
	position: absolute;
	top: -35%;
	z-index: -1;
	right: -13%;
}
@media (max-width: 575px) {
	.fugu-shape7 {
		display: none;
	}
}

.fugu-trading-section2 {
	padding: 120px 0 150px;
	overflow: hidden;
	background-color: var(--warning-100);
}
@media (max-width: 1399px) {
	.fugu-trading-section2 {
		padding: 120px 0;
	}
}
@media (max-width: 991px) {
	.fugu-trading-section2 {
		padding: 100px 0;
	}
}
@media (max-width: 767px) {
	.fugu-trading-section2 {
		padding: 80px 0;
	}
}

.fugu--text-slider-section2 {
	background: var(--gray-800);
}

.fugu--about-section {
	overflow: hidden;
	padding: 120px 0;
}
@media (max-width: 991px) {
	.fugu--about-section {
		padding: 100px 0;
	}
}
@media (max-width: 767px) {
	.fugu--about-section {
		padding: 80px 0;
	}
}
@media (max-width: 991px) {
	.fugu--about-section .fugu--default-content {
		max-width: 500px;
	}
}

.fugu--swiper-slider-wrap {
	position: relative;
}
@media (max-width: 991px) {
	.fugu--swiper-slider-wrap {
		margin-bottom: 40px;
	}
}

.fugu--double-star {
	position: absolute;
	left: -90px;
	bottom: 15%;
	-webkit-animation: float 3s ease-in-out infinite;
	animation: float 3s ease-in-out infinite;
}

.fugu--artwork-section {
	z-index: 0;
	overflow: hidden;
	position: relative;
	padding: 120px 0;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) -51.59%, #eee6d0 119.2%);
}
@media (max-width: 991px) {
	.fugu--artwork-section {
		padding: 100px 0 0;
	}
}
@media (max-width: 767px) {
	.fugu--artwork-section {
		padding: 80px 0 0;
	}
}
@media (max-width: 991px) {
	.fugu--artwork-section .fugu--default-content {
		max-width: 500px;
	}
}

.fugu--artwork-right {
	text-align: right;
	position: relative;
	z-index: 0;
}
@media (max-width: 991px) {
	.fugu--artwork-right {
		text-align: center;
	}
}
.fugu--artwork-right.left {
	text-align: left;
}
@media (max-width: 991px) {
	.fugu--artwork-right.left {
		text-align: center;
	}
}
.fugu--artwork-thumb {
	display: inline-block;
	position: relative;
	transform: rotate(3deg);
}
@media (max-width: 991px) {
	.fugu--artwork-thumb {
		transform: rotate(0deg) !important;
		margin-bottom: 40px;
	}
}
.fugu--artwork-data {
	position: absolute;
	left: -105px;
	bottom: 50px;
	background-size: cover;
	background-position: center;
	padding-top: 14px;
	width: 210px;
	height: 210px;
	display: flex;
	align-items: center;
	justify-content: center;
}
@media (max-width: 575px) {
	.fugu--artwork-data {
		width: 150px;
		height: 150px;
		left: 0;
		bottom: 0;
	}
}
.fugu--artwork-data p {
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #171719;
	transform: rotate(-10deg);
}
@media (max-width: 575px) {
	.fugu--artwork-data p {
		font-size: 14px;
		line-height: 20px;
	}
}

.fugu--collection-section {
	background-color: var(--warning-600);
}

.fugu--roadmap-v2 {
	background: linear-gradient(180deg, #eee6d0 -29.02%, rgba(255, 255, 255, 0) 160.21%);
}
.fugu--roadmap-v2 .fugu--section-title {
	max-width: 485px;
	text-align: left;
	margin: 0 0 75px;
}
@media (max-width: 991px) {
	.fugu--roadmap-v2 .fugu--section-title {
		margin: 0 0 50px;
	}
}
.fugu--roadmap-v2 .fugu--section-title p {
	padding: 0;
}

.fugu--client-section {
	padding: 0 0 120px;
	background-color: var(--gray-800);
}
@media (max-width: 1199px) {
	.fugu--client-section {
		padding: 0 0 100px;
	}
}
@media (max-width: 767px) {
	.fugu--client-section {
		padding: 0 0 80px;
	}
}

.fugu--client-title {
	margin-bottom: 60px;
	text-align: center;
}
.fugu--client-title p {
	font-weight: 600;
	font-size: 24px;
	margin: 0;
	color: white;
}

.fugu--feature-section {
	background-color: var(--gray-800);
}

.fugu--content-section {
	z-index: 0;
	position: relative;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: var(--gray-800);
}

.fugu--content-top {
	padding: 0 0 120px;
}
@media (max-width: 991px) {
	.fugu--content-top {
		padding: 0 0 100px;
	}
}
@media (max-width: 767px) {
	.fugu--content-top {
		padding: 0 0 80px;
	}
}
.fugu--content-top .fugu--default-content {
	margin-right: 51px;
}
@media (max-width: 991px) {
	.fugu--content-top .fugu--default-content {
		max-width: 550px;
	}
}
@media (max-width: 575px) {
	.fugu--content-top .fugu--default-content {
		margin-right: 0;
	}
}
.fugu--content-top .fugu--content-thumb {
	z-index: 0;
	text-align: right;
	position: relative;
}
@media (max-width: 1199px) {
	.fugu--content-top .fugu--content-thumb {
		text-align: center;
		margin: 0 auto;
		margin-bottom: 50px;
	}
}

.fugu--content-bottom .fugu--content-thumb {
	z-index: 0;
	position: relative;
}
@media (max-width: 1199px) {
	.fugu--content-bottom .fugu--content-thumb {
		text-align: center;
		margin: 0 auto;
		margin-bottom: 50px;
	}
}
.fugu--content-bottom .fugu--content-thumb .fugu--bitcoin {
	position: absolute;
	bottom: 10px;
	right: -90px;
	width: 87%;
	-webkit-animation: float 3s ease-in-out infinite;
	animation: float 3s ease-in-out infinite;
}
@media (max-width: 991px) {
	.fugu--content-bottom .fugu--content-thumb .fugu--bitcoin {
		right: -40px;
	}
}
@media (max-width: 479px) {
	.fugu--content-bottom .fugu--content-thumb .fugu--bitcoin {
		right: 0;
	}
}
.fugu--content-bottom .fugu--default-content {
	padding: 0 20px;
}
@media (max-width: 1199px) {
	.fugu--content-bottom .fugu--default-content {
		padding: 0;
	}
}
@media (max-width: 991px) {
	.fugu--content-bottom .fugu--default-content {
		max-width: 550px;
	}
}

.fugu--meta {
	margin-top: 35px;
}
.fugu--meta ul li {
	font-weight: 600;
	font-size: 18px;
	line-height: 30px;
	position: relative;
	padding-left: 33px;
	margin-bottom: 15px;
	color: white;
}
.fugu--meta ul li:last-child {
	margin-bottom: 0;
}
.fugu--meta ul li img {
	position: absolute;
	left: 0;
	top: 5px;
}

.fugu--testimonial-section {
	background-color: var(--gray-800);
}

.fugu--faq-section2.fugu--section-padding {
	background-size: cover;
}

.fugu-innovative-services-section {
	padding-top: 120px;
}
@media (max-width: 991px) {
	.fugu-innovative-services-section {
		padding-top: 100px;
	}
}
@media (max-width: 767px) {
	.fugu-innovative-services-section {
		padding-top: 80px;
	}
}
@media (max-width: 1199px) {
	.fugu-innovative-services-section .fugu-default-content h2 {
		font-size: 40px;
		line-height: 48px;
	}
}
@media (max-width: 767px) {
	.fugu-innovative-services-section .fugu-default-content h2 {
		font-size: 32px;
		line-height: 40px;
	}
}
@media (max-width: 991px) {
	.fugu-innovative-services-thumb img {
		width: 100%;
		height: 100%;
	}
}
@media (max-width: 991px) {
	.fugu-innovative-services-thumb {
		padding-bottom: 30px;
	}
}

.fugu-chart-thumb {
	background: #ffffff;
	box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
	border-radius: 10px;
	padding: 18px;
}
@media (max-width: 991px) {
	.fugu-chart-thumb {
		margin-bottom: 30px;
	}
}

.fugu-about-section {
	padding: 120px 0 140px;
}
@media (max-width: 991px) {
	.fugu-about-section {
		padding: 100px 0;
	}
}
@media (max-width: 767px) {
	.fugu-about-section {
		padding: 80px 0;
	}
}
@media (max-width: 1199px) {
	.fugu-about-section .fugu-default-content h2 {
		font-size: 40px;
		line-height: 48px;
	}
}
@media (max-width: 767px) {
	.fugu-about-section .fugu-default-content h2 {
		font-size: 32px;
		line-height: 40px;
	}
}
.fugu-about-thumb {
	position: relative;
	display: inline-block;
}
.fugu-about-thumb img {
	width: 100%;
}
@media (max-width: 1199px) {
	.fugu-about-thumb {
		margin-right: 50px;
	}
}
@media (max-width: 991px) {
	.fugu-about-thumb {
		display: block;
		margin: 0 0 50px;
		padding-right: 100px;
	}
}
@media (max-width: 479px) {
	.fugu-about-thumb {
		padding-right: 50px;
	}
}
.fugu-about-thumb2 {
	bottom: -20px;
	right: -22%;
	position: absolute;
	width: 57.87%;
	border-radius: 10px;
	transform: rotate(5deg);
	filter: drop-shadow(10px 14px 50px rgba(0, 0, 0, 0.15));
}
@media (max-width: 1199px) {
	.fugu-about-thumb2 {
		right: -10%;
		position: absolute;
		width: 50%;
	}
}
@media (max-width: 991px) {
	.fugu-about-thumb2 {
		right: 12px;
		width: 40%;
	}
}

.fugu-counter-rating {
	margin-top: 30px;
}
.fugu-counter-rating ul {
	display: flex;
	align-items: center;
}
@media (max-width: 1199px) {
	.fugu-counter-rating ul {
		justify-content: center;
	}
}
.fugu-counter-rating ul li {
	margin: 0 2px;
	line-height: 1;
	display: inline-block;
	font-size: 16px;
	color: rgba(255, 255, 255, 0.7);
}
.fugu-counter-rating ul li:first-child {
	margin-right: 20px;
}
.fugu-counter-rating ul li:last-child {
	margin-left: 10px;
}

.fugu--about-hero-section.dark-version {
	background-size: cover;
	padding: 200px 0 120px;
	background-color: var(--gray-800);
}
@media (max-width: 767px) {
	.fugu--about-hero-section.dark-version {
		padding: 60px 0;
	}
}

.fugu--about-section2 {
	z-index: 0;
	position: relative;
	background-color: var(--gray-800);
}
.fugu--about-section2 .fugu--hero-content {
	max-width: 100%;
}
.fugu--about-section2 .fugu--section-title {
	max-width: 855px;
}
.fugu--about-section2 .fugu--section-title p {
	margin-right: 0;
}

.fugu--thumb-thumb2 {
	border-radius: 12px;
	overflow: hidden;
}

.fugu--shpae5 {
	position: absolute;
	top: -20%;
	right: 0;
	z-index: -1;
}

.exeter-signal-loaded.exeter-signal {
	margin-top: 0;
}

.exeter-signal {
	margin-top: -200px;
	transition: all 0.4s;
}

.fugu--vision-section {
	padding: 5px 0;
	overflow: hidden;
	background-color: var(--gray-800);
}
@media (max-width: 991px) {
	.fugu--vision-section {
		padding: 5px 0 0;
	}
}

.swiper-cards .swiper-slide {
	overflow: inherit;
}

.fugu--video-section2 {
	background-color: var(--gray-800);
}
.fugu--video-section2 .fugu--default-content.content-sm h2 {
	padding: 0 30px;
}
@media (max-width: 540px) {
	.fugu--video-section2 .fugu--default-content.content-sm h2 {
		padding: 0;
	}
}
.fugu--video-section2 .fugu--video-thumb {
	margin: 0;
}

.fugu--faq-section.dark-version {
	background-color: var(--gray-800);
}
.fugu--faq-section.dark-version .fugu--section-title {
	margin: 0 auto 45px;
}
@media (max-width: 991px) {
	.fugu--faq-section.dark-version .fugu--section-title {
		margin: 0 auto 25px;
	}
}
@media (max-width: 991px) {
	.fugu--faq-section.dark-version .fugu--default-content {
		margin: 0 auto;
		max-width: 600px;
		text-align: center;
	}
}

.fugu--feature2-section {
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) -51.59%, #eee6d0 119.2%);
}

.fugu--feature-wrap {
	padding: 0 45px;
	padding-bottom: 90px;
	margin-bottom: 24px;
	border-left: 1px solid rgba(19, 17, 26, 0.2);
}
@media (max-width: 1400px) {
	.fugu--feature-wrap {
		padding: 0 30px;
		padding-right: 0;
		padding-bottom: 90px;
	}
}
@media (max-width: 1199px) {
	.fugu--feature-wrap {
		padding-bottom: 0;
		padding-right: 0;
	}
}
@media (max-width: 991px) {
	.fugu--feature-wrap {
		padding: 0 25px;
		padding-right: 0;
	}
}
.fugu--feature-data h2 {
	font-weight: 700;
	font-size: 60px;
	line-height: 1;
	margin-bottom: 30px;
	color: #ff6b55;
	font-family: "Space Grotesk", sans-serif;
}
.fugu--feature-data span {
	display: block;
	font-size: 30px;
	line-height: 1;
	letter-spacing: -1px;
	margin-bottom: 20px;
	color: var(--gray-800);
}
@media (max-width: 575px) {
	.fugu--feature-data span {
		font-size: 24px;
		margin-bottom: 15px;
	}
}
.fugu--feature-data p {
	color: #171719;
	opacity: 0.8;
}
.fugu--feature-data p:last-child {
	margin: 0;
}

.col-xl-4.col-md-6:nth-child(2) .fugu--feature-wrap {
	padding-top: 90px;
	padding-bottom: 0;
}
@media (max-width: 1199px) {
	.col-xl-4.col-md-6:nth-child(2) .fugu--feature-wrap {
		padding-top: 0;
	}
}

.fugu-blog-card {
	padding: 20px;
	border-radius: 10px;
	border: 1px solid var(--gray-700);
	transition: all 0.4s;
	margin-bottom: 24px;
	border-radius: 10px;
}
.fugu-blog-card:hover img {
	transform: scale(1.07) rotate(2deg);
}
.fugu-blog-card:hover {
	background-color: white;
	border: 1px solid white;
	box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
}
.fugu-blog-thumb {
	overflow: hidden;
	height: 263px;
	margin-bottom: 20px;
	border-radius: 10px;
}
.fugu-blog-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
	border-radius: 8px;
	transition: all 0.4s;
}
@media (max-width: 991px) {
	.fugu-blog-thumb {
		height: auto;
	}
}
.fugu-blog-meta {
	margin-bottom: 18px;
}
.fugu-blog-meta ul li {
	font-size: 16px;
	padding-right: 25px;
	position: relative;
	display: inline-block;
}
.fugu-blog-meta ul li:before {
	content: "";
	right: 5px;
	top: 13px;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	position: absolute;
	background-color: rgba(19, 17, 26, 0.8);
}
.fugu-blog-meta ul li:last-child:before {
	content: none;
}
.fugu-blog-meta ul li a {
	color: rgba(19, 17, 26, 0.8);
}
.fugu-blog-meta ul li a:hover {
	color: var(--warning-500);
}
.fugu-blog-content h5 {
	margin-bottom: 7px;
}
.fugu-blog-content h5 a {
	color: var(--gray-800);
}
.fugu-blog-content h5 a:hover {
	color: var(--warning-500);
}
.fugu-blog-content p {
	font-size: 16px;
	margin-bottom: 20px;
}

.fugu-blog-sidebar {
	border: 1px solid var(--gray-700);
	border-radius: 10px;
	padding: 30px;
}
@media (max-width: 991px) {
	.fugu-blog-sidebar {
		margin-top: 40px;
	}
}

.fugu-product-search {
	position: relative;
}

#fugu-search-btn {
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
}

.fugu-product-search input {
	padding: 10px 35px 10px 20px !important;
	border-radius: 4px;
}

.fugu-sidebar-item {
	margin-bottom: 35px;
}
.fugu-sidebar-item:last-child {
	margin-bottom: 0;
}
.fugu-sidebar-item-title {
	margin-bottom: 25px;
}
.fugu-sidebar-item-title h5 {
	margin: 0;
	font-size: 18px;
	line-height: 38px;
	font-weight: 600;
	display: inline-block;
	font-family: "Inter", sans-serif;
	border-bottom: 2px solid var(--gray-800);
}

.fugu-categories ul li {
	margin-bottom: 12px;
	padding-bottom: 12px;
	border-bottom: 1px solid rgba(19, 17, 26, 0.1);
}
.fugu-categories ul li:last-child {
	padding: 0;
	margin: 0;
	border: none;
}
.fugu-categories ul li a {
	display: block;
	color: var(--gray-800);
	transition: all 0.4;
}
.fugu-categories ul li a:hover {
	color: var(--warning-500);
}

.fugu-recent-post-item {
	margin-bottom: 30px;
}
.fugu-recent-post-item:last-child {
	margin-bottom: 0;
}
.fugu-recent-post-item a {
	display: block;
}
.fugu-recent-post-item a img {
	margin-bottom: 13px;
	border-radius: 3px;
	width: 100%;
	height: 100%;
}
.fugu-recent-post-item .fugu-blog-meta {
	margin-bottom: 10px;
}
.fugu-recent-post-item h6 {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	font-family: "Inter", sans-serif;
}
.fugu-recent-post-item h6 a {
	color: var(--gray-800);
}

.fugu-tags ul {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.fugu-tags ul li {
	flex: 0 0 48%;
	margin-bottom: 15px;
}
.fugu-tags ul li a {
	display: block;
	font-size: 15px;
	font-weight: 600;
	border-radius: 3px;
	text-align: center;
	padding: 13px 10px;
	line-height: 24px;
	color: var(--gray-800);
	border-radius: 3px;
	transition: all 0.4s;
	background-color: rgba(2, 2, 30, 0.03);
}
.fugu-tags ul li a:hover {
	background-color: var(--warning-500);
}

.fugu-blog-single-wrap {
	margin-right: 40px;
}
@media (max-width: 1199px) {
	.fugu-blog-single-wrap {
		margin: 0;
	}
}
.fugu-blog-single-wrap a {
	display: block;
}
.fugu-blog-single-wrap img {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	margin-bottom: 20px;
}
.fugu-blog-single-wrap .fugu-blog-meta {
	margin-bottom: 35px;
}
.fugu-blog-single-wrap ul li span {
	margin-right: 7px;
}

.fugu-user-wrap {
	margin: 40px 0;
	padding: 29px;
	display: flex;
	border-radius: 10px;
	position: relative;
	background-color: var(--warning-300);
}
@media (max-width: 767px) {
	.fugu-user-wrap {
		display: block;
	}
}
.fugu-user-thumb {
	width: 80px;
	height: 80px;
	flex-shrink: 0;
	overflow: hidden;
	margin-right: 15px;
	border-radius: 100%;
}
@media (max-width: 767px) {
	.fugu-user-thumb {
		margin: 0 0 15px;
	}
}
.fugu-user-thumb img {
	object-fit: cover;
}
.fugu-user-data ul {
	margin-bottom: 15px;
}
.fugu-user-data ul li {
	font-size: 16px;
	display: inline-block;
	font-weight: 700;
	padding-right: 20px;
	position: relative;
}
.fugu-user-data ul li:last-child {
	padding-right: 0;
}
.fugu-user-data ul li:before {
	content: "";
	right: 4px;
	top: 13px;
	width: 5px;
	height: 5px;
	border-radius: 50px;
	position: absolute;
	background-color: rgba(19, 17, 26, 0.8);
}
.fugu-user-data ul li:last-child:before {
	content: none;
}
.fugu-user-data ul li span {
	font-weight: 400;
	font-size: 14px;
}
.fugu-user-data p {
	font-size: 16px;
	line-height: 24px;
}

.fugu-blog-post-wrap {
	padding: 40px 0 35px;
	border-top: 1px solid rgba(19, 17, 26, 0.1);
	border-bottom: 1px solid rgba(19, 17, 26, 0.1);
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
}
@media (max-width: 767px) {
	.fugu-blog-post-wrap {
		display: block;
	}
}
.fugu-blog-post-column {
	flex: 0 0 37%;
}
.fugu-blog-post-column h6 {
	font-size: 18px;
	line-height: 26px;
	margin: 0;
}
.fugu-blog-post-column.post-column-right {
	text-align: right;
}
@media (max-width: 767px) {
	.fugu-blog-post-column.post-column-right {
		margin-top: 30px;
	}
}
.fugu-blog-post-column.post-column-right .fugu-blog-post-arrow {
	justify-content: flex-end;
}
.fugu-blog-post-column.post-column-right .fugu-blog-post-arrow-icon {
	margin-right: 0;
	margin-left: 15px;
}
.fugu-blog-post-arrow {
	display: flex;
	align-items: center;
	margin-bottom: 25px;
}
.fugu-blog-post-arrow:hover .fugu-blog-post-arrow-icon {
	background-color: var(--warning-500);
	border: 1px solid var(--warning-500);
}
.fugu-blog-post-arrow:hover .fugu-blog-post-arrow-data p {
	color: var(--gray-800);
}
.fugu-blog-post-arrow-icon {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	flex-shrink: 0;
	margin-right: 15px;
	transition: all 0.4s;
	border: 1px solid rgba(19, 17, 26, 0.1);
}
.fugu-blog-post-arrow-icon img {
	margin: 0;
	width: 18px;
	height: auto;
}
.fugu-blog-post-arrow-data p {
	font-weight: 600;
	color: rgba(19, 17, 26, 0.8);
}

.fugu-comment-section h5 {
	margin-bottom: 40px;
}
.fugu-comment-item {
	display: flex;
	margin-bottom: 24px;
	padding-bottom: 24px;
	position: relative;
	border-bottom: 1px solid rgba(19, 17, 26, 0.1);
}
.fugu-comment-item:last-child {
	border: none;
	margin: 0;
	padding: 0;
}
.fugu-comment-item:nth-child(2) {
	padding-left: 90px;
}
@media (max-width: 767px) {
	.fugu-comment-item:nth-child(2) {
		padding-left: 0;
	}
}
.fugu-comment-author {
	width: 80px;
	height: 80px;
	border-radius: 100%;
	overflow: hidden;
	flex-shrink: 0;
	margin-right: 20px;
}
.fugu-comment-author.author2 {
	width: 50px;
	height: 50px;
}
.fugu-comment-author img {
	object-fit: cover;
}
.fugu-comment-author-data {
	margin-right: 90px;
}
@media (max-width: 767px) {
	.fugu-comment-author-data {
		margin-right: 0;
	}
}
.fugu-comment-author-data h6 {
	font-size: 14px;
	font-weight: 600;
	font-family: "Inter", sans-serif;
	margin-bottom: 5px;
}
.fugu-comment-author-data span {
	display: block;
	font-size: 14px;
	margin-bottom: 15px;
}

a.comment-reply {
	font-size: 16px;
	font-weight: 700;
	position: absolute;
	top: 0;
	right: 0;
	color: var(--gray-800);
	transition: all 0.4s;
}
a.comment-reply:hover {
	color: var(--warning-500);
}

.fugu-input-field {
	margin-bottom: 20px;
}
.fugu-input-field-section {
	background: #ffffff;
	box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.06);
	border-radius: 5px;
	padding: 30px;
	margin-top: 40px;
}
.fugu-input-field-section h5 {
	margin-bottom: 30px;
}
.fugu-input-field-section .fugu-input-field textarea {
	height: 250px;
}
@media (max-width: 991px) {
	.fugu-input-field-section .fugu-input-field textarea {
		height: 150px;
	}
}
@media (max-width: 767px) {
	.fugu-input-field-section .fugu-input-field textarea {
		height: 100px;
	}
}
.fugu-input-field-section button#fugu-input-submit {
	margin-top: 16px;
}
@media (max-width: 767px) {
	.fugu-input-field-section button#fugu-input-submit {
		margin-top: 0;
	}
}

button#fugu-input-submit {
	width: 196px;
	height: 55px;
	left: 182px;
	top: 4119px;
	border-radius: 10px;
	font-weight: 700;
	font-size: 16px;
	transition: all 0.4s;
	background-color: var(--warning-500);
}
button#fugu-input-submit:hover {
	color: var(--gray-10);
	background-color: var(--gray-800);
}

.fugu--blog-top-wrap {
	padding-bottom: 100px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 767px) {
	.fugu--blog-top-wrap {
		padding-bottom: 80px;
	}
}
.fugu--blog-top-wrap .fugu--blog-wrap {
	position: relative;
	background: transparent;
	backdrop-filter: inherit;
}
.fugu--blog-top-wrap .fugu--blog-wrap:hover .fugu--blog-thumb img {
	transform: scale(1) rotate(0deg);
}
.fugu--blog-top-wrap .fugu--blog-thumb {
	height: 550px;
	overflow: inherit;
	position: relative;
	margin-left: 34%;
}
@media (max-width: 991px) {
	.fugu--blog-top-wrap .fugu--blog-thumb {
		margin: 0;
		height: auto;
	}
}
.fugu--blog-top-wrap .fugu--blog-content {
	position: absolute;
	z-index: 9;
	top: 50%;
	background: rgba(90, 75, 124, 0.2);
	backdrop-filter: blur(400px);
	border-radius: 10px;
	transform: translateY(-50%);
	border: 1px solid #fff;
	width: 642px;
}
@media (max-width: 991px) {
	.fugu--blog-top-wrap .fugu--blog-content {
		position: inherit;
		transform: inherit;
		width: 100%;
		border: none;
		border-radius: 0;
	}
}

.fugu--blog-shape3 {
	position: absolute;
	top: 4%;
	right: 0;
	z-index: -1;
}

.fugu--inner-section {
	z-index: 0;
	position: relative;
}

.dark-version {
	background-color: var(--gray-800);
}

.fugu--blog-sidebar-section.light-version {
	padding-bottom: 120px;
}
@media (max-width: 991px) {
	.fugu--blog-sidebar-section.light-version {
		padding-bottom: 100px;
	}
}
@media (max-width: 767px) {
	.fugu--blog-sidebar-section.light-version {
		padding-bottom: 80px;
	}
}

.fugu--blog-filtering.dark-version {
	padding-top: 120px;
}
@media (max-width: 991px) {
	.fugu--blog-filtering.dark-version {
		padding-top: 95px;
	}
}
@media (max-width: 767px) {
	.fugu--blog-filtering.dark-version {
		padding-top: 75px;
	}
}
.fugu--blog-filtering .fugu--blog-wrap {
	margin-bottom: 24px;
	margin-left: 24px;
}
.fugu--blog-filtering .fugu--portfolio-wrap {
	margin-left: -24px;
}
.fugu--blog-filtering .fugu--portfolio-menu {
	margin-bottom: 0;
}
@media (max-width: 1199px) {
	.fugu--blog-filtering .fugu--portfolio-menu {
		margin-top: 30px;
	}
	.fugu--blog-filtering .fugu--portfolio-menu ul li {
		flex: 0 0 19%;
	}
}
@media (max-width: 991px) {
	.fugu--blog-filtering .fugu--portfolio-menu ul li {
		flex: 0 0 23%;
	}
}
@media (max-width: 767px) {
	.fugu--blog-filtering .fugu--portfolio-menu ul li {
		flex: 0 0 48.5%;
	}
}
@media (max-width: 420px) {
	.fugu--blog-filtering .fugu--portfolio-menu ul li {
		flex: 0 0 100%;
	}
}
.fugu--blog-filtering .fugu--section-title-wrap {
	align-items: center;
}
@media (max-width: 1199px) {
	.fugu--blog-filtering .fugu--section-title-wrap {
		display: block;
		margin-bottom: 60px;
	}
}
@media (max-width: 767px) {
	.fugu--blog-filtering .fugu--section-title-wrap {
		display: block;
		margin-bottom: 40px;
	}
}
.fugu--blog-filtering .fugu--default-content.content-sm h2 {
	margin: 0;
}
.fugu--blog-filtering .fugu--card-wrap {
	margin-bottom: 24px;
	margin-left: 24px;
}

@media (max-width: 991px) {
	.fugu--blog-sidebar {
		margin-top: 40px;
	}
}
@media (max-width: 991px) {
	.fugu--blog-sidebar .fugu--newsletter {
		max-width: 100%;
	}
}

.fugu--blog-sidebar-item {
	background: rgba(90, 75, 124, 0.2);
	backdrop-filter: blur(400px);
	border-radius: 10px;
	border: 1px solid #fff;
	padding-block: 30px;
	margin-bottom: 24px;
}

.fugu--blog-sidebar-item h4 {
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 25px;
	font-family: "Inter", sans-serif;
	color: var(--gray-10);
}
.fugu--blog-sidebar-item:last-child {
	margin-bottom: 0;
}

.fugu--category ul li {
	margin-bottom: 15px;
}
.fugu--category ul li:last-child {
	margin-bottom: 0;
}
.fugu--category ul li a {
	font-size: 18px;
	line-height: 30px;
	opacity: 0.8;
	color: var(--gray-10);
	transition: all 0.4s;
}
.fugu--category ul li a:hover {
	color: #0092B4;
}

.fugu--blog-post-wrap {
	margin-bottom: 20px;
}
.fugu--blog-post-wrap:last-child {
	margin-bottom: 0;
}
.fugu--blog-post-thumb {
	height: 200px;
	overflow: hidden;
	border-radius: 10px;
	margin-bottom: 10px;
}
@media (max-width: 991px) {
	.fugu--blog-post-thumb {
		height: auto;
	}
}
.fugu--blog-post-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
}
.fugu--blog-post-data p {
	color: #ffffff;
	opacity: 0.4;
	margin-bottom: 10px;
}
.fugu--blog-post-data h5 {
	font-weight: 600;
	font-size: 18px;
	line-height: 30px;
	font-family: "Inter", sans-serif;
	margin: 0;
	color: #ffffff;
}

.fugu--tags ul {
	display: flex;
	flex-wrap: wrap;
}
.fugu--tags ul li a {
	font-size: 18px;
	color: #fff;
	opacity: 0.8;
	margin: 6px;
	padding: 14px 31px;
	display: block;
	border-radius: 10px;
	transition: all 0.4s;
	background-color: var(--gray-900);
}
.fugu--tags ul li div {
	font-size: 18px;
	color: #fff;
	opacity: 0.8;
	margin: 6px;
	padding: 14px 31px;
	display: block;
	border-radius: 10px;
	transition: all 0.4s;
	background-color: var(--gray-900);
}
.fugu--tags ul li div p {
	font-size: 18px;
	color: #fff;
	opacity: 0.8;
	margin: 0;
	border-radius: 10px;
	transition: all 0.4s;
}
.fugu--tags ul li  div:hover {
	background-color: #0092B4;
}
.fugu--tags ul li a:hover {
	background-color: #0092B4;
}

.fugu--blog-top-wrap {
	padding-bottom: 100px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 767px) {
	.fugu--blog-top-wrap {
		padding-bottom: 80px;
	}
}
.fugu--blog-top-wrap .fugu--blog-wrap {
	position: relative;
	background: transparent;
	backdrop-filter: inherit;
}
.fugu--blog-top-wrap .fugu--blog-wrap:hover .fugu--blog-thumb img {
	transform: scale(1) rotate(0deg);
}
.fugu--blog-top-wrap .fugu--blog-thumb {
	height: 550px;
	overflow: inherit;
	position: relative;
	margin-left: 34%;
}
@media (max-width: 991px) {
	.fugu--blog-top-wrap .fugu--blog-thumb {
		margin: 0;
		height: auto;
	}
}
.fugu--blog-top-wrap .fugu--blog-content {
	position: absolute;
	z-index: 9;
	top: 50%;
	background: rgba(90, 75, 124, 0.2);
	backdrop-filter: blur(400px);
	border-radius: 10px;
	transform: translateY(-50%);
	border: 1px solid #fff;
	width: 642px;
}
@media (max-width: 991px) {
	.fugu--blog-top-wrap .fugu--blog-content {
		position: inherit;
		transform: inherit;
		width: 100%;
		border: none;
		border-radius: 0;
	}
}

.fugu--blog-shape3 {
	position: absolute;
	top: 4%;
	right: 0;
	z-index: -1;
}

.fugu--inner-section {
	z-index: 0;
	position: relative;
}

.dark-version {
	background-color: var(--gray-800);
}

.fugu--blog-sidebar-section.light-version {
	padding-bottom: 120px;
}
@media (max-width: 991px) {
	.fugu--blog-sidebar-section.light-version {
		padding-bottom: 100px;
	}
}
@media (max-width: 767px) {
	.fugu--blog-sidebar-section.light-version {
		padding-bottom: 80px;
	}
}

.fugu--blog-filtering.dark-version {
	padding-top: 120px;
}
@media (max-width: 991px) {
	.fugu--blog-filtering.dark-version {
		padding-top: 95px;
	}
}
@media (max-width: 767px) {
	.fugu--blog-filtering.dark-version {
		padding-top: 75px;
	}
}
.fugu--blog-filtering .fugu--blog-wrap {
	margin-bottom: 24px;
	margin-left: 24px;
}
.fugu--blog-filtering .fugu--portfolio-wrap {
	margin-left: -24px;
}
.fugu--blog-filtering .fugu--portfolio-menu {
	margin-bottom: 0;
}
@media (max-width: 1199px) {
	.fugu--blog-filtering .fugu--portfolio-menu {
		margin-top: 30px;
	}
	.fugu--blog-filtering .fugu--portfolio-menu ul li {
		flex: 0 0 19%;
	}
}
@media (max-width: 991px) {
	.fugu--blog-filtering .fugu--portfolio-menu ul li {
		flex: 0 0 23%;
	}
}
@media (max-width: 767px) {
	.fugu--blog-filtering .fugu--portfolio-menu ul li {
		flex: 0 0 48.5%;
	}
}
@media (max-width: 420px) {
	.fugu--blog-filtering .fugu--portfolio-menu ul li {
		flex: 0 0 100%;
	}
}
.fugu--blog-filtering .fugu--section-title-wrap {
	align-items: center;
}
@media (max-width: 1199px) {
	.fugu--blog-filtering .fugu--section-title-wrap {
		display: block;
		margin-bottom: 60px;
	}
}
@media (max-width: 767px) {
	.fugu--blog-filtering .fugu--section-title-wrap {
		display: block;
		margin-bottom: 40px;
	}
}
.fugu--blog-filtering .fugu--default-content.content-sm h2 {
	margin: 0;
}
.fugu--blog-filtering .fugu--card-wrap {
	margin-bottom: 24px;
	margin-left: 24px;
}

@media (max-width: 991px) {
	.fugu--blog-sidebar {
		margin-top: 40px;
	}
}
@media (max-width: 991px) {
	.fugu--blog-sidebar .fugu--newsletter {
		max-width: 100%;
	}
}


.fugu--blog-sidebar-item h4 {
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 25px;
	font-family: "Inter", sans-serif;
}
.fugu--blog-sidebar-item:last-child {
	margin-bottom: 0;
}

.fugu--category ul li {
	margin-bottom: 15px;
}
.fugu--category ul li:last-child {
	margin-bottom: 0;
}
.fugu--category ul li a {
	font-size: 18px;
	line-height: 30px;
	opacity: 0.8;
	color: var(--gray-10);
	transition: all 0.4s;
}
.fugu--category ul li a:hover {
	color: #0092B4;
}

.fugu--blog-post-wrap {
	margin-bottom: 20px;
}
.fugu--blog-post-wrap:last-child {
	margin-bottom: 0;
}
.fugu--blog-post-thumb {
	height: 200px;
	overflow: hidden;
	border-radius: 10px;
	margin-bottom: 10px;
}
@media (max-width: 991px) {
	.fugu--blog-post-thumb {
		height: auto;
	}
}
.fugu--blog-post-thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
}
.fugu--blog-post-data p {
	color: #ffffff;
	opacity: 0.4;
	margin-bottom: 10px;
}
.fugu--blog-post-data h5 {
	font-weight: 600;
	font-size: 18px;
	line-height: 30px;
	font-family: "Inter", sans-serif;
	margin: 0;
}

.fugu--tags ul {
	display: flex;
	flex-wrap: wrap;
}
.fugu--tags ul li a {
	font-size: 18px;
	color: #fff;
	opacity: 0.8;
	margin: 6px;
	padding: 14px 31px;
	display: block;
	border-radius: 10px;
	transition: all 0.4s;
	background-color: var(--gray-900);
}
.fugu--tags ul li a:hover {
	background-color: #0092B4;
}

.light-version {
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) -51.59%, #eee6d0 119.2%);
}
.light-version .fugu--breadcrumbs-data h1,
.light-version .fugu--breadcrumbs-data p {
	color: var(--gray-800);
}
.light-version .fugu--breadcrumbs-data h1 {
	font-family: "Space Grotesk", sans-serif;
}
.light-version .fugu--newsletter.fugu--search input {
	border: 1px solid #d9d9d9 !important;
	border-radius: 3px;
}
.light-version .fugu--newsletter.fugu--search input:focus {
	border: 1px solid var(--danger-500) !important;
}
.light-version #fugu--submit-btn {
	border-radius: 3px;
	color: var(--gray-800);
	background: var(--danger-500);
}
.light-version .fugu--blog-top-wrap {
	border-bottom: 1px solid rgba(19, 17, 26, 0.1);
}
.light-version .fugu--blog-top-wrap .fugu--blog-content h3 {
	font-size: 42px;
	line-height: 53px;
}
@media (max-width: 991px) {
	.light-version .fugu--blog-top-wrap .fugu--blog-content h3 {
		font-size: 36px;
		line-height: 42px;
	}
}
@media (max-width: 767px) {
	.light-version .fugu--blog-top-wrap .fugu--blog-content h3 {
		font-size: 28px;
		line-height: 36px;
	}
}
.light-version .fugu--blog-content {
	backdrop-filter: inherit;
	background: #ffffff;
	border: none;
	box-shadow: 0px 8px 80px rgba(19, 17, 26, 0.06);
	border-radius: 3px;
}
.light-version .fugu--blog-content p {
	color: var(--gray-800);
	opacity: 0.8;
}
.light-version .fugu--blog-date ul li a {
	color: var(--gray-800);
}
.light-version .fugu--blog-title h3 {
	color: var(--gray-800);
	font-family: "Space Grotesk", sans-serif;
	transition: all 0.4s;
}
.light-version .fugu--blog-title h3:hover {
	color: var(--danger-500);
}
.light-version .fugu--blog-user-data span {
	color: var(--gray-800);
}
.light-version .fugu--default-content h2 {
	color: var(--gray-800);
	font-family: "Space Grotesk", sans-serif;
}
.light-version .fugu--portfolio-menu ul li {
	background: none;
}
.light-version .fugu--portfolio-menu ul li.active span {
	color: var(--gray-800);
	background-color: var(--danger-500);
	border: 1px solid var(--danger-500);
}
.light-version .fugu--portfolio-menu ul li span {
	color: var(--gray-800);
	background: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 3px;
}
.light-version .fugu--blog-wrap2 .fugu--blog-content {
	background-color: transparent !important;
}
.light-version .fugu--blog-sidebar-item {
	backdrop-filter: inherit;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 3px;
}
.light-version .fugu--blog-sidebar-item h4 {
	color: var(--gray-800);
}
.light-version .fugu--category ul li a {
	color: rgba(19, 17, 26, 0.8);
}
.light-version .fugu--category ul li a:hover {
	color: var(--danger-500);
	opacity: 1;
}
.light-version .fugu--blog-post-data p,
.light-version .fugu--blog-post-data h5 {
	color: var(--gray-800);
}
.light-version .fugu--tags ul li a {
	background: rgba(38, 36, 44, 0.1);
	border-radius: 3px;
	color: rgba(19, 17, 26, 0.8);
}
.light-version .fugu--tags ul li a:hover {
	opacity: 1;
	color: var(--gray-800);
	background-color: var(--danger-500);
}

.light-version .fugu--blog-wrap.fugu--blog-wrap2 a.fugu--readmore-btn {
	color: var(--gray-800);
}
.light-version .fugu--blog-wrap.fugu--blog-wrap2 a.fugu--readmore-btn span {
	transition: all 0.4s;
	background-color: rgba(19, 17, 26, 0.2);
}
.light-version .fugu--blog-wrap.fugu--blog-wrap2 a.fugu--readmore-btn span:before {
	content: none;
}
.light-version .fugu--blog-wrap.fugu--blog-wrap2:hover a.fugu--readmore-btn span {
	background-color: var(--danger-500);
}

.fugu--single-blog .fugu--breadcrumbs-data {
	max-width: 100%;
}
.fugu--single-blog .fugu--breadcrumbs-data p {
	margin-right: 120px;
	margin-bottom: 30px;
}
@media (max-width: 767px) {
	.fugu--single-blog .fugu--breadcrumbs-data p {
		margin-right: 40px;
	}
}
@media (max-width: 479px) {
	.fugu--single-blog .fugu--breadcrumbs-data p {
		margin: 0 0 20px;
	}
}

.fugu--blog-meta ul li {
	margin-right: 20px;
	display: inline-block;
}
.fugu--blog-meta ul li a {
	font-weight: 600;
	font-size: 18px;
	color: #ffffff;
	opacity: 0.4;
	display: flex;
}
.fugu--blog-meta ul li a img {
	margin-right: 10px;
}

.fugu--single-thumb {
	margin-bottom: 80px;
}
@media (max-width: 991px) {
	.fugu--single-thumb {
		margin-bottom: 40px;
	}
}

.fugu--blockquote {
	margin: 40px 0;
}
@media (max-width: 767px) {
	.fugu--blockquote {
		margin: 30px 0;
	}
}
.fugu--blockquote blockquote {
	background: rgba(90, 75, 124, 0.2);
	backdrop-filter: blur(400px);
	border-radius: 10px;
	border: 1px solid #fff;
	font-weight: 400;
	letter-spacing: -0.5px;
	padding: 40px;
	position: relative;
	color: white;
	padding: 40px 40px 40px 130px;
}
@media (max-width: 767px) {
	.fugu--blockquote blockquote {
		font-size: 18px;
		line-height: 28px;
	}
}
@media (max-width: 575px) {
	.fugu--blockquote blockquote {
		padding: 30px;
	}
}
.fugu--blockquote-user {
	display: flex;
	align-items: center;
	margin-top: 30px;
}
.fugu--blockquote-user-thumb {
	width: 60px;
	height: 60px;
	overflow: hidden;
	border-radius: 100%;
	margin-right: 20px;
}
.fugu--blockquote-user-thumb img {
	width: 100%;
	height: 100%;
	margin: 0;
	object-fit: cover;
	object-position: center top;
}
.fugu--blockquote-user-data h4 {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 5px;
	color: white;
	font-family: "Inter", sans-serif;
}
.fugu--blockquote-user-data p {
	opacity: 0.4;
}
.fugu--blockquote-icon {
	top: 45px;
	left: 40px;
	position: absolute;
}
@media (max-width: 575px) {
	.fugu--blockquote-icon {
		top: 0;
		left: 0;
		margin-bottom: 20px;
		position: inherit;
	}
}
.fugu--blockquote-icon img {
	margin: 0;
}

.fugu--tags.fugu--tags2 {
	margin-bottom: 74px;
}
@media (max-width: 767px) {
	.fugu--tags.fugu--tags2 {
		margin-bottom: 34px;
	}
}
.fugu--tags.fugu--tags2 h4 {
	margin-bottom: 30px;
	font-family: "Inter", sans-serif;
}

.fugu--post-navigation {
	flex: 0 0 50%;
	display: flex;
}
.fugu--post-navigation-wrap {
	padding: 40px 0;
	display: flex;
	justify-content: space-between;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	margin-bottom: 80px;
}
@media (max-width: 991px) {
	.fugu--post-navigation-wrap {
		margin-bottom: 40px;
	}
}
@media (max-width: 991px) {
	.fugu--post-navigation-wrap {
		display: block;
	}
}
.fugu--post-navigation:hover .fugu--post-navigation-icon:before {
	opacity: 1;
}
.fugu--post-navigation-icon {
	width: 60px;
	height: 55px;
	background: #26242c;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
	flex-shrink: 0;
	z-index: 0;
	position: relative;
}
.fugu--post-navigation-icon img {
	margin: 0;
}
.fugu--post-navigation-icon:before {
	content: "";
	left: 0;
	top: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	opacity: 0;
	border-radius: 10px;
	position: absolute;
	transition: all 0.4s;
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%);
}
.fugu--post-navigation-data p {
	font-size: 20px;
	letter-spacing: -0.5px;
	color: #ffffff;
	opacity: 0.8;
	margin-bottom: 15px;
}
.fugu--post-navigation-data span {
	display: block;
	font-weight: 700;
	font-size: 24px;
	line-height: 30px;
	color: #ffffff;
}
@media (max-width: 1199px) {
	.fugu--post-navigation-data span {
		font-size: 20px;
		line-height: 24px;
	}
}
.fugu--post-navigation.nav-previous {
	padding-right: 75px;
	border-right: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 991px) {
	.fugu--post-navigation.nav-previous {
		border: none;
		margin-bottom: 20px;
		padding: 0;
	}
}
.fugu--post-navigation.nav-next {
	text-align: right;
	padding-left: 20px;
	justify-content: flex-end;
}
@media (max-width: 991px) {
	.fugu--post-navigation.nav-next {
		padding: 0;
	}
}
.fugu--post-navigation.nav-next .fugu--post-navigation-icon {
	margin-left: 20px;
	margin-right: 0;
}

.fugu--comment-wrap {
	margin-bottom: 80px;
}
@media (max-width: 991px) {
	.fugu--comment-wrap {
		margin-bottom: 40px;
	}
}
.fugu--comment-wrap h3 {
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 30px;
	font-family: "Inter", sans-serif;
}
.fugu--comment-list {
	background: rgba(90, 75, 124, 0.2);
	backdrop-filter: blur(400px);
	border-radius: 10px;
	padding: 30px;
}
.fugu--comment-list li {
	padding-bottom: 24px;
	margin-bottom: 24px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.fugu--comment-list li:last-child {
	margin-bottom: 0;
	padding: 0;
	border: none;
}
.fugu--comment-list li.children {
	padding-left: 100px;
}
@media (max-width: 991px) {
	.fugu--comment-list li.children {
		padding-left: 0;
	}
}
.fugu--comment-list li.children .fugu--comment-author {
	width: 50px;
	height: 50px;
}
.fugu--comment-body {
	display: flex;
	position: relative;
}
@media (max-width: 767px) {
	.fugu--comment-body {
		display: block;
	}
}
.fugu--comment-body a.fugu--comment-reply {
	font-weight: 700;
	font-size: 18px;
	line-height: 26px;
	color: #ffffff;
	position: absolute;
	top: 0;
	right: 0;
	transition: all 0.4s;
}
.fugu--comment-body a.fugu--comment-reply:hover {
	color: #89D3FF;
}
.fugu--comment-author {
	width: 80px;
	height: 80px;
	overflow: hidden;
	border-radius: 100%;
	margin-right: 30px;
	flex-shrink: 0;
}
@media (max-width: 767px) {
	.fugu--comment-author {
		margin-bottom: 20px;
	}
}
.fugu--comment-author img {
	width: 100%;
	height: 100%;
	margin: 0;
	object-fit: cover;
	object-position: center top;
}
.fugu--comment-meta h5 {
	font-family: "Inter", sans-serif;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 10px;
	color: white;
}
.fugu--comment-meta span {
	font-weight: 400;
	font-size: 14px;
	color: #ffffff;
	opacity: 0.8;
	display: block;
	margin-bottom: 15px;
}
.fugu--comment-meta p {
	color: #ffffff;
	opacity: 0.8;
}

.fugu--comment-respond-area h3 {
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 15px;
	font-family: "Inter", sans-serif;
}
.fugu--comment-respond-area p {
	color: #ffffff;
	opacity: 0.8;
}

.fugu--comment-field {
	margin-bottom: 24px;
}

.dark-version .fugu--blog-title h3 {
	color: #ffffff;
}

.dark-version .fugu--single-blog-section h3,
.dark-version .fugu--single-blog-section p {
	color: #ffffff;
}

.fugu-contact-info {
	margin-top: 55px;
}
@media (max-width: 991px) {
	.fugu-contact-info {
		margin-top: 30px;
	}
}
.fugu-contact-info ul li {
	position: relative;
	padding-left: 65px;
	margin-bottom: 30px;
}
.fugu-contact-info ul li:last-child {
	margin-bottom: 0;
}
.fugu-contact-info ul li a {
	color: rgba(2, 2, 30, 0.8);
}
.fugu-contact-info ul li a h5 {
	font-weight: 600;
	margin-bottom: 5px;
}
.fugu-contact-info ul li a .fugu-contact-info-icon {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	position: absolute;
	left: 0;
	top: 5px;
	background-color: var(--warning-500);
}

.fugu-contact-wrap {
	background: #ffffff;
	box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.06);
	border-radius: 5px;
	padding: 40px;
}
@media (max-width: 991px) {
	.fugu-contact-wrap {
		padding: 30px;
		margin-top: 30px;
	}
}

.fugu-input-field label {
	display: block;
	margin-bottom: 10px;
	font-weight: 600;
	color: var(--gray-800);
}

#map {
	width: 100%;
	height: 550px;
}
@media (max-width: 991px) {
	#map {
		height: 450px;
	}
}
@media (max-width: 767px) {
	#map {
		height: 300px;
	}
}

.fugu-center-btn {
	text-align: center;
	margin-top: 80px;
}
@media (max-width: 991px) {
	.fugu-center-btn {
		margin-top: 40px;
	}
}

.fugu-default-content.contact-page {
	margin-right: 50px;
}
@media (max-width: 1399px) {
	.fugu-default-content.contact-page {
		margin-right: 0;
	}
}

.fugu--contact-info h4 {
	font-family: "Inter", sans-serif;
	margin-bottom: 40px;
}
.fugu--contact-info ul li {
	position: relative;
	padding-left: 65px;
	margin-bottom: 40px;
	line-height: 32px;
}
.fugu--contact-info ul li:last-child {
	margin-bottom: 0;
}
.fugu--contact-info ul li a {
	font-weight: 400;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: -0.5px;
	color: #ffffff;
}
.fugu--contact-info ul li a span {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	position: absolute;
	left: 0;
	top: -10px;
	background: linear-gradient(225deg, #0092B4 0%, #89D3FF 46.35%, #89D3FF 100%);
}
.fugu--contact-info.fugu--contact-info2 {
	margin-top: 60px;
	margin-right: 150px;
}
@media (max-width: 1300px) {
	.fugu--contact-info.fugu--contact-info2 {
		margin-right: 50px;
	}
}
@media (max-width: 991px) {
	.fugu--contact-info.fugu--contact-info2 {
		margin-top: 40px;
	}
}
@media (max-width: 767px) {
	.fugu--contact-info.fugu--contact-info2 {
		margin-right: 0;
	}
}
.fugu--contact-info.fugu--contact-info2 ul li a span {
	top: 0;
}

.fugu--contact-form {
	background: #211d2e;
	border-radius: 10px;
	padding: 40px;
}
@media (max-width: 991px) {
	.fugu--contact-form {
		padding: 30px;
		margin-top: 40px;
	}
}

.fugu--inner-section.bg-white {
	background: var(--gray-10);
}

.white-version {
	background-color: #fff;
}
.white-version .fugu--contact-info h4 {
	color: #171719;
}
.white-version .fugu--contact-info ul li a {
	color: rgba(19, 17, 26, 0.8);
}
.white-version .fugu--contact-info ul li a span {
	background: #2c04fe;
}
.white-version .fugu--contact-form {
	background-color: #f6f5f6;
}
.white-version .fugu--contact-form h3 {
	color: #171719;
}
.white-version .fugu--contact-form p {
	color: rgba(19, 17, 26, 0.8);
}
.white-version .fugu--comment-field input,
.white-version .fugu--comment-field textarea {
	color: #171719;
	background: #ffffff;
	border: 1px solid #c4c4c4 !important;
	border-radius: 10px;
}
.white-version .fugu--comment-field input::-webkit-input-placeholder,
.white-version .fugu--comment-field textarea::-webkit-input-placeholder {
	color: #171719;
	opacity: 0.5;
}
.white-version .fugu--comment-field input:-moz-placeholder,
.white-version .fugu--comment-field textarea:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #171719;
	opacity: 0.5;
}
.white-version .fugu--comment-field input::-moz-placeholder,
.white-version .fugu--comment-field textarea::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #171719;
	opacity: 0.5;
}
.white-version .fugu--comment-field input:-ms-input-placeholder,
.white-version .fugu--comment-field textarea:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #171719;
	opacity: 0.5;
}
.white-version .fugu--comment-field input::-ms-input-placeholder,
.white-version .fugu--comment-field textarea::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #171719;
	opacity: 0.5;
}
.white-version .fugu--comment-field input::placeholder,
.white-version .fugu--comment-field textarea::placeholder {
	/* Most modern browsers support this now. */
	color: #171719;
	opacity: 0.5;
}

.dark-version .fugu--contact-form h3,
.dark-version .fugu--contact-form p {
	color: white;
}
.dark-version .fugu--comment-field input,
.dark-version .fugu--comment-field textarea {
	color: #fff;
	background: #171719;
}
.dark-version .fugu--comment-field input::-webkit-input-placeholder,
.dark-version .fugu--comment-field textarea::-webkit-input-placeholder {
	color: #fff;
	opacity: 0.5;
}
.dark-version .fugu--comment-field input:-moz-placeholder,
.dark-version .fugu--comment-field textarea:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #fff;
	opacity: 0.5;
}
.dark-version .fugu--comment-field input::-moz-placeholder,
.dark-version .fugu--comment-field textarea::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #fff;
	opacity: 0.5;
}
.dark-version .fugu--comment-field input:-ms-input-placeholder,
.dark-version .fugu--comment-field textarea:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #fff;
	opacity: 0.5;
}
.dark-version .fugu--comment-field input::-ms-input-placeholder,
.dark-version .fugu--comment-field textarea::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #fff;
	opacity: 0.5;
}
.dark-version .fugu--comment-field input::placeholder,
.dark-version .fugu--comment-field textarea::placeholder {
	/* Most modern browsers support this now. */
	color: #fff;
	opacity: 0.5;
}

.fugu--contact-column {
	border-bottom: 1px solid rgba(19, 17, 26, 0.1);
	padding-bottom: 120px;
}
@media (max-width: 991px) {
	.fugu--contact-column {
		padding-bottom: 100px;
	}
}
@media (max-width: 767px) {
	.fugu--contact-column {
		padding-bottom: 80px;
	}
}

.white-version .fugu--iconbox-wrap5 {
	background: #fcfcfc;
	border: 1px solid #d9d9d9;
}
.white-version .fugu--iconbox-wrap5 .fugu--iconbox-thumb {
	background: #2c04fe;
}
.white-version .fugu--iconbox-wrap5 .fugu--iconbox-data h4 {
	color: #171719;
}
.white-version .fugu--iconbox-wrap5 .fugu--iconbox-data p {
	color: rgba(19, 17, 26, 0.8);
}
.white-version .fugu--iconbox-wrap5 .fugu--iconbox-data a {
	color: #171719;
}

.fugu--faq-section.white-version {
	background-color: #f6f5f6;
}

.white-version .fugu--accordion-one .accordion-item {
	border-bottom: 2px solid rgba(19, 17, 26, 0.2);
}

.accordion-item:last-of-type {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.fugu-title {
	display: flex;
	justify-content: center;
	color: #ffc947;
	user-select: none;
	font-size: 35px;
	font-weight: bold;
	margin-top: 30px;
	text-shadow: 3px 3px black;
}
.fugu-preloader-holder{
	width: 100%;
	height: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 109;
	background-color: transparent;
}

.fugu-preloader {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	left: 50%;
	top: 50%;
	width: 20%;
	height: 50%;
	z-index: 99;

	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.fugu-preloader.loaded {
	display: none;
	font: 100;
}

.fugu-spinner {
	display: flex;
	justify-content: center;
}
.fugu-button-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.fugu-button-spinner svg {
	width: 60px;
	height: 60px;
	width: 50%;
	max-width: 5rem;
	animation: rotate 3.6s linear infinite;
}

circle {
	fill: none;
	stroke: #9440f5;
	stroke-width: 8px;
	stroke-dasharray: 300;
	animation: outline 2s cubic-bezier(0.77, 0, 0.18, 1) infinite;
}

@keyframes outline {
	0% {
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dashoffset: 300;
	}
	100% {
		stroke-dashoffset: 600;
	}
}
@keyframes rotate {
	from {
		transform: rotate(0turn);
	}
	to {
		transform: rotate(-1turn);
	}
}
.fugu-career-card {
	padding: 29px;
	border-radius: 10px;
	margin-bottom: 24px;
	transition: all 0.4s;
	border: 1px solid rgba(19, 17, 26, 0.1);
}
.fugu-career-card:hover {
	background: #ffffff;
	border: 1px solid #fff;
	box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
}
.fugu-career-time {
	padding: 20px 0 35px;
}
.fugu-career-time ul {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.fugu-career-time ul li {
	font-size: 16px;
	margin-right: 18px;
	display: inline-block;
	color: var(--gray-800);
}
.fugu-career-time ul li:last-child {
	margin-right: 0;
}
.fugu-career-time ul li img {
	margin-right: 7px;
}

.fugu-single-career-item {
	margin-bottom: 55px;
}
.fugu-single-career-item:last-child {
	margin-bottom: 0;
}
@media (max-width: 991px) {
	.fugu-single-career-item {
		margin-bottom: 30px;
	}
}

.fugu-career-sidebar {
	top: 100px;
	padding: 40px;
	border-radius: 10px;
	position: sticky;
	background: #ffffff;
	box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
}
.fugu-career-sidebar h4 {
	margin-bottom: 25px;
}
@media (max-width: 991px) {
	.fugu-career-sidebar {
		padding: 30px;
		margin-top: 40px;
		position: static;
	}
}

.fugu-career-time2 {
	margin-bottom: 55px;
}
@media (max-width: 991px) {
	.fugu-career-time2 {
		margin-bottom: 30px;
	}
}
.fugu-career-time2 ul li {
	position: relative;
	font-size: 16px;
	padding-left: 30px;
	margin-bottom: 15px;
}
.fugu-career-time2 ul li:last-child {
	margin-bottom: 0;
}
.fugu-career-time2 ul li span {
	font-weight: 700;
	display: block;
	line-height: 1;
	color: var(--gray-800);
}
.fugu-career-time2 ul li img {
	left: 0;
	top: 2px;
	position: absolute;
}

.fugu-gallery-menu {
	text-align: center;
	max-width: 832px;
	margin: 0 auto 10px;
	border-bottom: 2px solid rgba(19, 17, 26, 0.1);
}
@media (max-width: 991px) {
	.fugu-gallery-menu {
		margin-bottom: 60px;
	}
}
@media (max-width: 767px) {
	.fugu-gallery-menu {
		margin-bottom: 30px;
		border: none;
	}
}
.fugu-gallery-menu ul li {
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
	margin-right: 50px;
	position: relative;
	cursor: pointer;
	padding-bottom: 10px;
	transition: all 0.4s;
	color: #fff;
}
@media (max-width: 991px) {
	.fugu-gallery-menu ul li {
		margin-right: 15px;
	}
}
@media (max-width: 767px) {
	.fugu-gallery-menu ul li {
		margin-bottom: 10px;
	}
}
.fugu-gallery-menu ul li.active {
	color: var(--warning-500);
}
.fugu-gallery-menu ul li.active:before {
	content: "";
	left: 0;
	opacity: 1;
	bottom: -2px;
	width: 100%;
	height: 2px;
	transition: all 0.4s;
	position: absolute;
	background-color: var(--warning-500);
}
.fugu-gallery-menu ul li:last-child {
	margin-right: 0;
}

.fugu-gallery-wrap {
	margin-left: -24px;
}
.fugu-gallery-item {
	overflow: hidden;
	position: relative;
	margin-left: 24px;
	margin-bottom: 24px;
}
.fugu-gallery-item.item2 {
	margin-bottom: 55px;
}
@media (max-width: 991px) {
	.fugu-gallery-item.item2 {
		margin-bottom: 30px;
	}
}
.fugu-gallery-item.item2:before {
	content: none;
}
.fugu-gallery-item img {
	width: 100%;
	height: 100%;
	border-radius: 10px;
}
.fugu-gallery-item:before {
	content: "";
	left: 0;
	top: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	mix-blend-mode: multiply;
	border-radius: 10px;
	transition: all 0.4s;
	background: linear-gradient(180deg, rgba(19, 17, 26, 0) 0%, #171719 100%);
}
.fugu-gallery-item:hover:before {
	opacity: 1;
}
.fugu-gallery-item:hover .fugu-gallery-data {
	opacity: 1;
	visibility: visible;
	left: 30px;
}
.fugu-gallery-data {
	position: absolute;
	left: 0;
	bottom: 30px;
	z-index: 1;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s;
}
.fugu-gallery-data h4,
.fugu-gallery-data h5 {
	margin: 0 0 8px;
}
.fugu-gallery-data h4 a,
.fugu-gallery-data h5 a {
	color: #fff;
}
.fugu-gallery-data h5 {
	margin: 0 0 5px;
}
.fugu-gallery-data p {
	color: #fff;
	opacity: 0.8;
}
.fugu-gallery-data2 {
	margin-top: 30px;
	position: relative;
}
.fugu-gallery-data2 h4 {
	margin: 0 0 5px;
}
.fugu-gallery-data2 h4 a {
	color: var(--gray-800);
}
.fugu-gallery-data2 p {
	margin: 0;
}
.fugu-gallery-data2 a.fugu-icon-btn {
	position: absolute;
	right: 0;
	top: 20px;
}
.fugu-gallery-data2 a.fugu-icon-btn img {
	width: auto;
}

.fugu-grid-item.fugu-grid-item-w2 {
	max-width: 100%;
}

.fugu-portfolio-btn {
	display: flex;
	justify-content: center;
	margin-top: 40px;
}
@media (max-width: 991px) {
	.fugu-portfolio-btn {
		margin-top: 20px;
	}
}

.fugu-gallery-wrap3 {
	max-width: 1075px;
	margin: 0 auto;
}
.fugu-gallery-thumb {
	margin-bottom: 25px;
}
.fugu-gallery-thumb img {
	width: 100%;
	height: 100%;
	border-radius: 16px;
}
.fugu-gallery-data3 h4 {
	margin-bottom: 10px;
}
.fugu-gallery-data3 p {
	margin-bottom: 20px;
}

.border_bottom {
	padding-bottom: 50px;
	margin-bottom: 55px;
	border-bottom: 1px solid rgba(19, 17, 26, 0.1);
}
@media (max-width: 991px) {
	.border_bottom {
		padding-bottom: 30px;
		margin-bottom: 30px;
	}
}

.fugu-gallery-data4 {
	margin-bottom: 55px;
}
@media (max-width: 991px) {
	.fugu-gallery-data4 {
		margin-bottom: 35px;
	}
}
.fugu-gallery-data4 ul {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.fugu-gallery-data4 ul li {
	color: var(--gray-800);
}
.fugu-gallery-data4 ul li span {
	display: block;
	font-weight: 600;
}
@media (max-width: 600px) {
	.fugu-gallery-data4 ul li {
		flex: 0 0 50%;
		margin-bottom: 10px;
	}
}
.fugu-gallery-data4 .fugu-team-information ul {
	display: block;
}

.fugu-portfolio-ratated-project {
	padding-top: 55px;
	border-top: 1px solid rgba(19, 17, 26, 0.1);
}
@media (max-width: 991px) {
	.fugu-portfolio-ratated-project {
		padding-top: 35px;
	}
}
.fugu-portfolio-ratated-project p {
	margin-right: 220px;
}
@media (max-width: 991px) {
	.fugu-portfolio-ratated-project p {
		margin-right: 0;
	}
}
.fugu-portfolio-ratated-project .fugu-gallery-item {
	margin-left: 0;
}
.fugu-portfolio-ratated-project .fugu-gallery-data p {
	font-size: 14px;
}
.dark-version .fugu--contact-form p.error {
	color: rgb(179, 40, 40);
}
.fugu-error-text {
	color: rgb(179, 40, 40);
	font-size: small;
}
.pin-text {
	font-size: 30px;
	color: black;
}
.pin i {
	font-size: 30px;
	color: red;
}

/* Slick Slider Css */
.fugu-testimonial-slider .slick-initialized .slick-slide,
.fugu-testimonial-slider2 .slick-initialized .slick-slide {
	padding: 0 15px;
}

/* checkbox */
/* Checkbox Styles */
.checkbox {
	width: 1rem; /* Equivalent to w-4 */
	height: 1rem; /* Equivalent to h-4 */
	background-color: #f3f4f6; /* Equivalent to bg-gray-100 */
	border-color: #d1d5db; /* Equivalent to border-gray-300 */
	border-width: 1px;
	border-style: solid;
	border-radius: 0.375rem; /* Equivalent to rounded */
	color: #2563eb; /* Equivalent to text-blue-600 */
  }
  
  /* Checkbox Focus Styles */
  .checkbox:focus {
	outline: none;
	box-shadow: 0 0 0 2px #2563eb; /* Equivalent to focus:ring-2 and focus:ring-blue-500 */
  }
  
  /* Dark Mode Styles */
  .dark .checkbox {
	background-color: #4b5563; /* Equivalent to dark:bg-gray-700 */
	border-color: #374151; /* Equivalent to dark:border-gray-600 */
  }
  
  /* Dark Mode Focus Styles */
  .dark .checkbox:focus {
	box-shadow: 0 0 0 2px #2563eb; /* Equivalent to dark:focus:ring-blue-600 */
  }
  
  /* Dark Mode Ring Offset Styles */
  .dark .checkbox:after {
	content: '';
	display: block;
	position: absolute;
	inset: -2px;
	border-radius: 0.375rem; /* Equivalent to dark:ring-offset-gray-800 */
	border: 2px solid transparent;
  }
  
  /* Apply the ring offset only when the checkbox is focused */
  .dark .checkbox:focus:after {
	border-color: #374151; /* Equivalent to dark:ring-offset-gray-800 */
  }
  
  .placeholder {
	display: flex;
	align-items: center;
  }
  
  .arrow {
	display: inline-block;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	transition: transform 0.2s;
  }
  
  .arrow.up {
	border-bottom: 5px solid #fff; /* Change color as needed */
	transform: rotate(180deg);
  }
  
  .arrow.down {
	border-top: 5px solid #fff; /* Change color as needed */
  }
  

  .selected-wallet:hover {
	color: var(--warning-500);
  }
  .selected-wallet {
	font-size: 16px;
	font-weight: 700;
  }
  
/* generative nfts */
.generative-layers-nfts-modal {
	width: 90vw !important;
	max-width: 80vw !important;
	
}
.generative-images-container {
	overflow: hidden;
	max-height: 450px;
	overflow-y: scroll;
	padding-bottom: 20px;
}
.generative-image-holder {
	height: 400px;
}
.generative-image-holder img{
	height: 400px;
	object-fit: cover;
	object-position: center;
	/* width: 100%; */
}
.generative-row-box {
	flex-direction: row;
	display: flex;
}
@media (max-width: 768px) {
	.generative-row-box {
	  flex-direction: column-reverse;
	}
  }
.fugu-img-container {
	border: 1px solid #808080;
	margin-top: 20px;
	padding: 20px;
	border-radius: 14px;
	box-shadow: 5px 5px 5px rgb(225 225 225 / 7%);
}
.fugu-generative-img-input {
	border: none !important;
	color: #fff !important;
	font-size: 24px !important;
	text-align: center;
	background-color: transparent;
	border: none;
	padding: 20px !important;
	border-bottom: none !important;
	border-radius: 0;
	outline: none !important; 
	height: 30px;
}
.fugu-generative-img-input:focus {
	border: none;
	padding-bottom: 10px !important;

}
.fugu-generative-img-input:focus:not(:placeholder-shown) {
    border: none !important;
	padding-bottom: 10px !important;
	border-bottom: 1px solid #808080 !important;
}
.fugu-selected-image-card {
	border: 1px solid green;
}
.fugu-slider {
	border: none !important
	
}
.fugu-slider:focus {
	border: none !important
}
.fugu-slider::-webkit-slider-thumb {
    /* Change the range indication color for WebKit browsers (Chrome, Safari, etc.) */
    background-color: #007bff !important;
}

  .fugu-slider::-webkit-slider-thumb {
    /* Change the thumb color for WebKit browsers (Chrome, Safari, etc.) */
    background-color: #007bff; /* Change to your desired color */
}

.fugu-slider::-moz-range-thumb {
    /* Change the thumb color for Mozilla Firefox */
    background-color: #007bff; /* Change to your desired color */
}
  .modal-backdrop.show {
	opacity: 0.9 !important;
  }
/* override the carousel style */
.carousel-control-prev {
	justify-content: start !important;
	width: 5% !important;
}
.carousel-control-next {
	justify-content: end !important;
	width: 5% !important;
}