.sortable-list {
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 13px 46px rgba(0, 0, 0, 0.1);
}


.add-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 14px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    transition: background-color 0.3s ease;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
}

.add-button:hover {
    background-color: #0056b3;
}

.sortable-list .item {
    margin-top: 1rem;
    border: 1px solid #ccc;
    justify-content: space-between;
    list-style: none;
    display: flex;
    cursor: move;
    background: rgba(0, 0, 0, 0.7);
    align-items: center;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

    .details {
        display: flex;
        align-items: center;

        span {
            font-size: 1.2rem;
            font-weight: 500;
            color: #fff;
        }
    }

    &.selected {
        background: #fff;

        span {

            color: #000;
        }

    }
}


.item.dragging {
    opacity: 0.6;
}